









































































































































































import { Option } from '~/models/shared/types'
import {
  computed,
  defineNuxtComponent,
  PropType,
  ref,
  onBeforeUnmount,
  nextTick
} from '~/utils/nuxt3-migration'
import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'
import { ciMagnifyingGlass } from '~/icons/source/regular/magnifying-glass'
import { formatNumber } from '~/utils/number'
import { useId } from '~/compositions/id'
import { OptionGroup } from '~/components/shared/configurable/form/select/types'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineNuxtComponent({
  props: {
    container: {
      type: String,
      default: null
    },
    q: {
      type: String,
      required: true
    },
    searchPlaceholder: {
      type: String,
      required: true
    },
    filteredOptionGroups: {
      type: Array as PropType<OptionGroup[]>,
      required: true
    },
    filteredOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    dropdownClass: {
      type: String,
      required: true
    },
    noResultsMessage: {
      type: String,
      default: ''
    },
    searchable: {
      type: Boolean,
      required: true
    },
    selectedOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    internalValue: {
      type: [Array, Number, String],
      default: null
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    autoSize: {
      type: Boolean,
      default: true
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      required: true
    },
    wrapOptionText: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: true
    },
    textField: {
      type: String,
      default: 'text'
    }
  },
  setup(props, { emit }) {
    const { createRandomId } = useId()

    const dropdownRef = ref<typeof CDropdown>()
    const searchInputRef = ref<HTMLInputElement>()
    const activeOptionIndex = ref<number>(-1)
    const inputId = createRandomId()
    const dropdownId = createRandomId()

    const dropdownContentClasses = computed(() => {
      const classes = []

      if (props.size === 'lg') {
        classes.push('tw-gap-4 tw-py-3')
      } else if (props.size === 'sm') {
        classes.push('tw-gap-2 tw-py-2')
      } else {
        classes.push('tw-gap-3 tw-py-3')
      }

      return classes
    })

    const distance = computed(() => {
      if (props.size === 'sm') {
        return '2'
      } else if (props.size === 'md') {
        return '4'
      } else if (props.size === 'lg') {
        return '8'
      }

      return '4'
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onKeyDown)
    })

    function optionInSelected(option: Option) {
      return props.selectedOptions.some(
        (selectedOption: Option) => selectedOption.value === option.value
      )
    }

    function optionIsActive(index: number) {
      return activeOptionIndex.value === index
    }

    function onOptionClick(option: Option) {
      if (option.disabled) {
        return
      }

      if (props.multiSelect) {
        if (optionInSelected(option)) {
          emit(
            'multi-select',
            props.internalValue.filter(v => v !== option.value)
          )
        } else {
          emit('multi-select', [...props.internalValue, option.value])
        }
      } else {
        emit('select', option.value)

        if (dropdownRef.value) {
          dropdownRef.value.hide()
        }
      }
    }

    function onOptionMouseEnter(option: Option, index: number) {
      if (option.disabled) {
        return
      }

      activeOptionIndex.value = index
    }

    function onMultiselectCheckboxChange(value: any) {
      emit('multi-select', value)
    }

    function getOptionClasses(option: Option, index: number) {
      const classes = []

      if (optionIsActive(index)) {
        classes.push('tw-text-blue-500 tw-bg-grey-150')
      }

      if (optionInSelected(option)) {
        classes.push('tw-font-medium tw-text-blue-500')
      }

      if (props.size === 'lg') {
        classes.push('tw-py-3 tw-text-lg')
      } else if (props.size === 'sm') {
        classes.push('tw-py-1.5 tw-text-base')
      } else {
        classes.push('tw-py-2.5 tw-text-base')
      }

      if (option.disabled) {
        classes.push('tw-cursor-default tw-opacity-40')
      } else {
        classes.push('tw-cursor-pointer')
      }

      if (props.size === 'lg') {
        classes.push('tw-px-4')
      } else {
        classes.push('tw-px-3')
      }

      return classes
    }

    function onKeyDown(e: KeyboardEvent) {
      if (!['ArrowDown', 'ArrowUp', 'Enter', ' ', 'Escape'].includes(e.key)) {
        return
      }

      if (e.key === 'Escape' && dropdownRef.value) {
        dropdownRef.value.hide()
        emit('return-focus')

        return
      }

      if (e.key === ' ' && props.searchable) {
        // Do not handle space key if the dropdown is searchable to allow typing
        return
      }

      e.preventDefault()

      const allOptions = [
        ...props.filteredOptionGroups.flatMap(
          (group: OptionGroup) => group.options || []
        ),
        ...props.filteredOptions
      ]

      if (!allOptions.length) {
        return
      }

      if (e.key === 'ArrowDown') {
        // Find the next non-disabled option
        let nextIndex = activeOptionIndex.value

        do {
          nextIndex = (nextIndex + 1) % allOptions.length
          // Break the loop if we've checked all options and come back to the current one
          if (nextIndex === activeOptionIndex.value) break
        } while (allOptions[nextIndex].disabled)

        // Only update if we found a non-disabled option
        if (!allOptions[nextIndex].disabled) {
          activeOptionIndex.value = nextIndex
          scrollOptionIntoView(allOptions[nextIndex])
        }
      } else if (e.key === 'ArrowUp') {
        let prevIndex = activeOptionIndex.value

        do {
          // Handle wrapping around to the end of the list
          prevIndex = prevIndex - 1 < 0 ? allOptions.length - 1 : prevIndex - 1
          // Break the loop if we've checked all options and come back to the current one
          if (prevIndex === activeOptionIndex.value) break
        } while (allOptions[prevIndex].disabled)

        // Only update if we found a non-disabled option
        if (!allOptions[prevIndex].disabled) {
          activeOptionIndex.value = prevIndex
          scrollOptionIntoView(allOptions[prevIndex])
        }
      } else if (
        (e.key === 'Enter' || (e.key === ' ' && !props.searchable)) &&
        activeOptionIndex.value >= 0
      ) {
        onOptionClick(allOptions[activeOptionIndex.value])

        if (!props.multiSelect) {
          emit('return-focus')
        }
      }
    }

    function onDropdownShow() {
      emit('show')
    }

    function onDropdownHidden() {
      emit('hidden')

      activeOptionIndex.value = -1
      window.removeEventListener('keydown', onKeyDown)
    }

    async function onDropdownShown() {
      // Set active option to the selected option when the dropdown first opens
      const allOptions = [
        ...props.filteredOptionGroups.flatMap(
          (group: OptionGroup) => group.options || []
        ),
        ...props.filteredOptions
      ]

      if (
        allOptions.length &&
        props.selectedOptions.length &&
        !props.searchable
      ) {
        const [firstSelectedOption] = props.selectedOptions
        const selectedIndex = allOptions.findIndex(
          option => option.value === firstSelectedOption.value
        )

        if (selectedIndex >= 0) {
          activeOptionIndex.value = selectedIndex
          scrollOptionIntoView(allOptions[selectedIndex])
        }
      }

      if (props.searchable) {
        await nextTick()
        // FIXME: Using a normal ref here doesn't work because the ref is not yet available when the dropdown is fully shown
        const inputEl = document.getElementById(inputId.value)

        if (inputEl) {
          inputEl.focus()
        }
      }

      window.addEventListener('keydown', onKeyDown)
    }

    function onSearchInput(event: Event) {
      const text = (event.target as HTMLInputElement).value

      emit('search', text)
    }

    async function scrollOptionIntoView(option: Option) {
      await nextTick()

      const optionEl = document.querySelector(
        `[data-option-value="${dropdownId.value}-${option.value}"]`
      )

      if (optionEl) {
        optionEl.scrollIntoView({ block: 'nearest' })
      }
    }

    function show() {
      if (dropdownRef.value) {
        dropdownRef.value.show()
      }
    }

    return {
      ciMagnifyingGlass,
      dropdownRef,
      dropdownId,
      searchInputRef,
      dropdownContentClasses,
      distance,
      inputId,
      activeOptionIndex,
      onDropdownShow,
      onDropdownShown,
      onDropdownHidden,
      formatNumber,
      getOptionClasses,
      optionInSelected,
      onOptionClick,
      onOptionMouseEnter,
      onSearchInput,
      onMultiselectCheckboxChange,
      show
    }
  },
  i18n: defineComponentTranslations({
    rest_of_options: {
      en: 'Rest of options',
      el: 'Υπόλοιπες επιλογές'
    }
  })
})

























































import { mapGetters } from 'vuex'
import CImageViewer from '~/components/shared/configurable/image/CImageViewer.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import FontAwesomeIcon from '@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon'
import { mapDeps } from '~/plugins/dependency-container/utils'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { ciPencil } from '~/icons/source/solid/pencil'
import { ciUser } from '~/icons/source/solid/user'

export default defineComponent({
  components: {
    CImageViewer
  },
  props: {
    avatar: {
      type: [String, null],
      required: false,
      default: null
    },
    border: {
      type: Boolean,
      required: false,
      default: false
    },
    borderWidth: {
      type: Number,
      required: false,
      default: 1
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: Number,
      required: false,
      default: 50
    },
    clickable: {
      type: Boolean,
      required: false,
      default: true
    },
    shadow: {
      type: Boolean,
      required: false,
      default: false
    },
    height: {
      type: Number,
      required: false,
      default: 50
    },
    rounded: {
      type: Boolean,
      default: true
    },
    zoomable: {
      type: Boolean,
      default: true
    },
    clickTarget: {
      type: [String, null],
      required: false,
      default: null
    },
    noAvatarIcon: {
      type: Object as PropType<FontAwesomeIcon>,
      default: null
    },
    linkTag: {
      type: String,
      required: false,
      default: 'a'
    },
    alt: {
      type: String,
      required: false,
      default: 'user avatar'
    },
    customClass: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      showZoomer: false,
      hasError: false
    }
  },
  computed: {
    ...mapGetters(USER_NS, {
      isGuest: 'isGuest'
    }),
    ...mapDeps({
      legacyUrlService: LegacyUrlService
    }),
    style() {
      const appliedHeight = this.border
        ? this.height + 2 * this.borderWidth
        : this.height
      const appliedWidth = this.border
        ? this.width + 2 * this.borderWidth
        : this.width

      const borderWidth = this.border ? this.borderWidth + 'px' : null

      if (!this.rounded) {
        const maxWidth = this.width === 50 ? '100%' : `${appliedWidth}px`

        return {
          maxWidth,
          borderWidth,
          width: !this.avatar && `${appliedWidth}px`,
          height: `${appliedHeight}px`
        }
      }
      return {
        borderWidth,
        borderRadius: appliedWidth / 2 + 'px',
        width: `${appliedWidth}px`,
        height: `${appliedHeight}px`
      }
    },
    icons() {
      return {
        pencil: ciPencil,
        user: ciUser
      }
    },
    linkToEdit() {
      if (this.isGuest) {
        return this.$router.resolve({ name: '__account' }).href
      }
      return '/account/classified_settings/'
    },
    filesToShow() {
      if (this.avatar) {
        return [
          {
            type: 'image',
            url: this.avatar,
            name: ''
          }
        ]
      }
      return []
    }
  },
  watch: {
    avatar() {
      this.hasError = false
    }
  },
  methods: {
    avatarClicked(event) {
      // only stopping event bubbling in case it needs to be stopped
      if (!this.editable) {
        if (this.clickTarget) {
          event.stopPropagation()
          return this.$router.push({
            path: this.clickTarget
          })
        } else if (this.avatar && this.zoomable && this.clickable) {
          event.stopPropagation()
          this.showZoomer = true
        }
      }
    },
    linkOverlayClicked(event) {
      event.preventDefault()
      event.stopPropagation()
      window.location = this.linkToEdit
    },
    linkOverlayMiddleClicked(event) {
      event.preventDefault()
      event.stopPropagation()
      window.open(this.linkToEdit, '_blank')
    }
  }
})

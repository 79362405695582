import { CIcon } from '~/icons/types'

export const ciClock: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 2.2c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8Zm4.5 10.6H12c-.4 0-.8-.3-.8-.8V6c0-.4.3-.8.8-.8s.8.3.8.8v5.2h3.8c.4 0 .8.3.8.8s-.5.8-.9.8Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'clock',
  type: 'solid'
}







































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch
} from '~/utils/nuxt3-migration'
import { Option } from '~/models/shared/types'
import { SelectSize } from '~/models/app/select'
import { ciAngleDown } from '~/icons/source/regular/angle-down'
import { ciTimes } from '~/icons/source/regular/times'
import { elementIsOverflownInParent } from '~/utils/dom'
import { debounce } from '~/utils/function'

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    headerClasses: {
      type: String,
      required: true
    },
    mainTextClasses: {
      type: String,
      default: ''
    },
    selectedOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    internalDropdownOpen: {
      type: Boolean,
      required: true
    },
    size: {
      type: String as PropType<SelectSize>,
      required: true
    },
    warning: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    nothingSelected: {
      type: Boolean,
      default: false
    },
    useFullScreenModal: {
      type: Boolean,
      required: true
    },
    textField: {
      type: String,
      default: 'text'
    }
  },
  setup(props, { emit }) {
    const optionsRef = ref<HTMLElement[]>()
    const optionsContainerRef = ref<HTMLElement>()
    const overflownOptionsCount = ref()
    let resizeObserver: ResizeObserver

    onMounted(() => {
      if (
        !optionsContainerRef.value ||
        !optionsRef.value ||
        !('ResizeObserver' in window)
      ) {
        return
      }

      resizeObserver = new ResizeObserver(calculateOverflowingOptionsCount)
      resizeObserver.observe(optionsContainerRef.value)
    })

    onUnmounted(() => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    })

    watch(
      () => props.selectedOptions,
      () => {
        calculateOverflowingOptionsCount()
      }
    )

    const firstOptionText = computed(() => {
      const [firstOption] = selectedOptionSet.value

      if (typeof firstOption === 'string') {
        return firstOption
      }

      return firstOption.headerText || firstOption[props.textField]
    })

    const computedClasses = computed(() => {
      const classes = [props.headerClasses, props.size]

      if (props.size === 'sm') {
        classes.push('tw-h-[32px] tw-min-h-[32px] tw-text-base tw-rounded-md')
      } else if (props.size === 'md') {
        classes.push('tw-h-[40px] tw-min-h-[40px] tw-text-base tw-rounded-lg')
      } else if (props.size === 'lg') {
        classes.push('tw-h-[48px] tw-min-h-[48px] tw-text-lg tw-rounded-xl')
      }

      if (props.disabled) {
        classes.push(
          ...[
            'tw-bg-grey-300',
            'tw-cursor-not-allowed',
            'tw-pointer-events-none'
          ]
        )
      } else {
        classes.push(...['tw-bg-white', 'tw-cursor-pointer'])
      }

      if (props.internalDropdownOpen) {
        classes.push('tw-ring-blue-500', '!tw-border-blue-500 tw-ring-1')
      }

      if (!props.disabled) {
        classes.push('focus-visible:tw-ring-1 focus-visible:tw-ring-blue-500')
      }

      if (props.nothingSelected || props.disabled) {
        classes.push('tw-text-grey-600')
      } else {
        classes.push('tw-text-grey-900')
      }

      return classes
    })

    const selectedOptionSet = computed((): Option[] | string[] => {
      const set = new Set<string>()
      const options = []
      for (const option of props.selectedOptions) {
        const duplicateName: boolean = set.has(option[props.textField])

        set.add(option[props.textField])

        if (duplicateName) {
          continue
        }

        options.push(option)
      }
      return options
    })

    function onClick() {
      emit('click')
    }

    const calculateOverflowingOptionsCount = debounce(async () => {
      if (!optionsRef.value || !optionsContainerRef.value) {
        return
      }

      await nextTick()

      let count = 0

      for (const optionEl of optionsRef.value) {
        const optionIsOverflown = elementIsOverflownInParent(
          optionEl,
          optionsContainerRef.value
        )

        if (optionIsOverflown) {
          count += 1
        }
      }

      overflownOptionsCount.value = count
    }, 100)

    return {
      ciAngleDown,
      ciTimes,
      selectedOptionSet,
      computedClasses,
      firstOptionText,
      optionsRef,
      optionsContainerRef,
      overflownOptionsCount,
      onClick
    }
  }
})

import { ciCalendar } from '~/icons/source/solid/calendar'
import { ciArea } from '~/icons/source/solid/area'
import { ciDashboard } from '~/icons/source/solid/dashboard'
import { ciMotor } from '~/icons/source/solid/motor'
import { ciGasStation } from '~/icons/source/solid/gas-station'
import { ciGearshift } from '~/icons/source/solid/gearshift'
import { ciMap } from '~/icons/source/solid/map'
import { ciWallet } from '~/icons/source/solid/wallet'
import { ciAccounting } from '~/icons/source/solid/accounting'
import { ciHome } from '~/icons/source/solid/home'
import { ciDoubleBed } from '~/icons/source/solid/double-bed'
import { ciThermometer } from '~/icons/source/solid/thermometer'
import { ciShelving } from '~/icons/source/solid/shelving'
import { ciFamilySilhouette } from '~/icons/source/solid/family-silhouette'
import { ciToolsAndUtensils } from '~/icons/source/solid/tools-and-utensils'
import { ciFullScreen } from '~/icons/source/solid/full-screen'
import { ciDriverLicense } from '~/icons/source/solid/driver-license'
import { ciEnergyClass } from '~/icons/source/solid/energy-class'
import { ciWater } from '~/icons/source/solid/water'
import { ciGamepadController } from '~/icons/source/solid/gamepad-controller'
import { ciHardDisk } from '~/icons/source/solid/hard-disk'
import { ciFlash } from '~/icons/source/solid/flash'
import { ciGenders } from '~/icons/source/solid/genders'
import { ciMeasuringTape } from '~/icons/source/solid/measuring-tape'
import { ciPicture } from '~/icons/source/solid/picture'
import { ciSeat } from '~/icons/source/solid/seat'
import { ciHandHoldingUpAGear } from '~/icons/source/solid/hand-holding-up-a-gear'
import { ciRim } from '~/icons/source/solid/rim'
import { ciComputerVirus } from '~/icons/source/solid/computer-virus'
import { ciSettings } from '~/icons/source/solid/settings'
import { ciTabs } from '~/icons/source/solid/tabs'
import { ciDoubleArrow } from '~/icons/source/solid/double-arrow'
import { ciDoubleArrowAlt } from '~/icons/source/solid/double-arrow-alt'
import { ciOven } from '~/icons/source/solid/oven'
import { ciCloth } from '~/icons/source/solid/cloth'
import { ciStove } from '~/icons/source/solid/stove'
import { ciChassis } from '~/icons/source/solid/chassis'
import { ciWeight } from '~/icons/source/solid/weight'
import { ciShipment } from '~/icons/source/solid/shipment'
import { ciStar } from '~/icons/source/solid/star'
import { ciBarcode } from '~/icons/source/solid/barcode'

export const solidKeyFeaturesIcons = Object.freeze({
  makemodel: {
    icon: ciBarcode
  },
  age: {
    icon: ciCalendar
  },
  registration: {
    icon: ciCalendar
  },
  area: {
    icon: ciArea
  },
  mileage: {
    icon: ciDashboard
  },
  engine_size: {
    icon: ciMotor
  },
  fuel_type: {
    icon: ciGasStation
  },
  engine_power: {
    icon: ciDashboard
  },
  gearbox_type: {
    icon: ciGearshift
  },
  location: {
    icon: ciMap
  },
  price: {
    icon: ciWallet
  },
  price_per_square_meter: {
    icon: ciAccounting
  },
  year_of_construction: {
    icon: ciCalendar
  },
  floor: {
    icon: ciHome
  },
  bedrooms: {
    icon: ciDoubleBed
  },
  heat_medium: {
    icon: ciThermometer
  },
  spaces: {
    icon: ciShelving
  },
  guests: {
    icon: ciFamilySilhouette
  },
  memory: {
    icon: ciToolsAndUtensils
  },
  ram: {
    icon: ciToolsAndUtensils
  },
  microchip: {
    icon: ciToolsAndUtensils
  },
  resolution: {
    icon: ciFullScreen
  },
  dealer_licence_number: {
    icon: ciDriverLicense
  },
  diagonal: {
    icon: ciFullScreen
  },
  screen_size: {
    icon: ciFullScreen
  },
  energy_class: {
    icon: ciEnergyClass
  },
  viscosity_w: {
    icon: ciWater
  },
  console: {
    icon: ciGamepadController
  },
  condition: {
    icon: ciStar
  },
  hard_drive: {
    icon: ciHardDisk
  },
  total_power: {
    icon: ciFlash
  },
  sex: {
    icon: ciGenders
  },
  number: {
    icon: ciMeasuringTape
  },
  image_refresh_period: {
    icon: ciPicture
  },
  seats: {
    icon: ciSeat
  },
  functions: {
    icon: ciHandHoldingUpAGear
  },
  tire: {
    icon: ciRim
  },
  capacity: {
    icon: ciComputerVirus
  },
  technology: {
    icon: ciSettings
  },
  arrangement: {
    icon: ciTabs
  },
  width: {
    icon: ciDoubleArrow
  },
  height: {
    icon: ciDoubleArrowAlt
  },
  type_of_oven: {
    icon: ciOven
  },
  material: {
    icon: ciCloth
  },
  net_capacity: {
    icon: ciComputerVirus
  },
  rim_material: {
    icon: ciRim
  },
  baking_panels_type: {
    icon: ciStove
  },
  gears: {
    icon: ciGearshift
  },
  rim_size: {
    icon: ciRim
  },
  axles: {
    icon: ciChassis
  },
  weight: {
    icon: ciWeight
  },
  payload: {
    icon: ciShipment
  }
})





























































import AdvertiseService from '~/services/ads/AdvertiseService'

import { computed, defineComponent, ref, toRefs } from '~/utils/nuxt3-migration'
import { useDroppable } from '~/compositions/cursor'
import { useDep } from '~/compositions/dependency-container'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import { ciCheck } from '~/icons/source/solid/check'
import { ciPlus } from '~/icons/source/solid/plus'

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { type } = toRefs(props)
    const images = ref(null)

    const advertisingService = useDep(AdvertiseService)
    const snackbar = useSnackbar()
    const {
      droppingOver,
      onDrop,
      onDragOver,
      onDragLeave
    } = useDroppable(false, (e: DragEvent) => emit('addFiles', e))

    const { t } = useI18n()
    function triggerInput(): void {
      emit('trigger-input')
    }

    const selectedFiles = computed(
      () => images.value?.filter(f => f.selected) || []
    )

    function addSelectedImage() {
      for (let i = 0; i < selectedFiles.value.length; i++) {
        const img = selectedFiles.value[i]
        emit('image-select', img)
      }
    }

    async function loadImages() {
      // for now this only works for announcements
      try {
        const { assets } = await advertisingService.getAdsAssets()
        let assetsToUse = assets
        if (type.value) {
          if (type.value === 'square_image_url') {
            assetsToUse = [
              ...assets.filter(
                a => a.type === type.value || a.type === 'square_image'
              )
            ]
          } else {
            assetsToUse = [...assets.filter(a => a.type === type.value)]
          }
        }
        images.value = assetsToUse.map(a => {
          return {
            file: null,
            originalFilename: 'todo calc name',
            filename: a.url,
            thumb: a.url,
            type: 'image',
            url: a.url,
            selected: false
          }
        })
      } catch (error) {
        snackbar.error(t('an error occurred please try again later'))
      }
    }

    function toggleImageSelected(index: number) {
      if (!images.value) {
        return
      }

      for (let i = 0; i < images.value.length; i++) {
        images.value[i].selected = index === i
      }
    }

    return {
      images,
      droppingOver,
      onDrop,
      onDragOver,
      onDragLeave,
      ciPlus,
      ciCheck,
      triggerInput,
      addSelectedImage,
      loadImages,
      toggleImageSelected,
      selectedFiles
    }
  }
})

import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import RequestBuilder from '~/builders/http/RequestBuilder'
import { AdActionResult, InHouseEntry } from '~/models/advertising/types'
import { formatAdvertiseResponse } from '~/services/ads/formatters'
import { getRandomArrayItem } from '~/utils/array'

@containerScoped()
export default class InHouseService {
  constructor(
    @inject(httpToken) private http: AxiosInstance,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  public fetchInHouseEntry(
    unit: string,
    makes: number[],
    categories: number[],
    itemId?: number | string
  ): Promise<InHouseEntry> {
    return this.requestBuilder
      .request('get', '/api/in-house/fetch/')
      .params({
        unit,
        context_makes: makes,
        context_categories: categories,
        item_id: itemId
      })
      .validate(body => body && body.data)
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public recordImpression(itemId: number | string): Promise<AdActionResult> {
    return this.requestBuilder
      .request('post', '/api/in-house/record/')
      .data({ item: itemId.toString() })
      .validate(body => body && body.message === 'recorded')
      .map(body => formatAdvertiseResponse(body.data))
      .send()
  }

  public getSizeForCurrentResolution(sizes: number[][], sizesMappings: any[]) {
    let selectedMappingSize: number[] | null = null
    if (sizesMappings?.length) {
      // find the size mappings where at least one of the sizes exist
      sizesMappings.forEach(mapping => {
        if (selectedMappingSize) {
          return
        }
        const mappingHasSizes = mapping[1]?.length
          ? mapping[1].some((m: any[]) => {
              // @ts-ignore
              return sizes.some(s => s[0] === m[0] && s[1] === m[1])
            })
          : false

        const mappingMinW = mapping[0][0]
        const mappingMinH = mapping[0][1]
        if (
          mappingHasSizes &&
          window.innerWidth > mappingMinW &&
          window.innerHeight > mappingMinH
        ) {
          // return one of the mappings that fit the current resolution
          selectedMappingSize = getRandomArrayItem(mapping[1])
        }
      })
    }
    return selectedMappingSize || getRandomArrayItem(sizes)
  }
}

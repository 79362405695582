














import { resolveCategory } from '~/utils/category'
import { LinkType } from '~/models/search/types'
import { ciDeals } from '~/icons/source/regular/deals'
import {
  alternativeCategoriesIconsMappings,
  categoriesIconsMappings
} from '~/constants/category/icon'
import FeedSidebarIconContainer from '~/components/car/classifieds/index/feeds/sidebar/FeedSidebarIconContainer.vue'
import { ciTradesMatch } from '~/icons/source/regular/trades-match'
import { ciBlackFriday } from '~/icons/source/brand/black-friday'
import { ciBicycle } from '~/icons/source/regular/bicycle'
import { CategoryId } from '~/models/category/types'
import { ciJobsInactive } from '~/icons/source/regular/jobs-inactive'
import { ciXymaInactive } from '~/icons/source/regular/xyma-inactive'
import { ciRealEstateInactive } from '~/icons/source/regular/real-estate-inactive'
import { ciRentals } from '~/icons/source/regular/rentals'
import { defineComponent } from '~/utils/nuxt3-migration'
import { ciMagnifyingGlass } from '~/icons/source/solid/magnifying-glass'
import { ciStar } from '~/icons/source/solid/star'

export default defineComponent({
  components: {
    FeedSidebarIconContainer
  },
  props: {
    categoryIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    inSidebar: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    alt: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    isSubcategory: {
      type: Boolean,
      default: false
    },
    isThirdLevelCategory: {
      type: Boolean,
      default: false
    },
    noColors: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      if (!this.type) {
        return {
          icon: resolveCategory(
            this.alt ||
              this.isThirdLevelCategory ||
              (this.isSubcategory && this.selected)
              ? alternativeCategoriesIconsMappings
              : categoriesIconsMappings,
            this.categoryIds
          )
        }
      }

      switch (this.type) {
        case LinkType.PROMOTED: {
          return {
            icon: ciStar
          }
        }
        case LinkType.ALL: {
          return {
            icon: ciMagnifyingGlass
          }
        }
        case LinkType.DEALS: {
          return {
            icon: ciDeals
          }
        }
        case LinkType.BLACK_FRIDAY: {
          return {
            icon: ciBlackFriday,
            class: 'black-friday'
          }
        }
        case LinkType.TRADES: {
          return {
            icon: ciTradesMatch,
            class: 'tw-text-[1.5rem] tw-ml-1'
          }
        }
        case LinkType.JOBS: {
          return {
            icon: ciJobsInactive
          }
        }
        case LinkType.XYMA: {
          return {
            icon: ciXymaInactive
          }
        }
        case LinkType.REAL_ESTATE: {
          return {
            icon: ciRealEstateInactive
          }
        }
        case LinkType.RENTALS: {
          return {
            icon: ciRentals
          }
        }
        case String(CategoryId.BICYCLES): {
          return {
            icon: ciBicycle
          }
        }
      }
      return {
        icon: resolveCategory(
          this.alt ||
            this.isThirdLevelCategory ||
            (this.isSubcategory && this.selected)
            ? alternativeCategoriesIconsMappings
            : categoriesIconsMappings,
          this.categoryIds
        )
      }
    }
  }
})

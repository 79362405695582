



























































































































import { findDeep } from '~/utils/object'
import CTreeSelectNode from '~/components/shared/configurable/form/select/tree/CTreeSelectNode.vue'
import {
  computed,
  defineComponent,
  PropType,
  vue3Model,
  ref,
  watch
} from '~/utils/nuxt3-migration'
import { TreeOption } from '~/models/shared/types'
import CTreeSelectHeader from '~/components/shared/configurable/form/select/tree/CTreeSelectHeader.vue'
import { useUserAgent } from '~/compositions/user-agent'
import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'

export default defineComponent({
  model: vue3Model,
  components: {
    CTreeSelectHeader,
    CTreeSelectNode
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array as PropType<TreeOption[]>,
      required: true
    },
    modelValue: {
      type: Array,
      default() {
        return []
      }
    },
    valueCol: {
      type: String,
      default: 'value'
    },
    nameCol: {
      type: String,
      default: 'name'
    },
    countCol: {
      type: String,
      default: 'count'
    },
    childrenCol: {
      type: String,
      default: 'children'
    },
    expanded: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showCounts: {
      type: Boolean,
      default: true
    },
    bodyClasses: {
      type: String,
      default: ''
    },
    hideZeroCountOptions: {
      type: Boolean,
      default: false
    },
    headerClass: {
      type: String,
      default: ''
    },
    mobileModalTitle: {
      type: String,
      default: ''
    },
    childrenClasses: {
      type: String,
      default: ''
    },
    ignoreSelectedCount: {
      type: Number,
      default: 0
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md'
    }
  },
  setup(props, { emit }) {
    const { isMobile } = useUserAgent()

    const isDropdownOpen = ref(false)
    const isMobileModalVisible = ref(false)
    const selectedOptions = ref<TreeOption[]>([])
    const selectedIds = ref<number[]>([])
    const dropdownRef = ref<CDropdown | null>()
    const optionMap = ref<Map<number, TreeOption>>(getOptionMap() || new Map())

    const selectedValue = computed({
      get: () => JSON.parse(JSON.stringify(props.modelValue)),
      set: (value: number[]) => {
        emit('update:modelValue', value)
        emit('change', value)
      }
    })

    watch(selectedValue, () => {
      updateSelectedOptions()
      emit('selected-values', selectedIds.value)
    })

    watch(
      () => props.options,
      () => {
        optionMap.value = getOptionMap()
        updateSelectedOptions()
      }
    )

    const selectedCount = computed(
      () => selectedValue.value.length - props.ignoreSelectedCount
    )

    const dropdownDistance = computed(() => {
      const distances = {
        sm: '2',
        md: '4',
        lg: '8'
      }

      return distances[props.size as 'sm' | 'md' | 'lg'] || distances.md
    })

    const shownOptions = computed(() =>
      props.options.filter(option => {
        if (!props.hideZeroCountOptions) {
          return true
        }

        const count = option[props.countCol as any]
        return count && count >= 0
      })
    )

    function onDropdownShow() {
      isDropdownOpen.value = true
    }

    function onDropdownHidden() {
      isDropdownOpen.value = false
    }

    function getOptionMap() {
      const map = new Map()

      const flattenOptionTree = (
        option: TreeOption,
        parentId: number | null
      ) => {
        map.set(option[props.valueCol], { ...option, parentId })

        if (option[props.childrenCol]) {
          option[props.childrenCol].forEach(c =>
            flattenOptionTree(c, option[props.valueCol])
          )
        }
      }

      props.options.forEach((option: TreeOption) =>
        flattenOptionTree(option, null)
      )

      return map
    }

    function updateSelectedOptions() {
      if (!optionMap.value.size) {
        return
      }

      const selectedLeaves = selectedValue.value.filter((value: number) => {
        const option = optionMap.value.get(value)
        if (!option) {
          return false
        }

        const someChildIsSelected = option[
          props.childrenCol
        ]?.some((child: TreeOption) =>
          selectedValue.value.some((sv: number) => sv === child[props.valueCol])
        )

        return !someChildIsSelected
      })

      selectedIds.value = selectedLeaves.map(
        (value: number) => optionMap.value.get(value)[props.valueCol]
      )

      selectedOptions.value = selectedLeaves.map((value: number) => {
        const option = optionMap.value.get(value)

        return {
          value: option[props.valueCol],
          text: option[props.nameCol]
        }
      })
    }

    function onChange(newSelections: number[]) {
      if (newSelections.length < selectedValue.value.length) {
        const difference = selectedValue.value
          .filter((s: number) => !newSelections.includes(s))
          .concat(
            newSelections.filter(
              (s: number) => !selectedValue.value.includes(s)
            )
          )
        deselect(newSelections, difference[0])
      } else {
        selectedValue.value = newSelections
      }
    }

    function deselect(newSelections: number[], id: number) {
      const idsToDeselect = getIdsToDeselect(id, [])
      newSelections = newSelections.filter(
        (s: number) => !idsToDeselect.includes(s)
      )

      selectedValue.value = newSelections
    }

    function getIdsToDeselect(id: number, ids: number[]) {
      const toDeselect = findDeep({
        object: props.options,
        key: props.valueCol,
        value: id
      })

      if (toDeselect[props.childrenCol]) {
        toDeselect[props.childrenCol].forEach((child: TreeOption) => {
          ids.push(child[props.valueCol])
          getIdsToDeselect(child[props.valueCol], ids)
        })
      }

      return ids
    }

    function onNodeRedirectUrlClick(url: string) {
      emit('click:node-redirect-url', url)

      if (dropdownRef.value) {
        dropdownRef.value.hide()
      }
    }

    updateSelectedOptions()

    return {
      dropdownDistance,
      isDropdownOpen,
      isMobile,
      isMobileModalVisible,
      selectedCount,
      selectedOptions,
      selectedValue,
      dropdownRef,
      onDropdownShow,
      onDropdownHidden,
      onChange,
      onNodeRedirectUrlClick,
      shownOptions
    }
  }
})

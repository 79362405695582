import { driver, Popover } from 'driver.js'
import 'driver.js/dist/driver.css'
import { useDep } from '~/compositions/dependency-container'
import { ONE_YEAR } from '~/constants/duration'
import { DriverCookieName } from '~/constants/driver'
import CookiesService from '~/services/CookiesService'

interface HighlightOptions {
  element: Element
  popover: Popover
}

interface HighlightOnceOptions extends HighlightOptions {
  cookieName: DriverCookieName
}

/**
 * @see https://driverjs.com/docs/api
 */
export function useDriver() {
  const cookiesService = useDep(CookiesService)

  function highlight({ element, popover }: HighlightOptions) {
    if (process.server) {
      throw new Error('Can not use driver highlight on the server')
    }

    const driverInstance = driver({
      popoverClass: 'driverjs-theme',
      onCloseClick: () => {
        driverInstance.destroy()
      },
      onHighlightStarted: element => {
        if (element) {
          element.addEventListener('click', () => {
            driverInstance.destroy()
          })
        }
      }
    })

    driverInstance.highlight({
      element,
      popover: {
        ...popover
      }
    })
  }

  function highlightOnce({
    cookieName,
    element,
    popover
  }: HighlightOnceOptions) {
    const cookie = cookiesService.get(cookieName)
    if (cookie) {
      return
    }

    highlight({ element, popover })

    if (cookieName) {
      cookiesService.set(cookieName, true, { maxAge: ONE_YEAR * 10 })
    }
  }

  return { highlightOnce, highlight, driver }
}

import { CIcon } from '~/icons/types'

export const ciChevronUp: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M10.94 7.19a1.5 1.5 0 0 1 2.12 0l7.5 7.5a1.5 1.5 0 0 1-2.12 2.12L12 10.372l-6.44 6.44a1.5 1.5 0 0 1-2.12-2.122l7.5-7.5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'chevron-up',
  type: 'solid'
}

import { CIcon } from '~/icons/types'
export const ciApproved: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M512 256c0 141.385-114.615 256-256 256S0 397.385 0 256 114.615 0 256 0c84.887 0 160.123 41.316 206.698 104.935a25.3 25.3 0 0 0-12.914 6.853L212.768 346.936 119.105 254h-.001a25.322 25.322 0 0 0-17.834-7.335A25.323 25.323 0 0 0 83.436 254a25.067 25.067 0 0 0-7.425 17.797c0 6.689 2.68 13.09 7.425 17.797l111.493 110.605.004.004a25.246 25.246 0 0 0 8.189 5.433 25.349 25.349 0 0 0 9.645 1.905c3.308 0 6.585-.646 9.645-1.905a25.235 25.235 0 0 0 8.189-5.433l.004-.004 254.847-252.817a25.357 25.357 0 0 0 1.603-1.746C503.045 179.054 512 216.481 512 256Zm-43.075-142.172a16.002 16.002 0 0 0-12.576 4.578 16 16 0 0 1 12.576-4.577v-.001ZM101.27 255.987c-4.227 0-8.28 1.666-11.27 4.631v-.001a16.006 16.006 0 0 1 11.27-4.63c4.227 0 8.28 1.666 11.269 4.63l100.228 99.45-100.228-99.449a16.004 16.004 0 0 0-11.269-4.631Z'
    }
  ],
  name: 'approved',
  type: 'regular'
}

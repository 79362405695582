import { SET_QUICK_AD_VALUE } from '~/store/modules/shared/advertising/form/mutation-types'
import { useNamespacedStore } from '~/compositions/store'
import {
  ADVERTISE_FORM_NS,
  AdvertiseFormState
} from '~/store/modules/shared/advertising/form/state'
import { computed, useRoute } from '~/utils/nuxt3-migration'
import { diffInDays } from '~/utils/date'
import { useRouter } from '@nuxtjs/composition-api'

export function useQuickAdForm() {
  const { state, commit } = useNamespacedStore<AdvertiseFormState>(
    ADVERTISE_FORM_NS
  )

  const router = useRouter()
  const route = useRoute()

  const schema = computed(() => state.quickAdSchema)

  const values = computed(() => state.quickAd.values)
  const campaign = computed(() => state.quickAd.inHouseAdCampaign)
  const ad = computed(() => state.quickAd.inHouseAd)
  const adItem = computed(() => state.quickAd.inHouseAdItem)

  const inEdit = computed(() =>
    Boolean(campaign.value && ad.value && adItem.value)
  )

  const inQuickForm = computed(
    () =>
      !route.value.name?.startsWith('__advertising') ||
      route.value.name?.startsWith('__advertising_dashboard')
  )
  const emitParams = (name: string, value: any) => {
    commit(SET_QUICK_AD_VALUE, { name, value })
  }

  const getSchemaField = (name: string) => {
    return schema.value?.[name]
  }

  const daysBetween = computed(() => {
    if (values.value?.startDate && values.value?.finishDate) {
      return diffInDays(values.value.finishDate, values.value.startDate)
    }
    return null
  })

  const loading = computed(() => state.loading)
  const errors = computed(() => state.quickAdErrors)

  const clearPreviewUrlParams = () => {
    if (
      route.value.query.item &&
      route.value.query.unit &&
      (route.value.query.success || route.value.query.cancel)
    ) {
      router.replace({
        query: {
          ...route.value.query,
          item: undefined,
          unit: undefined,
          success: undefined,
          cancel: undefined
        }
      })
    }
  }

  return {
    loading,
    errors,
    values,
    campaign,
    ad,
    adItem,
    schema,
    emitParams,
    getSchemaField,
    daysBetween,
    inQuickForm,
    inEdit,
    clearPreviewUrlParams
  }
}

import { CIcon } from '~/icons/types'

export const ciDashboard: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d: 'M14 18.8h-4c-.4 0-.8.3-.8.8s.3.8.8.8h4c.4 0 .8-.3.8-.8s-.4-.8-.8-.8Z'
    },
    {
      d:
        'M22.6 14.302c0-1.9-.5-3.7-1.4-5.3v-.2c-.9-1.6-2.2-2.9-3.8-3.8h-.2c-1.6-.9-3.3-1.4-5.3-1.4-1.9 0-3.7.5-5.3 1.4h-.2c-1.3 1.1-2.6 2.4-3.6 3.9-.9 1.6-1.4 3.4-1.4 5.3s.5 3.7 1.4 5.3l.3.3h.1c.1.2.2.2.2.2.1 0 .3 0 .4-.1l1.7-1c.4-.2.5-.7.3-1-.2-.4-.7-.5-1-.3l-1 .6c-.5-1-.8-2.1-.8-3.1h1.2c.4 0 .8-.3.8-.8s-.3-.8-.8-.8H2.9c.1-1.1.4-2.2.8-3.1l1 .6c.1.1.2.1.4.1.3 0 .5-.1.6-.4.2-.4.1-.8-.3-1l-1-.6c.6-.9 1.4-1.7 2.3-2.3l.6 1c.2.3.5.4.7.4.1 0 .3 0 .4-.1.4-.2.5-.7.3-1l-.6-1c1-.5 2-.8 3.1-.8v1.2c0 .4.3.8.8.8s.8-.3.8-.8v-1.3c1.1.1 2.2.4 3.1.8l-.6 1c-.2.4-.1.8.3 1 .1.1.2.1.4.1.3 0 .5-.1.7-.4l.6-1c.9.6 1.7 1.4 2.3 2.3l-1 .6c-.4.2-.5.7-.3 1 .1.2.4.4.7.4.1 0 .3 0 .4-.1l1-.6c.5 1 .8 2 .8 3.1H20c-.4 0-.8.3-.8.8s.3.8.8.8h1.2c-.1 1.1-.4 2.2-.8 3.1l-1-.6c-.4-.2-.8-.1-1 .3-.2.4-.1.8.3 1l1.7 1c.1.1.2.1.3.1h.1c.1 0 .2 0 .3-.1h.1c.1-.1.2-.1.3-.3.6-1.5 1.1-3.3 1.1-5.2Z'
    },
    {
      d:
        'm15.7 9.6-2.8 2.8c-.8-.3-1.7-.2-2.3.4-.4.4-.6.9-.6 1.5 0 .3.1.6.2.8l-.6.6c-.3.3-.3.8 0 1.1 0 .2.2.2.4.2s.4-.1.5-.2l.6-.6c.3.1.5.2.8.2.6 0 1.1-.2 1.5-.6.4-.4.6-.9.6-1.5 0-.3-.1-.6-.2-.8l2.8-2.8c.3-.3.3-.8 0-1.1-.3-.3-.6-.3-.9 0Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'dashboard',
  type: 'solid'
}

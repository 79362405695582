import { CIcon } from '~/icons/types'

export const ciCompass: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d: 'm9.2 14.8 3.2-1.4-1.8-1.8-1.4 3.2Zm5.6-5.6-3.2 1.4 1.8 1.8 1.4-3.2Z'
    },
    {
      d:
        'M21.2 12.8H20c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h2.7c-.4-5.3-4.6-9.6-10-10V4c0 .4-.3.8-.8.8s-.7-.4-.7-.8V1.3c-5.3.4-9.6 4.6-10 10H4c.4 0 .8.3.8.8s-.4.7-.8.7H1.3c.4 5.3 4.6 9.6 10 10V20c0-.4.3-.8.8-.8s.8.3.8.8v2.7c5.3-.4 9.6-4.6 10-10h-1.7v.1ZM8.1 16.9c-.1.1-.2.1-.3.1-.2 0-.4-.1-.5-.2-.3-.2-.3-.6-.2-.9L9.6 10c.1-.2.2-.3.4-.4l5.9-2.5c.3-.1.7-.1.9.1.2.2.3.5.2.8l-2.6 6c0 .1-.1.2-.2.2s-.1.1-.2.2l-5.9 2.5Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'compass',
  type: 'solid'
}

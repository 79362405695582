










































































































































































































































































import {
  defineComponent,
  ref,
  computed,
  onMounted
} from '~/utils/nuxt3-migration'
import { useI18n } from '~/compositions/i18n'
import { useLogger } from '~/compositions/logger'
import { useDeps } from '~/compositions/dependency-container'
import { useNamespacedStore } from '~/compositions/store'
import { useSnackbar } from '~/compositions/snackbar'
import FormService from '~/services/form/FormService'
import StripeService from '~/services/logistics/StripeService'
import { ACCOUNT_NS, AccountState } from '~/store/modules/shared/account/state'
import { SET_ACCOUNT_LOADING } from '~/store/modules/shared/account/mutation-types'
import {
  InvoicingInfoForm,
  InvoicingInfoSchema
} from '~/models/payments/stripe'
import { toCamelCase } from '~/utils/object'
import { capitalizeSubStrings } from '~/utils/string'
import CRow from '~/components/shared/configurable/grid/CRow.vue'
import CCol from '~/components/shared/configurable/grid/CCol.vue'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import CFormGroup from '~/components/shared/configurable/form/CFormGroup.vue'
import CInput from '~/components/shared/configurable/form/input/CInput.vue'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import CButton from '~/components/shared/configurable/button/CButton.vue'
import CInputGroup from '~/components/shared/configurable/form/input/CInputGroup.vue'
import CInternationalTelephoneInput from '~/components/shared/configurable/form/input/CInternationalTelephoneInput.vue'
import CFormSelect from '~/components/shared/configurable/form/select/CFormSelect.vue'
import { ciMagnifyingGlass } from '~/icons/source/solid/magnifying-glass'

export default defineComponent({
  components: {
    CRow,
    CCol,
    CFormGroup,
    CInput,
    CFormError,
    CIcon,
    CButton,
    CInputGroup,
    CFormSelect,
    CInternationalTelephoneInput
  },
  props: {
    inPage: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(_, { emit }) {
    const { t } = useI18n()
    const logger = useLogger()
    const snackbar = useSnackbar()
    const [stripeService, formService] = useDeps(StripeService, FormService)
    const { commit, getters } = useNamespacedStore<AccountState>(ACCOUNT_NS)

    const submitbutton = ref<HTMLButtonElement>()
    const form = ref<InvoicingInfoForm>({} as InvoicingInfoForm)
    const schema = ref<InvoicingInfoSchema>({} as InvoicingInfoSchema)
    const errors = ref<InvoicingInfoForm>({} as InvoicingInfoForm)

    const loading = computed(() => getters('getLoading'))
    const afmTooltip = computed(() => {
      return capitalizeSubStrings(
        t('autocomplete business info based on afm').toString(),
        ['αφμ', 'afm'],
        {
          allUppercase: true
        }
      )
    })

    const onSubmit = async () => {
      errors.value = {} as InvoicingInfoForm
      try {
        commit(SET_ACCOUNT_LOADING, true)
        const { values } = await stripeService.submitInvoicingInfoForm(
          form.value
        )
        form.value = values
        emit('form-valid')
      } catch (error) {
        if ((error as any).response?.data?.data?.errors) {
          errors.value = toCamelCase((error as any).response.data.data.errors)
          formService.onErrorScroll({ errorClass: '.c-form-error' })
        }
        logger.captureError(error as any)
      } finally {
        commit(SET_ACCOUNT_LOADING, false)
      }
    }

    const searchForAfm = async () => {
      if (form.value?.afm?.length !== 9) {
        snackbar.error(
          capitalizeSubStrings(
            t('afm must be exactly 9 digits').toString(),
            ['αφμ', 'afm'],
            { allUppercase: true }
          )
        )
        return
      }

      commit(SET_ACCOUNT_LOADING, true)

      try {
        const { info } = await stripeService.getAfmInfo(form.value.afm)

        if (info.city) {
          form.value.address = info.city
        }
        if (info.afm) {
          form.value.afm = info.afm
        }
        if (info.countryId) {
          form.value.countryId = info.countryId
        }
        if (info.doyName) {
          form.value.doyName = info.doyName
        }
        if (info.companyName) {
          snackbar.success(
            t('found company: "{company}"', { company: info.companyName })
          )
          form.value.companyName = info.companyName
        }
        if (info.address) {
          form.value.address = info.address
        }
        if (info.postcode) {
          form.value.postcode = info.postcode
        }
        if (info.occupation) {
          form.value.occupation = info.occupation
        }
      } catch (error) {
        snackbar.error(
          capitalizeSubStrings(
            t('no business details found for this afm').toString(),
            ['αφμ', 'afm'],
            { allUppercase: true }
          )
        )
        logger.captureError(error as any)
      } finally {
        commit(SET_ACCOUNT_LOADING, false)
      }
    }

    const triggerSubmit = () => submitbutton.value?.click()

    onMounted(async () => {
      try {
        const {
          values,
          schema: formSchema
        } = await stripeService.getInvoicingInfoSchemaAndValues()
        if (values.address === 'No address') {
          values.address = null
        }
        form.value = values
        schema.value = formSchema
        emit('schema-loaded')
      } catch (error) {
        logger.captureError(error as any)
      }
    })

    return {
      ciMagnifyingGlass,
      submitbutton,
      schema,
      form,
      errors,
      loading,
      afmTooltip,
      searchForAfm,
      onSubmit,
      triggerSubmit // it is exposed for parent component
    }
  }
})

import { CIcon } from '~/icons/types'
export const ciImageUpload: CIcon = {
  name: 'image-upload',
  type: 'solid',
  width: 56,
  height: 53,
  paths: [
    {
      d:
        'M51.175 6.503A5.09 5.09 0 0 1 54.462 8.6a5.05 5.05 0 0 1 .854 3.807l-3.733 21.19c-.742-.816-1.731-1.495-2.693-2.024V17.813c0-4.346-3.547-7.893-7.892-7.893H9.556l.947-5.405C10.991 1.813 13.7-.107 16.427.374l34.748 6.129ZM40.84 30.182c-5.985.976-10.521 6.301-10.521 12.368 0 .501.018.984.074 1.411.056.427.112.836.204 1.245H5.712a5.104 5.104 0 0 1-5.108-5.108V17.813a5.104 5.104 0 0 1 5.108-5.107h35.286a5.104 5.104 0 0 1 5.107 5.107V30.44c-1.73-.432-3.499-.547-5.265-.259Zm-26.827-4.7a1.4 1.4 0 0 1 1.969 0l4.587 4.588L31.34 19.298a1.401 1.401 0 0 1 1.969 0l10.01 10.01V17.813a2.322 2.322 0 0 0-2.322-2.322H5.713a2.322 2.322 0 0 0-2.322 2.322v18.293l10.623-10.623Zm6.557-3.193a1.393 1.393 0 1 0 0-2.786 1.393 1.393 0 0 0 0 2.786Zm22.285 10.51c2.26 0 4.584.875 6.307 2.331a9.669 9.669 0 0 1 3.443 7.42c0 5.376-4.374 9.75-9.75 9.75-5.313 0-9.75-4.434-9.75-9.75 0-5.376 4.373-9.75 9.75-9.75Zm2.73 9.799a1.392 1.392 0 1 0 1.969-1.97l-3.715-3.714a1.413 1.413 0 0 0-1.97 0l-3.713 3.714a1.392 1.392 0 1 0 1.97 1.97l1.336-1.337v5.923a1.393 1.393 0 0 0 2.785 0v-5.923l1.337 1.337Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ]
}

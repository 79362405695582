

































































































































































import CSlider from '~/components/shared/configurable/slider/CSlider.vue'
import { ciZoomIn } from '~/icons/source/regular/zoomIn'
import { ciZoomOut } from '~/icons/source/regular/zoomOut'
import AccountSpinner from '~/components/shared/account/AccountSpinner.vue'
import { defineComponent, vue3Model } from '~/utils/nuxt3-migration'
import { useFile } from '~/compositions/files/file'
import { ciAngleUp } from '~/icons/source/solid/angle-up'

export default defineComponent({
  model: vue3Model,
  components: {
    AccountSpinner,
    CSlider
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    file: {
      type: Object,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    targetWidth: {
      type: Number,
      required: false,
      default: 500
    },
    targetHeight: {
      type: Number,
      required: false,
      default: 500
    },
    roundedCropper: {
      type: Boolean,
      required: false,
      default: true
    },
    closeIfExact: {
      type: Boolean,
      required: false,
      default: false
    },
    forceCrop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const { getCroppedImage, getAverageColorsFromImage } = useFile()
    return { getCroppedImage, getAverageColorsFromImage }
  },
  data() {
    return {
      baseHeight: 500,
      baseWidth: 500,
      scaledCropperWidth: 500,
      scaledCropperHeight: 500,
      scrollX: 0,
      scrollY: 0,
      initialX: null,
      initialY: null,
      initialScrollX: null,
      initialScrollY: null,
      swapDirection: null,
      dragging: false,
      transit: false,
      diffX: 0,
      diffY: 0,
      timeStart: 0,
      timeFinish: 0,
      lastMoveTime: 0,
      zoom: 0,
      resultSrc: null,
      bgColorHex: '#ffffff'
    }
  },
  computed: {
    visible: {
      get() {
        return this.modelValue
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value)
      }
    },
    icons() {
      return {
        zoomIn: ciZoomIn,
        zoomOut: ciZoomOut,
        center: ciAngleUp
      }
    },
    imageSource() {
      return this.file?.thumb || null
    },
    isTouch() {
      return 'ontouchstart' in window
    },
    targetMinimumSize() {
      return 480
    },
    horizontalImage() {
      return this.baseWidth >= this.baseHeight
    },
    zoomPercentage() {
      if (this.zoom < 0) {
        const zoomAsDecimal = Math.abs(this.zoom) / 100

        const baseDimension = this.horizontalImage
          ? this.baseWidth
          : this.baseHeight

        const divideFactor =
          (baseDimension - this.targetMinimumSize) / this.targetMinimumSize

        return zoomAsDecimal / (1 + divideFactor / zoomAsDecimal)
      }
      return this.zoom / 100
    },
    appliedWidth() {
      return (
        this.baseWidth *
        (this.zoom >= 0 ? 1 + this.zoom / 100 : 1 / (1 - this.zoom / 100))
      )
    },
    appliedHeight() {
      return (
        this.baseHeight *
        (this.zoom >= 0 ? 1 + this.zoom / 100 : 1 / (1 - this.zoom / 100))
      )
    },
    minZoom() {
      return -200 // %
    },
    maxZoom() {
      return 200 // %
    },
    zoomInterval() {
      return 5
    },
    debug() {
      return false
    }
  },
  watch: {
    file(val) {
      if (val) {
        this.onFileLoad()
      }
    }
  },
  beforeDestroy() {
    this.removeScrollListeners()
  },
  methods: {
    onHide() {
      this.removeScrollListeners()
      this.$emit('hide')
      this.baseHeight = 500
      this.baseWidth = 500
      this.scaledCropperHeight = 500
      this.scaledCropperWidth = 500
      this.scrollX = 0
      this.scrollY = 0
      this.initialX = null
      this.initialY = null
      this.initialScrollX = null
      this.initialScrollY = null
      this.swapDirection = null
      this.dragging = false
      this.transit = false
      this.diffX = 0
      this.diffY = 0
      this.timeStart = 0
      this.timeFinish = 0
      this.lastMoveTime = 0
      this.zoom = 0
    },
    async save(okFunction: Function) {
      await this.$nextTick()
      if (this.file) {
        const croppedFile = await this.getCroppedImage(
          this.file,
          this.scrollX,
          this.scrollY,
          this.appliedWidth,
          this.appliedHeight,
          this.scaledCropperWidth,
          this.scaledCropperHeight,
          this.targetWidth,
          this.targetHeight,
          this.bgColorHex
        )
        this.$emit('on-result', croppedFile)
        okFunction()
      }
    },
    saveNoCrop(okFunction: Function) {
      this.$emit('on-result', this.file)
      okFunction()
    },
    async calcResult() {
      if (!this.debug) {
        return
      }
      this.resultSrc = await this.getCroppedImage(
        this.file,
        this.scrollX,
        this.scrollY,
        this.appliedWidth,
        this.appliedHeight,
        this.scaledCropperWidth,
        this.scaledCropperHeight,
        this.targetWidth,
        this.targetHeight,
        this.bgColorHex
      )
    },
    zoomChange() {
      this.checkScrollLimits()
    },
    resetZoom() {
      this.zoom = 0
      this.scrollX = 0
      this.scrollY = 0
      this.checkScrollLimits()
    },
    zoomIn() {
      if (this.zoom + this.zoomInterval <= this.maxZoom) {
        this.transit = true
        this.zoom = parseFloat((this.zoom + this.zoomInterval).toFixed(2))
        this.checkScrollLimits()
      }
    },
    zoomOut() {
      if (this.zoom - this.zoomInterval >= this.minZoom) {
        this.transit = true
        this.zoom = parseFloat((this.zoom - this.zoomInterval).toFixed(2))
        this.checkScrollLimits()
      }
    },
    calcScaledCropperWidth() {
      // 1) Start with the "target" dimension
      let w = this.targetWidth
      let h = this.targetHeight

      const containerWidth = this.$refs.scrollerCont?.offsetWidth || 500

      if (w > containerWidth) {
        const scale = containerWidth / w
        w = containerWidth
        h = h * scale
      }

      const containerHeight = this.$refs.scrollerCont?.offsetHeight || 500
      // 3) If height > 500, scale both dimensions down again
      if (h > containerHeight) {
        const scale = containerHeight / h
        h = containerHeight
        w = w * scale
      }
      this.scaledCropperWidth = w
    },
    calcScaledCropperHeight() {
      // We repeat the exact same logic
      let w = this.targetWidth
      let h = this.targetHeight

      const containerWidth = this.$refs.scrollerCont?.offsetWidth || 500
      if (w > containerWidth) {
        const scale = containerWidth / w
        w = containerWidth
        h = h * scale
      }

      const containerHeight = this.$refs.scrollerCont?.offsetHeight || 500
      if (h > containerHeight) {
        const scale = containerHeight / h
        h = containerHeight
        w = w * scale
      }
      this.scaledCropperHeight = h
    },
    async onFileLoad() {
      if (this.file.height && this.file.width) {
        if (
          this.file.width === this.targetWidth &&
          this.file.height === this.targetHeight &&
          this.closeIfExact
        ) {
          this.$emit('on-result', this.file)
          this.$emit('update:modelValue', false)
          return
        }
        this.calcScaledCropperWidth()
        this.calcScaledCropperHeight()

        // consider removing the following logic now that we have custom dimension cropping
        // however, this keeps ui behavior a bit better on mobile so lets leave it for now
        const squareDisplaySize = this.$refs.cropperWindow.offsetWidth
        let height = squareDisplaySize
        let width = (squareDisplaySize * this.file.width) / this.file.height
        if (width < squareDisplaySize) {
          width = squareDisplaySize
          height = (squareDisplaySize * this.file.height) / this.file.width
        }

        this.baseHeight = height
        this.baseWidth = width
        this.addScrollListeners()

        await this.$nextTick()
        if (this.$refs.avatar) {
          this.bgColorHex = this.getAverageColorsFromImage(this.$refs.avatar)
        }
      }
    },
    addScrollListeners() {
      this.$nextTick(() => {
        if (!this.$refs.scroller) {
          return
        }
        this.$refs.scroller.addEventListener('selectstart', this.disableSelect)

        if (this.isTouch) {
          this.$refs.scroller.addEventListener('touchstart', this.startInput, {
            passive: true
          })
        } else {
          this.$refs.scroller.addEventListener('mousedown', this.startInput, {
            passive: true
          })

          this.$refs.scroller.addEventListener('mousewheel', this.mousewheel, {
            passive: true
          })
        }
      })
    },
    startInput(e) {
      if (!this.isTouch && e.button !== 0) {
        // not left click
        return
      }

      if (this.isTouch) {
        document.addEventListener('touchend', this.stopInput, {
          passive: true
        })

        document.addEventListener('touchmove', this.moveInput, {
          passive: true
        })
      } else {
        document.addEventListener('mouseup', this.stopInput, {
          passive: true
        })
        document.addEventListener('mousemove', this.moveInput, {
          passive: true
        })
      }

      if (this.isTouch) {
        this.initialX = e.touches[0].clientX
        this.initialY = e.touches[0].clientY
      } else {
        this.initialX = e.clientX
        this.initialY = e.clientY
      }

      this.initialScrollX = -this.scrollX
      this.initialScrollY = -this.scrollY

      this.transit = false
      this.timeStart = new Date().getTime()
    },
    disableSelect(e) {
      e.preventDefault()
    },
    moveInput(e) {
      if (this.initialX === null && this.initialY === null) {
        return
      }

      let currentX = null
      let currentY = null

      if (this.isTouch) {
        currentX = e.touches[0].clientX
        currentY = e.touches[0].clientY
      } else {
        currentX = e.clientX
        currentY = e.clientY
      }

      this.diffX = this.initialX - currentX
      this.diffY = this.initialY - currentY

      if (Math.abs(this.diffX) > 0 || Math.abs(this.diffY) > 0) {
        this.lastMoveTime = new Date().getTime()
        // we scroll
        this.dragging = true

        const calculatedX = -(this.initialScrollX + this.diffX)

        // x limits
        if (
          this.$refs.cropperWindow &&
          calculatedX <=
            (this.appliedWidth - this.$refs.cropperWindow.offsetWidth) / 2 &&
          calculatedX >=
            -(this.appliedWidth - this.$refs.cropperWindow.offsetWidth) / 2
        ) {
          this.scrollX = calculatedX
        }

        const calculatedY = -(this.initialScrollY + this.diffY)

        // y limits
        if (
          this.$refs.cropperWindow &&
          calculatedY <=
            (this.appliedHeight - this.$refs.cropperWindow.offsetHeight) / 2 &&
          calculatedY >=
            -(this.appliedHeight - this.$refs.cropperWindow.offsetHeight) / 2
        ) {
          this.scrollY = calculatedY
        }

        e.stopImmediatePropagation()
        e.stopPropagation()
        // e.preventDefault()

        if (this.diffX > 10) {
          // swiped left
          this.swapDirection = 'left'
        } else if (this.diffX < -10) {
          // swiped right
          this.swapDirection = 'right'
        }
      } else {
        this.swapDirection = null
        this.dragging = false // so that it gets clicked instantly
      }

      if (this.preventVertical && this.swapDirection && e.cancelable) {
        e.preventDefault()
      }
    },
    async stopInput() {
      if (!this.transit) {
        this.$emit('move-done')
      }
      this.transit = true

      this.timeFinish = new Date().getTime()

      this.checkScrollLimits()

      this.swapDirection = null
      this.initialX = null
      this.initialY = null
      this.transit = true
      this.diffX = null
      this.diffY = null

      this.removeDocumentListeners()

      await this.$nextTick()
      this.dragging = false
    },
    checkScrollLimits() {
      if (this.$refs.cropperWindow) {
        const cropperWidth = this.$refs.cropperWindow.offsetWidth
        const cropperHeight = this.$refs.cropperWindow.offsetHeight

        // X-axis: if appliedWidth is smaller than or equal to cropper, center it.
        if (this.appliedWidth <= cropperWidth) {
          this.scrollX = 0
        } else {
          const xLimit = (this.appliedWidth - cropperWidth) / 2
          if (this.scrollX > xLimit) {
            this.scrollX = xLimit
          } else if (this.scrollX < -xLimit) {
            this.scrollX = -xLimit
          }
        }

        // Y-axis: if appliedHeight is smaller than or equal to cropper, center it.
        if (this.appliedHeight <= cropperHeight) {
          this.scrollY = 0
        } else {
          const yLimit = (this.appliedHeight - cropperHeight) / 2
          if (this.scrollY > yLimit) {
            this.scrollY = yLimit
          } else if (this.scrollY < -yLimit) {
            this.scrollY = -yLimit
          }
        }

        // Now recalc the result with the adjusted scroll positions.
        this.calcResult()
      }
    },
    mousewheel(e) {
      if (!this.dragging) {
        if (Math.abs(e.deltaX) !== 0) {
          if (e.deltaX > 0) {
            this.moveRight(e.deltaX)
          } else {
            this.moveLeft(Math.abs(e.deltaX))
          }
        }

        if (Math.abs(e.deltaY) !== 0) {
          if (e.deltaY > 0) {
            this.zoomOut()
          } else {
            this.zoomIn()
          }
        }
      }
    },
    moveToStart() {
      this.scrollX =
        (this.appliedWidth - this.$refs.cropperWindow.offsetWidth) / 2
      this.checkScrollLimits()
      this.transit = true
    },
    moveLeft(amount) {
      this.scrollX += amount
      if (
        this.scrollX >=
        (this.appliedWidth - this.$refs.cropperWindow.offsetWidth) / 2
      ) {
        this.moveToStart()
      }
    },
    moveRight(amount) {
      this.scrollX -= amount
      if (
        this.$refs.cropperWindow &&
        this.scrollX <=
          -(this.appliedWidth - this.$refs.cropperWindow.offsetWidth) / 2
      ) {
        this.scrollX =
          -(this.appliedWidth - this.$refs.cropperWindow.offsetWidth) / 2
        this.checkScrollLimits()
        this.transit = true
      }
    },
    removeScrollListeners() {
      if (!this.$refs.scroller) {
        return
      }
      this.$refs.scroller.removeEventListener('mousedown', this.startInput)
      this.$refs.scroller.removeEventListener('selectstart', this.disableSelect)
      this.$refs.scroller.removeEventListener('touchstart', this.startInput)
      this.$refs.scroller.removeEventListener('mousewheel', this.mousewheel)
      this.removeDocumentListeners()
    },
    removeDocumentListeners() {
      document.removeEventListener('mousemove', this.moveInput)
      document.removeEventListener('touchmove', this.moveInput)
      document.removeEventListener('mouseup', this.stopInput)
      document.removeEventListener('touchend', this.stopInput)
    },
    onModalClose() {
      this.visible = false
    }
  }
})

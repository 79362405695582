












































import {
  computed,
  defineComponent,
  toRefs,
  vue3Model
} from '~/utils/nuxt3-migration'
import CTransitionExpand from '~/components/shared/configurable/expand/CTransitionExpand.vue'

export default defineComponent({
  components: { CTransitionExpand },
  model: vue3Model,
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    large: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: true
    },
    labelClass: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { disabled, value } = toRefs(props)

    const internalValue = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
        emit('change', value)
      }
    })

    const cursorClass = computed(() => {
      if (disabled.value) {
        return internalValue.value === value.value
          ? 'tw-cursor-default'
          : 'tw-cursor-not-allowed'
      }
      return 'tw-cursor-pointer'
    })

    const containerClass = computed(() => {
      if (disabled.value) {
        return internalValue.value !== value.value ? 'tw-opacity-75' : ''
      }
      return 'hover:tw-bg-grey-50'
    })

    return { internalValue, cursorClass, containerClass }
  }
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-col",class:{
    'tw-rounded-xl tw-border tw-border-solid tw-border-grey-300 tw-p-8 tw-pt-6': !_vm.noBorder
  }},[_c('h3',{staticClass:"tw-text-grey-900 tw-font-extrabold tw-flex tw-justify-between",class:[
      _vm.inQuickForm ? 'tw-text-xl ' : 'tw-text-3xl',
      { 'tw-mb-6': !_vm.hasSubtitle, 'tw-mb-1': _vm.hasSubtitle }
    ]},[_c('span',[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_vm._t("status")],2),_vm._v(" "),(_vm.hasSubtitle)?_c('p',{staticClass:"tw-mb-8",class:_vm.inQuickForm
        ? 'tw-text-base tw-text-grey-900'
        : 'tw-text-xl tw-font-medium tw-text-grey-500'},[(_vm.$slots.subtitle)?_vm._t("subtitle"):_c('span',[_vm._v(_vm._s(_vm.subtitle))])],2):_vm._e(),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
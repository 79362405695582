import { CIcon } from '~/icons/types'

export const ciEngine: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'm23.7 11.5-1.4-1.4c-.2-.2-.4-.3-.7-.3h-1.4c-.6 0-1 .4-1 1v1.6h-.7v-.6c0-.3-.1-.5-.3-.7l-1.9-1.9c-.1-.1-.4-.2-.7-.2v-.9c0-1.1-.9-1.9-1.9-1.9h-2.1v-.7h2.1c.6 0 1-.4 1-1s-.4-1-1-1H7.6c-.6 0-1 .4-1 1s.4 1 1 1h2.1v.7H8.2c-.7 0-1.3.4-1.7 1L5.5 9h-.8c-1.1 0-1.9.9-1.9 1.9v1.5H2v-1.9c0-.6-.4-1-1-1s-1 .4-1 1v5.8c0 .6.4 1 1 1s1-.4 1-1v-1.9h.8v2.1c0 1.1.9 1.9 1.9 1.9h1.9l.5 1c.3.7 1 1.1 1.7 1.1h7.8c1.1 0 1.9-.9 1.9-1.9v-1.4h.7v1.6c0 .6.4 1 1 1h1.4c.3 0 .5-.1.7-.3l1.4-1.4c.2-.2.3-.4.3-.7v-5.1c0-.3-.1-.6-.3-.8ZM8.2 8.2h5.4V9H7.8l.4-.8Zm.7 10.4L8.1 17c-.2-.3-.5-.6-.9-.6H4.8V11h10.4l1.3 1.3v6.3H8.9Zm10.3-3.4h-.7v-.7h.7v.7ZM22 17l-.7.7v-5.8l.7.7V17Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'engine',
  type: 'regular'
}




































import { computed, defineComponent, ref, watch } from '~/utils/nuxt3-migration'
import { useAdForm } from '~/compositions/advertise/ad-form'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'

export default defineComponent({
  components: {
    CFormError
  },
  setup() {
    const {
      getSchemaField,
      emitParams,
      loading,
      errors,
      editMode,
      adValues
    } = useAdForm()
    const periods = computed(() => {
      return (
        getSchemaField('impressions_per_period_period')?.options.map(o => {
          return { text: o.label.toLowerCase(), value: o.value }
        }) || []
      )
    })

    const getImpressions = () => {
      return editMode.value ? adValues.value.impressionsPerPeriodPerUser : 3
    }
    const impressions = ref(getImpressions())

    const getPeriod = () => {
      return editMode.value ? adValues.value.impressionsPerPeriodPeriod : 'day'
    }
    const period = ref(getPeriod())

    // emit the default params for starters
    emitParams('impressionsPerPeriodPeriod', period.value)
    emitParams('impressionsPerPeriodPerUser', impressions.value)

    const schemaField = computed(() => {
      return getSchemaField('impressions_per_period_per_user')
    })

    const onImpressionsInput = () => {
      emitParams('impressionsPerPeriodPerUser', Number(impressions.value))
    }

    const onPeriodChange = () => {
      emitParams('impressionsPerPeriodPeriod', period.value)
    }

    watch(adValues, (val, oldVal) => {
      if (
        val.impressionsPerPeriodPerUser !== oldVal.impressionsPerPeriodPerUser
      ) {
        impressions.value = getImpressions()
      }
      if (
        val.impressionsPerPeriodPeriod !== oldVal.impressionsPerPeriodPeriod
      ) {
        period.value = getPeriod()
      }
    })

    return {
      impressions,
      getSchemaField,
      loading,
      errors,
      emitParams,
      period,
      periods,
      schemaField,
      onImpressionsInput,
      onPeriodChange
    }
  }
})

import { CIcon } from '~/icons/types'

export const ciWeight: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M22 19.8 21.4 8c-.1-1.4-1.3-2.6-2.7-2.6h-4.3c.2-.4.4-.9.4-1.4 0-1.5-1.2-2.8-2.8-2.8-1.6 0-2.8 1.3-2.8 2.8 0 .5.2 1 .4 1.5H5.4C3.9 5.5 2.7 6.6 2.6 8l-.7 11.8c0 .8.2 1.5.7 2.1.5.5 1.2.9 2 .9h14.7c.8 0 1.5-.3 2-.9.5-.5.8-1.3.7-2.1Zm-10.3-1.2c-.1.2-.3.2-.5.2s-.4-.1-.5-.2l-2.1-2v1.5c0 .4-.3.8-.8.8s-.8-.4-.8-.8v-7.9c0-.4.3-.8.8-.8s.8.3.8.8v2.2l1.9-2.7c.2-.3.7-.4 1-.2.3.2.4.7.2 1l-2.9 4.1 2.9 2.8c.3.4.3.9 0 1.2ZM10.8 4c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2Zm6.6 11.5c-.3 2-1.2 3.3-2.3 3.3-1.6 0-2.4-2.4-2.4-4.7 0-2.3.8-4.7 2.4-4.7.5 0 1.3.2 1.9 1.7.2.4 0 .8-.4 1-.4.2-.8 0-1-.4-.2-.6-.5-.8-.5-.8-.3 0-.9 1.2-.9 3.2s.7 3.2.9 3.2c.1 0 .6-.6.8-2 0-.1 0-.3.1-.4h-.1c-.4 0-.8-.3-.8-.8s.3-.8.8-.8h.8c.4 0 .8.3.8.8s0 1-.1 1.4Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'weight',
  type: 'solid'
}

import { CIcon } from '~/icons/types'
export const ciInvoice: CIcon = {
  width: 532,
  height: 512,
  name: 'invoice',
  paths: [
    {
      d:
        'M65.9 1.5c-1.5.8-3.2 2.9-3.8 4.6-1.6 4.5-1.6 495.3 0 499.8 2.3 6.6-12 6.1 193.9 6.1 208 0 191.9.6 194-6.8.8-2.6 1-63.2.8-206.5L450.5 96l-48-48-48-48h-143C89.2.1 68.1.3 65.9 1.5zM343 60.2c.1 31.7.4 42.7 1.3 43.9 2.9 3.8 4.6 3.9 47.1 3.9H433v386H79V18h264v42.2zm77 29.5c0 .2-13.3.3-29.5.3H361V31.8l29.5 28.8C406.7 76.5 420 89.5 420 89.7z',
      fill: 'currentColor'
    },
    {
      d:
        'M253.4 157.4c-24.3 5.5-45.7 23.6-54.9 46.3l-2.4 5.8-10 .6c-10.7.6-13.3 1.7-15.2 6.7-1.1 2.9 1.1 8.6 3.9 10.1 1.2.6 5.5 1.1 9.6 1.1h7.6v3.7c0 2.1.3 6.2.6 9l.7 5.3h-7.7c-4.8 0-8.8.6-10.5 1.5-6.2 3.2-5.9 12.4.5 15.1 2.1.9 7 1.4 13.3 1.4h9.9l1.1 3.1c1.9 5.6 10 16.5 17 23.2 20.3 19.1 47.4 25.9 74.5 18.5 4.8-1.3 8.9-3.1 10.3-4.4 3.2-3.2 3.1-8.7-.2-11.9-2.9-3-6.4-3.2-14.3-.8-7.7 2.3-25.8 2.4-32.7.1-15-4.9-25-12-34-24.1l-2.7-3.7h28.6c32.6 0 34.6-.3 36.6-6.4.9-2.8.9-4.1-.2-6.3-2.4-5.1-3.7-5.3-38.9-5.3h-32.6l-.7-4.6c-.3-2.6-.6-6.6-.6-9V228h75.1l2.4-2.5c3.5-3.4 3.4-9.3-.1-12.6l-2.6-2.4-34.9-.3-35-.3 3.3-6.1c1.8-3.4 6-8.9 9.4-12.2 10.3-10.2 21.3-15.6 36-17.7 8.5-1.2 11.9-.8 24.6 2.4l8.7 2.1 2.8-2c3.5-2.7 5-6.5 3.7-10-2.2-6.4-11.9-9.5-30.9-10-8.7-.2-15 .1-19.1 1zM126.8 348.1c-1.5.6-3.3 2.5-4.2 4.4-1.5 3-1.5 3.8-.2 6.9.8 1.9 2.3 3.9 3.4 4.5 3 1.6 125.5 1.5 128.5-.1 5.3-2.8 6.3-8.8 2.3-13.5l-2.4-2.8-62.4-.2c-39-.1-63.3.2-65 .8zm-1.1 45c-1.1.6-2.5 2.6-3.3 4.5-1.6 3.8-.8 6.8 2.6 9.9 2.2 2 3.6 2 126.6 2H376l2.7-2.7c3.8-3.8 3.7-9.2-.1-12.4l-2.7-2.4H251.7c-86.5.1-124.8.4-126 1.1zm2.3 43.6c-2.4.9-5.2 3.6-6.1 6.1-1.1 2.9 1.1 8.6 3.9 10.1 1.5.8 39.1 1.1 125.9 1.1 111.9 0 124.1-.2 126.1-1.6 3.4-2.3 4.5-6 3.1-10.1-2.2-6.7 5-6.3-129.6-6.2-67 0-122.5.3-123.3.6z',
      fill: 'currentColor'
    }
  ],
  type: 'regular'
}












































































































import { mapActions, mapGetters } from 'vuex'

import CSwipeMixin from '~/components/shared/configurable/mixin/CSwipeMixin.vue'
import { ZoomerFile } from './types'
import CDragScroll from '~/components/shared/configurable/CDragScroll.vue'
import CCarouselThumb from '~/components/shared/configurable/image/carousel/CCarouselThumb.vue'
import { useModalToggle } from '~/compositions/modal-toggle.ts'
import CVideoPlayer from '~/components/shared/configurable/video/CVideoPlayer.vue'
import ShortVideosButtons from '~/components/shared/short-videos/ShortVideosButtons.vue'
import { Reactions } from '~/models/short-video'
import { Classified } from '~/models/classified/types'
import { PAGE_NS } from '~/store/modules/shared/page/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { ciTimes } from '~/icons/source/solid/times'
import { ciChevronLeft } from '~/icons/source/solid/chevron-left'
import { ciChevronRight } from '~/icons/source/solid/chevron-right'

interface Data {
  activeIndex: number
  showNavsMobile: boolean
  icons: object
}

export default defineComponent({
  components: { CCarouselThumb, CDragScroll, ShortVideosButtons, CVideoPlayer },
  mixins: [CSwipeMixin],
  setup() {
    const modalToggleFunc = useModalToggle()
    return { modalToggleFunc }
  },
  props: {
    // supported files: images and pdfs
    filesToShow: {
      type: Array as PropType<ZoomerFile[]>,
      required: true
    },
    startIndex: {
      type: Number,
      required: false,
      default: 0
    },
    usePortal: {
      type: Boolean,
      required: false,
      default: false
    },
    modalInSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    classified: {
      type: Object as PropType<Classified>,
      required: false,
      default: null
    }
  },
  data(): Data {
    return {
      activeIndex: 0,
      showNavsMobile: true,
      thumbsLoaded: 0,
      canClickThumbs: true,
      shortVideoWidth: 0,
      shortVideoHeight: 0,
      internalFilesToShow: []
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    icons() {
      return {
        close: ciTimes,
        chevronLeft: ciChevronLeft,
        chevronRight: ciChevronRight
      }
    },
    activeFile() {
      return this.internalFilesToShow[this.activeIndex]
    },
    isSwipable() {
      return this.internalFilesToShow.length > 1
    },
    hasImages() {
      return this.internalFilesToShow.some(
        f => f.type === 'image' || f.type === 'img'
      )
    },
    hasPdfs() {
      return this.internalFilesToShow.some(f => f.type === 'pdf')
    },
    hasShortVideos() {
      return this.internalFilesToShow.some(f => f.type === 'shortVideo')
    }
  },
  watch: {
    filesToShow: {
      immediate: true,
      handler(val) {
        this.internalFilesToShow = val
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.navigate)
    if (this.hasShortVideos) {
      window.removeEventListener('resize', this.handleResize)
    }
  },
  mounted() {
    if (this.hasShortVideos) {
      this.handleResize()
      window.addEventListener('resize', this.handleResize, false)
      if (this.internalFilesToShow[this.startIndex].type === 'shortVideo') {
        this.$refs.videoPlayer.playVideo()
      }
    }
    this.modalToggleFunc.setModalInSearch(this.modalInSearch)
    this.modalToggleFunc.setOnModalCloseCallback(this.onModalClose)
    this.show(this.startIndex)
  },
  methods: {
    ...mapActions(PAGE_NS, {
      addBodyClass: 'addBodyClass'
    }),
    show(index) {
      // this.activeIndex = index
      document.addEventListener('keyup', this.navigate)
      this.modalToggleFunc.modalOpen()
      this.$nextTick(() => {
        if (this.isSwipable) {
          if (this.$refs.scroller) {
            this.$refs.scroller.calcDsWidth()
          }
          this.activateThumb(index)
        }
      })
    },
    onModalClose() {
      document.removeEventListener('keyup', this.navigate)
      this.$emit('zoomer-closed')
    },
    imgLoad(index) {
      if (this.$refs.scroller) {
        this.$refs.scroller.calcDsWidth()
      }
      this.$nextTick(() => {
        if (this.activeIndex === index) {
          this.activateThumb(index)
        }
      })
    },
    navigate(event) {
      const code = event.keyCode
      if (code === 39) {
        // right arrow
        this.nextThumb()
      }

      if (code === 37) {
        // left arrow
        this.prevThumb()
      }
    },
    nextThumb() {
      let goTo = this.activeIndex + 1
      if (goTo >= this.internalFilesToShow.length) {
        goTo = 0
      }
      this.activateThumb(goTo)
    },
    prevThumb() {
      let goTo = this.activeIndex - 1
      if (goTo < 0) {
        goTo = this.internalFilesToShow.length - 1
      }
      this.activateThumb(goTo)
    },
    activateThumb(thumbIndex) {
      if (!this.canClickThumbs) {
        return
      }

      this.activeIndex = thumbIndex

      const scroller = this.$refs.scroller
      if (scroller) {
        scroller.setActiveChildIndex(thumbIndex)
      }
    },
    onSwipeRight() {
      this.prevThumb()
    },
    onSwipeLeft() {
      this.nextThumb()
    },
    imgClick() {
      if (!this.isPc) {
        this.toggleNavs()
      }
    },
    toggleNavs() {
      this.showNavsMobile = !this.showNavsMobile
    },
    increaseThumbsLoaded() {
      this.thumbsLoaded++
      if (this.thumbsLoaded === this.internalFilesToShow.length) {
        // we loaded the active thumb
        this.$nextTick(() => {
          this.activateThumb(this.activeIndex)
        })
      }
    },
    handleResize() {
      const modalHeaderHeight = 50
      const carouselHeight = this.$refs.scroller?.$el?.clientHeight
      const height =
        window.innerHeight - (modalHeaderHeight + (carouselHeight || 0))
      const width = (height - 35) / 1.77
      this.shortVideoWidth = width

      if (window.innerWidth >= width) {
        this.shortVideoHeight = `${height - 14}px`
      } else {
        this.shortVideoHeight = 'auto'
      }
    },
    async removeThumbIndex(i) {
      this.$emit('remove-file-index', i)
      await this.$nextTick()
      if (this.$refs.scroller) {
        this.$refs.scroller.calcDsWidth()
      }
    },
    handleCreateReaction({
      videoId,
      reaction
    }: {
      videoId: number
      reaction: Reactions
    }) {
      const index = this.internalFilesToShow.findIndex(
        sv => sv.video.id === videoId
      )
      if (index !== -1) {
        const { reactions } = this.internalFilesToShow[index].video

        this.internalFilesToShow[index].video.reactions = {
          ...reactions,
          [reaction]: reactions[reaction] + 1
        }
        this.setReaction(index, reaction, true)
      }
    },
    handleDeleteReaction({
      videoId,
      reaction
    }: {
      videoId: number
      reaction: Reactions
    }) {
      const index = this.internalFilesToShow.findIndex(
        sv => sv.video.id === videoId
      )
      if (index !== -1) {
        const { reactions } = this.internalFilesToShow[index].video
        this.internalFilesToShow[index].video.reactions = {
          ...reactions,
          [reaction]: reactions[reaction] - 1
        }
        this.setReaction(index, reaction, false)
      }
    },
    setReaction(index: number, reaction: Reactions, status: boolean) {
      this.$set(this.internalFilesToShow[index], 'video', {
        ...this.internalFilesToShow[index].video,
        myReactions: {
          [reaction]: status
        }
      })
    },
    addClass() {
      setTimeout(() => this.addBodyClass('modal-open'))
    }
  }
})

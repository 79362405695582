import { CIcon } from '~/icons/types'

export const ciStar: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'm22.6 9.4-.2-.2c-.1-.1-.2-.1-.2-.1-.1 0-.2-.1-.3-.1-.2 0-.5-.1-.8-.1-.7-.1-1.5-.2-2.4-.3-.9-.1-1.7-.2-2.4-.3-.3 0-.5-.1-.7-.1-.1-.1-.1-.3-.2-.5-.3-.6-.6-1.3-1-2s-.7-1.5-1.1-2.1c-.1-.3-.3-.6-.4-.8l-.3-.3-.2-.2c-.1 0-.2-.1-.4-.1s-.3.1-.4.1c-.1 0-.2.1-.2.2l-.3.3c-.1.2-.3.5-.4.8-.3.6-.7 1.3-1.1 2.1-.4.7-.7 1.5-1 2-.1.2-.1.4-.2.5-.2 0-.4 0-.7.1-.6.1-1.5.2-2.3.3-.9.1-1.8.2-2.4.3-.4 0-.7.1-.9.1-.1.1-.2.1-.3.1 0 0-.1 0-.2.1l-.2.2c-.1.1-.2.2-.2.5 0 .2 0 .3.1.3 0 .1 0 .1.1.1 0 .1.1.1.1.1l.2.2c.1.2.3.4.6.6.5.4 1.1 1 1.7 1.6.6.6 1.3 1.1 1.7 1.5.2.2.3.3.5.4 0 .2-.1.3-.1.6-.1.6-.3 1.4-.4 2.2-.2.8-.3 1.6-.4 2.3-.1.3-.1.6-.1.8v.7c0 .1.1.2.2.3.1.1.3.2.4.2h.4c.1 0 .2-.1.3-.1.2-.1.5-.2.8-.3.6-.3 1.4-.7 2.1-1.1.8-.4 1.5-.8 2.1-1.1l.6-.3c.2.1.3.2.6.3.6.3 1.3.7 2.1 1.1.8.4 1.5.8 2.1 1.1.3.1.6.3.8.3.1 0 .2.1.4.1h.3c.1 0 .3-.1.5-.2.1-.1.2-.3.2-.4v-.6c0-.2 0-.5-.1-.9-.1-.6-.3-1.5-.4-2.3-.2-.8-.3-1.6-.5-2.2 0-.2-.1-.4-.1-.5.1-.1.3-.2.4-.4.5-.4 1.1-1 1.7-1.5.6-.6 1.3-1.1 1.7-1.6l.6-.6c.1-.1.1-.2.2-.2l.1-.1s0-.1.1-.1c0 0 .1-.2.1-.3-.1-.3-.3-.4-.3-.5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'star',
  type: 'solid'
}

export function useFileUpload() {
  function filesAreOfAcceptableType(
    fileList: FileList,
    availableTypes: string[]
  ) {
    for (const file of Array.from(fileList)) {
      if (file.type) {
        if (!availableTypes.includes(file.type)) {
          return false
        }
      } else if (file.name) {
        // no type detected, get type using file name
        const fileExtension = file.name.split('.').pop()
        if (typeof fileExtension === 'string') {
          return (
            availableTypes.includes(`image/${fileExtension.toLowerCase()}`) ||
            availableTypes.includes(fileExtension)
          )
        } else {
          return false
        }
      }
    }
    return true
  }
  return {
    filesAreOfAcceptableType
  }
}

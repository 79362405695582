import { Icon } from '~/models/common/types'
import { ciPiston } from '~/icons/source/light/piston'
import { ciBreakAlt } from '~/icons/source/light/break-alt'
import { ciInsurance } from '~/icons/source/light/insurance'
import { ciAirConditioner } from '~/icons/source/light/air-conditioner'
import { ciDamper } from '~/icons/source/light/damper'
import { ciAirbag1 } from '~/icons/source/regular/airbag-1'
import { ciShield } from '~/icons/source/light/shield'
import { ciRim } from '~/icons/source/regular/rim'
import { ciTransport } from '~/icons/source/regular/transport-1'
import { ciGearshift1 } from '~/icons/source/light/gearshift-1'
import { ciEngine } from '~/icons/source/regular/engine'
import { ciIdea } from '~/icons/source/light/idea'
import { ciRadio } from '~/icons/source/regular/radio'
import { ciDiscBrake1 } from '~/icons/source/light/disc-brake-1'
import { ciTire } from '~/icons/source/light/tire'
import { ciLightBulb1 } from '~/icons/source/light/light-bulb-1'
import { ciBreak } from '~/icons/source/light/break'
import { ciPowerButton } from '~/icons/source/light/power-button'
import { ciElevator } from '~/icons/source/regular/elevator'
import { ciQuality } from '~/icons/source/light/quality'
import { ciSofa } from '~/icons/source/regular/sofa'
import { ciPower } from '~/icons/source/light/power'
import { ciAirConditionerAlt } from '~/icons/source/light/air-conditioner-alt'
import { ciCarDoor1 } from '~/icons/source/regular/car-door-1'
import { ciSteeringWheel } from '~/icons/source/light/steering-wheel'
import { ciTwoCogwheels } from '~/icons/source/light/two-cogwheels'
import { ciCarSteeringWheel } from '~/icons/source/light/car-steering-wheel'
import { ciGearstick } from '~/icons/source/light/gearstick'
import { ciCarDoor } from '~/icons/source/light/car-door'
import { ciTechnology } from '~/icons/source/regular/technology'
import { ciRoller } from '~/icons/source/light/roller'
import { ciWindow } from '~/icons/source/light/window'
import { ciDoor } from '~/icons/source/regular/door'
import { ciStudent } from '~/icons/source/light/student'
import { ciDiamond } from '~/icons/source/light/diamond'
import { ciDoorOne } from '~/icons/source/light/door-one'
import { ciWindowAlt } from '~/icons/source/light/window-alt'
import { ciCorner } from '~/icons/source/light/corner'
import { ciKey } from '~/icons/source/light/key'
import { ciRoof } from '~/icons/source/light/roof'
import { ciAntiqueBalcony } from '~/icons/source/light/antique-balcony'
import { ciBreakfast } from '~/icons/source/light/breakfast'
import { ciConcierge } from '~/icons/source/light/concierge'
import { ciHumidifier } from '~/icons/source/light/humidifier'
import { ciExchange } from '~/icons/source/light/exchange'
import { ciTwoStoreBuilding } from '~/icons/source/light/two-store-building'
import { ciDisabled } from '~/icons/source/light/disabled'
import { ciFireplace } from '~/icons/source/light/fireplace'
import { ciDraws } from '~/icons/source/regular/draws'
import { ciFireworks } from '~/icons/source/light/fireworks'
import { ciFamily } from '~/icons/source/light/family'
import { ciWatering } from '~/icons/source/light/watering'
import { ciWeightlifting } from '~/icons/source/light/weightlifting'
import { ciHairdryer } from '~/icons/source/light/hairdryer'
import { ciCrane } from '~/icons/source/regular/crane'
import { ciStairs } from '~/icons/source/regular/stairs'
import { ciProfits } from '~/icons/source/light/profits'
import { ciIron } from '~/icons/source/light/iron'
import { ciAshtray } from '~/icons/source/light/ashtray'
import { ciBathroom } from '~/icons/source/light/bathroom'
import { ciMuseum } from '~/icons/source/light/museum'
import { ciGarage } from '~/icons/source/light/garage'
import { ciPenthouse } from '~/icons/source/light/penthouse'
import { ciTrack } from '~/icons/source/light/track'
import { ciConsole } from '~/icons/source/light/console'
import { ciRenovation } from '~/icons/source/light/renovation'
import { ciToilet } from '~/icons/source/light/toilet'
import { ciSafeBox } from '~/icons/source/light/safe-box'
import { ciSatelliteTv } from '~/icons/source/light/satellite-tv'
import { ciSolarPanel } from '~/icons/source/light/solar-panel'
import { ciBoxes } from '~/icons/source/light/boxes'
import { ciTractor2 } from '~/icons/source/light/tractor-2'
import { ciSwimmingPool } from '~/icons/source/light/swimming-pool'
import { ciTelevision } from '~/icons/source/light/television'
import { ciUsb } from '~/icons/source/regular/usb'
import { ciWashingMachine } from '~/icons/source/light/washing-machine'
import { ciWifi } from '~/icons/source/light/wifi'
import { ciArea } from '~/icons/source/regular/area'
import { ciAbs } from '~/icons/source/regular/abs'
import { ciAcceptExchange } from '~/icons/source/regular/accept-exchange'
import { ciAc } from '~/icons/source/regular/ac'
import { ciAirSuspension } from '~/icons/source/regular/air-suspension'
import { ciAlarm } from '~/icons/source/regular/alarm'
import { ciAlumiumRims } from '~/icons/source/regular/alumium-rims'
import { ciAntiTheftSystemGps } from '~/icons/source/regular/anti-theft-system-gps'
import { ciAndroidAuto } from '~/icons/source/regular/android-auto'
import { ciAntique } from '~/icons/source/regular/antique'
import { ciAppleCarplay } from '~/icons/source/regular/apple-carplay'
import { ciArmored } from '~/icons/source/regular/armored'
import { ciAutomaticParking } from '~/icons/source/regular/automatic-parking'
import { ciBluetooth } from '~/icons/source/regular/bluetooth'
import { ciBucketSeats } from '~/icons/source/regular/bucket-seats'
import { ciCdPlayer } from '~/icons/source/regular/cd-player'
import { ciCentralLocking } from '~/icons/source/regular/central-locking'
import { ciChequesAccepted } from '~/icons/source/regular/cheques-accepted'
import { ciCollisionAvoidanceSystem } from '~/icons/source/regular/collision-avoidance-system'
import { ciCreditCardAccepted } from '~/icons/source/regular/credit-card-accepted'
import { ciCruiseControl } from '~/icons/source/regular/cruise-control'
import { ciDvd } from '~/icons/source/regular/dvd'
import { ciEcoStartStop } from '~/icons/source/regular/eco-start-stop'
import { ciElectricSunroof } from '~/icons/source/regular/electric-sunroof'
import { ciEsp } from '~/icons/source/regular/esp'
import { ciExchangeBoatAccepted } from '~/icons/source/regular/exchange-boat-accepted'
import { ciExchangeWithBike } from '~/icons/source/regular/exchange-with-bike'
import { ciFogLights } from '~/icons/source/regular/fog-lights'
import { ciFromOrganizationOfPublicMaterialsManagement } from '~/icons/source/regular/from-organization-of-public-materials-management'
import { ciGps } from '~/icons/source/regular/gps'
import { ciGreekDealership } from '~/icons/source/regular/greek-dealership'
import { ciHardtop } from '~/icons/source/regular/hardtop'
import { ciHeatedSeats } from '~/icons/source/regular/heated-seats'
import { ciHillAssist } from '~/icons/source/regular/hill-assist'
import { ciHitch } from '~/icons/source/regular/hitch'
import { ciImmobilizer } from '~/icons/source/regular/immobilizer'
import { ciImported } from '~/icons/source/regular/imported'
import { ciIsofixChildrenSeats } from '~/icons/source/regular/isofix-children-seats'
import { ciKeyless } from '~/icons/source/regular/keyless'
import { ciLeatherSeats } from '~/icons/source/regular/leather-seats'
import { ciLedLights } from '~/icons/source/regular/led-lights'
import { ciMultiPurposeSteeringWheel } from '~/icons/source/regular/multi-purpose-steering-wheel'
import { ciNeverCrashed } from '~/icons/source/regular/never-crashed'
import { ciNonsmoker } from '~/icons/source/regular/nonsmoker'
import { ciNotesAccepted } from '~/icons/source/regular/notes-accepted'
import { ciPanoramicRoof } from '~/icons/source/regular/panoramic-roof'
import { ciParktronic } from '~/icons/source/regular/parktronic'
import { ciPowerMirrors } from '~/icons/source/regular/power-mirrors'
import { ciPowerSeats } from '~/icons/source/regular/power-seats'
import { ciPowerSteering } from '~/icons/source/regular/power-steering'
import { ciPowerWindows } from '~/icons/source/regular/power-windows'
import { ciPriceWithoutVat } from '~/icons/source/regular/price-without-vat'
import { ciRadioPlayer } from '~/icons/source/regular/radio-player'
import { ciRainSensor } from '~/icons/source/regular/rain-sensor'
import { ciRearViewCamera } from '~/icons/source/regular/rear-view-camera'
import { ciRightHandDriveCar } from '~/icons/source/regular/right-hand-drive-car'
import { ciRoofRails } from '~/icons/source/regular/roof-rails'
import { ciSeats7Plus } from '~/icons/source/regular/seats-7-plus'
import { ciServiceBook } from '~/icons/source/regular/service-book'
import { ciSteeringLights } from '~/icons/source/regular/steering-lights'
import { ciTaxfreeForeignNumbers } from '~/icons/source/regular/taxfree-foreign-numbers'
import { ciTcsAsr } from '~/icons/source/regular/tcs-asr'
import { ciTelephone } from '~/icons/source/light/telephone'
import { ciTftScreen } from '~/icons/source/regular/tft-screen'
import { ciTripComputer } from '~/icons/source/regular/trip-computer'
import { ciTurbo } from '~/icons/source/regular/turbo'
import { ciTvCamera } from '~/icons/source/regular/tv-camera'
import { ciWarranty } from '~/icons/source/regular/warranty'
import { ciWheelchair } from '~/icons/source/regular/wheelchair'
import { ciWithdrawalRight } from '~/icons/source/regular/withdrawal-right'
import { ciXenon } from '~/icons/source/regular/xenon'
import { ciCheck } from '~/icons/source/regular/check'
import { ciVehicle } from '~/icons/source/light/vehicle'
import { ciAdaptiveCruiseControl } from '~/icons/source/regular/adaptive-cruise-control'
import { ciHeadUpDisplay } from '~/icons/source/regular/head-up-display'
import { ciLaneAssist } from '~/icons/source/regular/lane-assist'
import { ciWarrantyExtension } from '~/icons/source/regular/warranty-extension'
import { ciAutomaticAirConditioning } from '~/icons/source/regular/automatic-air-conditioning'
import { ciAgricultural } from '~/icons/source/regular/agricultural'
import { ciLockingDifferential } from '~/icons/source/regular/locking-differential'
import { ciSlidingDoor } from '~/icons/source/regular/sliding-door'
import { ciSlidingDoorLeft } from '~/icons/source/regular/sliding-door-left'
import { ciCompass } from '~/icons/source/solid/compass'
export const fallbackIcon: Icon = {
  icon: ciCheck
}

export const extrasIcons: { [name: string]: Icon } = Object.freeze({
  '2_stroke': {
    icon: ciPiston
  },
  '4_stroke_engine': {
    icon: ciPiston
  },
  abs: {
    icon: ciAbs
  },
  acc: {
    icon: ciAdaptiveCruiseControl
  },
  accept_exchange_with_car: {
    icon: ciAcceptExchange
  },
  adr: {
    icon: ciInsurance
  },
  agricultural: {
    icon: ciAgricultural
  },
  air_brake: {
    icon: ciBreakAlt
  },
  'air_conditioning_(a_c)': {
    icon: ciAc
  },
  air_condition: {
    icon: ciAirConditioner
  },
  air_suspension: {
    icon: ciAirSuspension
  },
  airbags: {
    icon: ciAirbag1
  },
  'aircondition(a_c)': {
    icon: ciAc
  },
  alarm: {
    icon: ciAlarm
  },
  alumium_rims: {
    icon: ciAlumiumRims
  },
  android_auto: {
    icon: ciAndroidAuto
  },
  anti_theft_system: {
    icon: ciShield
  },
  anti_theft_system_gps: {
    icon: ciAntiTheftSystemGps
  },
  antique: {
    icon: ciAntique
  },
  apple_carplay: {
    icon: ciAppleCarplay
  },
  armored: {
    icon: ciArmored
  },
  auto_pilot: {
    icon: ciTransport
  },
  automatic: {
    icon: ciGearshift1
  },
  automatic_air_conditioning: {
    icon: ciAutomaticAirConditioning
  },
  automatic_parking: {
    icon: ciAutomaticParking
  },
  backup_engine: {
    icon: ciEngine
  },
  bluetooth: {
    icon: ciBluetooth
  },
  bucket_seats: {
    icon: ciBucketSeats
  },
  bow_platform_definiera: {
    icon: ciSteeringWheel
  },
  bright: {
    icon: ciIdea
  },
  car_exchange: {
    icon: ciAcceptExchange
  },
  cast_wheels: {
    icon: ciRim
  },
  cb_radio: {
    icon: ciRadio
  },
  cd_player: {
    icon: ciCdPlayer
  },
  central_locking: {
    icon: ciCentralLocking
  },
  cheques_accepted: {
    icon: ciChequesAccepted
  },
  collision_avoidance_system: {
    icon: ciCollisionAvoidanceSystem
  },
  compass: {
    icon: ciCompass
  },
  credit_card_accepted: {
    icon: ciCreditCardAccepted
  },
  cruise_control: {
    icon: ciCruiseControl
  },
  disc_brakes: {
    icon: ciDiscBrake1
  },
  double_tires: {
    icon: ciTire
  },
  dvd: {
    icon: ciDvd
  },
  dynamo_lights: {
    icon: ciLightBulb1
  },
  ebs: {
    icon: ciBreak
  },
  eco_start_stop: {
    icon: ciEcoStartStop
  },
  electric_starter: {
    icon: ciPowerButton
  },
  electric_sunroof: {
    icon: ciElectricSunroof
  },
  esp: {
    icon: ciEsp
  },
  elevator: {
    icon: ciElevator
  },
  exchange: {
    icon: ciAcceptExchange
  },
  exchange_accepted: {
    icon: ciAcceptExchange
  },
  exchange_boat_accepted: {
    icon: ciExchangeBoatAccepted
  },
  exchange_with_bike: {
    icon: ciExchangeWithBike
  },
  exchange_with_boat: {
    icon: ciExchangeBoatAccepted
  },
  exchange_with_car: {
    icon: ciExchangeBoatAccepted
  },
  exchange_with_moto_accepted: {
    icon: ciExchangeBoatAccepted
  },
  exhibition: {
    icon: ciQuality
  },
  fog_lights: {
    icon: ciFogLights
  },
  from_organization_of_public_materials_management: {
    icon: ciFromOrganizationOfPublicMaterialsManagement
  },
  four_stroke_engine: {
    icon: ciPiston
  },
  furnished: {
    icon: ciSofa
  },
  generators: {
    icon: ciPower
  },
  gps: {
    icon: ciGps
  },
  greek_dealership: {
    icon: ciGreekDealership
  },
  hardtop: {
    icon: ciHardtop
  },
  headlights: {
    icon: ciLightBulb1
  },
  head_up_display: {
    icon: ciHeadUpDisplay
  },
  heater: {
    icon: ciAirConditionerAlt
  },
  heated_seats: {
    icon: ciHeatedSeats
  },
  hill_assist: {
    icon: ciHillAssist
  },
  hitch: {
    icon: ciHitch
  },
  hydraulic_door: {
    icon: ciCarDoor1
  },
  idravliko_timoni: {
    icon: ciSteeringWheel
  },
  immobilizer: {
    icon: ciImmobilizer
  },
  imported: {
    icon: ciImported
  },
  isofix_children_seats: {
    icon: ciIsofixChildrenSeats
  },
  intarder: {
    icon: ciTwoCogwheels
  },
  keyless: {
    icon: ciKeyless
  },
  lane_assist: {
    icon: ciLaneAssist
  },
  leather_seats: {
    icon: ciLeatherSeats
  },
  led_lights: {
    icon: ciLedLights
  },
  locking_differential: {
    icon: ciLockingDifferential
  },
  multi_purpose_steering_wheel: {
    icon: ciMultiPurposeSteeringWheel
  },
  narrow_tires: {
    icon: ciTire
  },
  never_crashed: {
    icon: ciNeverCrashed
  },
  nonsmoker: {
    icon: ciNonsmoker
  },
  notes_accepted: {
    icon: ciNotesAccepted
  },
  new_tires: {
    icon: ciTire
  },
  panoramic_roof: {
    icon: ciPanoramicRoof
  },
  parktronic: {
    icon: ciParktronic
  },
  power_locks: {
    icon: ciPowerButton
  },
  power_mirrors: {
    icon: ciPowerMirrors
  },
  power_seats: {
    icon: ciPowerSeats
  },
  power_steering: {
    icon: ciPowerSteering
  },
  power_windows: {
    icon: ciPowerWindows
  },
  price_without_vat: {
    icon: ciPriceWithoutVat
  },
  radio_player: {
    icon: ciRadioPlayer
  },
  rain_sensor: {
    icon: ciRainSensor
  },
  rear_view_camera: {
    icon: ciRearViewCamera
  },
  right_hand_drive_car: {
    icon: ciRightHandDriveCar
  },
  roof_rails: {
    icon: ciRoofRails
  },
  'seats_7+': {
    icon: ciSeats7Plus
  },
  semiautomatic: {
    icon: ciGearstick
  },
  service_book: {
    icon: ciServiceBook
  },
  shock_absorber: {
    icon: ciDamper
  },
  side_door: {
    icon: ciCarDoor
  },
  sliding_doors_x2: {
    icon: ciSlidingDoor
  },
  right_sliding_door: {
    icon: ciSlidingDoor
  },
  left_sliding_door: {
    icon: ciSlidingDoorLeft
  },
  spoke_wheels: {
    icon: ciRim
  },
  springs: {
    icon: ciVehicle
  },
  steering_lights: {
    icon: ciSteeringLights
  },
  taxfree_foreign_numbers: {
    icon: ciTaxfreeForeignNumbers
  },
  tape_player: {
    icon: ciTechnology
  },
  tcs_asr: {
    icon: ciTcsAsr
  },
  telephone: {
    icon: ciTelephone
  },
  tft_screen: {
    icon: ciTftScreen
  },
  trip_computer: {
    icon: ciTripComputer
  },
  turbo: {
    icon: ciTurbo
  },
  tv_camera: {
    icon: ciTvCamera
  },
  two_stroke: {
    icon: ciPiston
  },
  warranty: {
    icon: ciWarranty
  },
  warranty_extension: {
    icon: ciWarrantyExtension
  },
  wheelhouse_console: {
    icon: ciCarSteeringWheel
  },
  wheelchair: {
    icon: ciWheelchair
  },
  withdrawal_right: {
    icon: ciWithdrawalRight
  },
  'with 1 engine': {
    icon: ciEngine
  },
  'with 2 engines': {
    icon: ciEngine
  },
  xenon: {
    icon: ciXenon
  },
  painted: {
    icon: ciRoller
  },
  window_screens: {
    icon: ciWindow
  },
  double_glass: {
    icon: ciDoor
  },
  for_students: {
    icon: ciStudent
  },
  luxury: {
    icon: ciDiamond
  },
  secure_door: {
    icon: ciDoorOne
  },
  view: {
    icon: ciWindowAlt
  },
  angular: {
    icon: ciCorner
  },
  arrival_without_host_presence: {
    icon: ciKey
  },
  attic: {
    icon: ciRoof
  },
  balcony: {
    icon: ciAntiqueBalcony
  },
  breakfast: {
    icon: ciBreakfast
  },
  concierge: {
    icon: ciConcierge
  },
  corner: {
    icon: ciCorner
  },
  dryer: {
    icon: ciHumidifier
  },
  exchangeable: {
    icon: ciExchange
  },
  facade: {
    icon: ciTwoStoreBuilding
  },
  facilities_for_disabled_people: {
    icon: ciDisabled
  },
  fireplace: {
    icon: ciFireplace
  },
  floor_heating: {
    icon: ciDraws
  },
  for_events: {
    icon: ciFireworks
  },
  'for_families_/_children': {
    icon: ciFamily
  },
  freight_elevator: {
    icon: ciElevator
  },
  garden: {
    icon: ciWatering
  },
  gym: {
    icon: ciWeightlifting
  },
  hairdryer: {
    icon: ciHairdryer
  },
  incomplete: {
    icon: ciCrane
  },
  internal_staircase: {
    icon: ciStairs
  },
  investment: {
    icon: ciProfits
  },
  iron: {
    icon: ciIron
  },
  is_smoking_allowed: {
    icon: ciAshtray
  },
  jacuzzi: {
    icon: ciBathroom
  },
  neoclassical: {
    icon: ciMuseum
  },
  parking: {
    icon: ciGarage
  },
  penthouse: {
    icon: ciPenthouse
  },
  pets_allowed: {
    icon: ciTrack
  },
  playroom: {
    icon: ciConsole
  },
  private_wc: {
    icon: ciToilet
  },
  renovated: {
    icon: ciRenovation
  },
  safe: {
    icon: ciSafeBox
  },
  satellite_receiver: {
    icon: ciSatelliteTv
  },
  shared_wc: {
    icon: ciToilet
  },
  solar_water_heating: {
    icon: ciSolarPanel
  },
  storage_space: {
    icon: ciBoxes
  },
  suitable_for_agricultural_use: {
    icon: ciTractor2
  },
  swimming_pool: {
    icon: ciSwimmingPool
  },
  tv: {
    icon: ciTelevision
  },
  usb: {
    icon: ciUsb
  },
  washing_machine: {
    icon: ciWashingMachine
  },
  wifi: {
    icon: ciWifi
  },
  within_urban_plan: {
    icon: ciArea
  },
  without_urban_plan: {
    icon: ciArea
  }
})

import { CIcon } from '~/icons/types'

export const ciMinus: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M3.5 12A1.5 1.5 0 0 1 5 10.5h14a1.5 1.5 0 0 1 0 3H5A1.5 1.5 0 0 1 3.5 12Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'minus',
  type: 'solid'
}

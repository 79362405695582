var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-select-none"},[_c('div',{staticClass:"tw-form-input tw-border tw-border-solid tw-py-0 tw-flex tw-items-center tw-relative tw-w-full tw-text-left focus:tw-ring-blue-500 focus:tw-border-blue-500 hover:tw-border-blue-500 tw-border-grey-300 tw-border-solid",class:_vm.computedClasses,attrs:{"role":"button","title":_vm.placeholder,"aria-disabled":_vm.disabled},on:{"click":_vm.onClick}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full"},[_c('div',{staticClass:"tw-w-full tw-min-w-0"},[_c('div',{staticClass:"tw-flex tw-items-center tw-mr-1.5 tw-w-full tw-h-full"},[(_vm.selectedOptionSet.length > 0)?_c('div',{staticClass:"tw-whitespace-nowrap tw-text-grey-900 tw-overflow-hidden tw-overflow-ellipsis tw-max-w-full"},[_c('div',{staticClass:"tw-relative tw-w-full tw-flex tw-items-center tw-gap-2"},[_c('div',{ref:"optionsContainerRef",staticClass:"tw-flex tw-flex-wrap tw-overflow-hidden tw-items-center",class:[
                  { 'tw-max-h-5 tw-gap-2': _vm.size === 'sm' },
                  { 'tw-max-h-6 tw-gap-2': _vm.size === 'md' },
                  { 'tw-max-h-8 tw-gap-3': _vm.size === 'lg' }
                ]},_vm._l((_vm.selectedOptionSet),function(option){return _c('div',{key:option.value,ref:"optionsRef",refInFor:true,staticClass:"tw-flex tw-items-center tw-bg-blue-100 tw-text-blue-500 tw-font-medium tw-gap-2",class:[
                    {
                      'tw-text-sm tw-px-1 tw-py-0.5 tw-gap-1 tw-rounded':
                        _vm.size === 'sm'
                    },
                    {
                      'tw-text-base tw-px-1 tw-py-0.5 tw-gap-1 tw-rounded':
                        _vm.size === 'md'
                    },
                    {
                      'tw-text-lg tw-px-2 tw-py-1 tw-gap-3 tw-rounded-md':
                        _vm.size === 'lg'
                    }
                  ]},[_c('span',[_vm._v("\n                    "+_vm._s(option[_vm.textField])+"\n                  ")])])}),0),_vm._v(" "),(_vm.overflownOptionsCount > 0)?_c('div',{staticClass:"tw-bg-blue-500 tw-text-white",class:[
                  [
                    {
                      'tw-text-sm tw-px-1 tw-py-0.5 tw-rounded': _vm.size === 'sm'
                    },
                    {
                      'tw-text-base tw-px-1 tw-py-0.5 tw-rounded':
                        _vm.size === 'md'
                    },
                    {
                      'tw-text-lg tw-px-2 tw-py-1 tw-rounded-md':
                        _vm.size === 'lg'
                    }
                  ]
                ]},[_vm._v("\n                +"+_vm._s(_vm.overflownOptionsCount)+"\n              ")]):_vm._e()])]):_c('div',{staticClass:"tw-whitespace-nowrap tw-text-grey-600 tw-overflow-hidden tw-overflow-ellipsis tw-max-w-full"},[_vm._v("\n            "+_vm._s(_vm.placeholder)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-ml-1"},[_c('CIcon',{staticClass:"tw-transition-transform tw-duration-200 tw-ease-in",class:[
            _vm.disabled ? 'tw-text-grey-600' : 'tw-text-grey-700',
            { 'tw-rotate-180': _vm.internalDropdownOpen }
          ],attrs:{"icon":_vm.ciAngleDown,"size":_vm.size === 'lg' ? 20 : 16}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
import { Category } from '~/models/search/types'

export enum SuggestionType {
  FILTER = 'filter',
  AUTOCOMPLETE = 'autocomplete',
  LEGACY = 'legacy',
  CLASSIFIED = 'classified',
  MORE = 'more'
}

export interface SearchbarSuggestionData {
  url?: string
  suggester?: boolean
  category?: string
  query?: string
  phrase?: string
  path?: string[]
}

export interface SearchbarSuggestion {
  data: SearchbarSuggestionData
  displayPhrase?: string
  tags?: string[]
  id?: number
  uqtId?: number | string
  raw?: boolean
  fromHistory?: boolean
  lastSearch?: string
}

export interface SearchbarLegacySuggestion {
  category: Category[]
  categoryHumanPathParts: any[]
  count: number
  isFuzzy: boolean
  isUNderSameRoot: boolean
  name: string
  popularity: number
  seoUrl: string
  tags?: string[]
  value: LegacySuggestionValue
}

export interface LegacySuggestionValue {
  category: string | number
  q: string
}

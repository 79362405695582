import { CIcon } from '~/icons/types'
export const ciChevronCircleLeft: CIcon = {
  width: 532,
  height: 512,
  paths: [
    {
      d:
        'M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256zm107.5-8.5 122.8-122.8c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17L234.2 256l91.7 91.7c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L163.5 264.5c-4.7-4.7-4.7-12.3 0-17z',
      fill: 'currentColor'
    }
  ],
  name: 'chevron-circle-left',
  type: 'regular'
}

import { CIcon } from '~/icons/types'

export const ciVolumeOff: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M11.43 4.1a1 1 0 0 0-1 .12L5.65 8H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h3.65l4.73 3.78A1 1 0 0 0 11 20a.91.91 0 0 0 .43-.1A1 1 0 0 0 12 19V5a1 1 0 0 0-.57-.9Zm8.98 7.9 2.3-2.29a1.004 1.004 0 1 0-1.42-1.42L19 10.59l-2.29-2.3a1.004 1.004 0 1 0-1.42 1.42l2.3 2.29-2.3 2.29a1 1 0 0 0 0 1.42 1.001 1.001 0 0 0 1.42 0l2.29-2.3 2.29 2.3a1.001 1.001 0 0 0 1.639-.325 1 1 0 0 0-.22-1.095L20.41 12Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'volume-off',
  type: 'solid'
}

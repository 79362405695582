



















import { FeedTypes } from '~/models/user/types'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { defineComponent } from '~/utils/nuxt3-migration'
import { ciMagnifyingGlass } from '~/icons/source/solid/magnifying-glass'
import { ciClock } from '~/icons/source/solid/clock'
import { ciPlus } from '~/icons/source/solid/plus'
import { ciYoutube } from '~/icons/source/solid/youtube'
import { ciParkingP } from '~/icons/source/solid/parking-p'

export default defineComponent({
  props: {
    feedType: {
      type: String,
      required: false,
      default: null
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    icons() {
      return {
        plusSquare: ciPlus,
        parking: ciParkingP,
        search: ciMagnifyingGlass,
        clock: ciClock,
        youtube: ciYoutube
      }
    },
    isUserClassifieds() {
      return this.feedType === 'user_classifieds'
    },
    isForParking() {
      return this.feedType === 'favorite_classifieds'
    },
    isYoutubeFeed() {
      return this.feedType === FeedTypes.YOUTUBE
    },
    isRecent() {
      return (
        this.feedType === 'recent_user_search' ||
        this.feedType === 'recent_viewed_classifieds'
      )
    },
    isNormalSearch() {
      return (
        (!this.isUserClassifieds && !this.isForParking && !this.isRecent) ||
        this.feedType === null
      )
    },
    labelToShow() {
      if (this.isUserClassifieds) {
        return this.$t('new classified')
      }
      if (this.isForParking) {
        return 'Parking'
      }
      return this.$t('more')
    },
    iconToShow() {
      if (this.isYoutubeFeed) {
        return this.icons.youtube
      }
      if (this.isUserClassifieds) {
        return this.icons.plusSquare
      }
      if (this.isForParking) {
        return this.icons.parking
      }
      if (this.isRecent) {
        return this.icons.clock
      }
      return this.icons.search
    },
    computedUrl() {
      if (this.isUserClassifieds) {
        return this.$dep(LegacyUrlService).getNewClassifiedUrl()
      }
      return this.url
    }
  },
  methods: {
    hrefClick(e) {
      if (!this.isUserClassifieds) {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('clicked', e)
      }
    }
  }
})










import { defineComponent } from '~/utils/nuxt3-migration'
import { capitalizeAllWords } from '~/utils/string'

export default defineComponent({
  setup() {
    return {
      capitalizeAllWords
    }
  }
})
















import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { useDep } from '~/compositions/dependency-container'
import AdvertiseService from '~/services/ads/AdvertiseService'
import OfferAmountInput from '~/components/shared/classified/offers/OfferAmountInput.vue'
import ReceiptOrInvoiceModal from '~/components/shared/account/payments/subscriptions/ReceiptOrInvoiceModal.vue'
import CBalanceTopUpTrigger from '~/components/shared/configurable/CBalanceTopUpTrigger.vue'
import { ciWallet } from '~/icons/source/solid/wallet'
import { ButtonSize } from '~/models/app/button'

export default defineComponent({
  components: { CBalanceTopUpTrigger, ReceiptOrInvoiceModal, OfferAmountInput },
  props: {
    size: {
      type: String as PropType<ButtonSize>,
      default: 'md',
      required: false
    },
    clickCallback: {
      type: Function,
      required: false,
      default: null
    }
  },
  setup() {
    const advertisingService = useDep(AdvertiseService)

    const topUpFunction = async (amount, requestInvoice) => {
      return await advertisingService.topUpBalance(amount, requestInvoice)
    }

    return {
      ciWallet,
      advertisingService,
      topUpFunction
    }
  }
})

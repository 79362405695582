





































































import {
  computed,
  defineComponent,
  nextTick,
  ref,
  toRefs,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'
import { debounce } from '~/utils/function'
import { DEBOUNCE_DEFAULT } from '~/constants/debounce'
import { ciPlus } from '~/icons/source/solid/plus'
import { ciMinus } from '~/icons/source/solid/minus'

export default defineComponent({
  model: vue3Model,
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    startingPrice: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    currencySymbol: {
      type: String,
      required: false,
      default: '€'
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    minPercentage: {
      type: Number,
      required: false,
      default: 1
    },
    orangeColor: {
      type: Boolean,
      required: false,
      default: false
    },
    showAsterisk: {
      type: Boolean,
      required: false,
      default: false
    },
    min: {
      type: Number,
      required: false,
      default: undefined
    },
    maxLimit: {
      type: Number,
      required: false,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const { min, startingPrice, orangeColor, maxLimit } = toRefs(props)
    const internalValue = ref(Math.floor(props.modelValue))
    const inputRef = ref(null)

    const minLimit = computed(() => {
      if (min.value) {
        return min.value
      }
      return Math.floor(startingPrice.value - startingPrice.value * 0.5)
    })

    const stepAmount = computed(() => {
      if (startingPrice.value === 0) {
        return 5 // euros
      }
      return Math.ceil(startingPrice.value * 0.02) // 2%
    })

    const inputWidth = computed(() => {
      const letterWidth = 17
      if (
        internalValue.value.toString().length <
        Math.floor(startingPrice.value).toString().length
      ) {
        return `${Math.floor(startingPrice.value).toString().length *
          letterWidth}px`
      }
      return `${internalValue.value.toString().length * letterWidth}px`
    })

    const btnClasses =
      'tw-text-[2rem]  tw-flex tw-items-center tw-overflow-hidden tw-justify-center min-[364px]:tw-px-4 max-[363px]:tw-px-2 tw-flex-shrink-0 tw-group tw-text-grey-300'
    const btnCircleClasses =
      'tw-rounded-full tw-text-grey-300 tw-border-grey-300 tw-text-xl tw-p-1 tw-w-[2.2rem] tw-h-[2.2rem] tw-border-solid tw-border  tw-border-[3px] tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center'

    const btnCircleHoverClasses = orangeColor.value
      ? 'group-hover:tw-border-amber-700 group-hover:tw-text-amber-700'
      : 'group-hover:tw-border-blue-400 group-hover:tw-text-blue-400'

    watch(
      () => props.modelValue,
      (newValue: number) => {
        internalValue.value = Math.floor(newValue)
      }
    )

    const extractNumber = event => {
      const text = (event.target as HTMLInputElement).value
      if (text.trim()) {
        return parseInt(text.trim())
      }

      internalValue.value = 0
      return 0
    }

    const onInput = (event: InputEvent) => {
      emit('update:modelValue', extractNumber(event))
    }

    const increase = async (e: Event) => {
      e.stopPropagation()
      e.preventDefault()
      emit('update:modelValue', internalValue.value + stepAmount.value)
      await nextTick()
      priceCheck()
    }

    const decrease = async (e: Event) => {
      e.stopPropagation()
      e.preventDefault()
      emit('update:modelValue', internalValue.value - stepAmount.value)
      await nextTick()
      priceCheck()
    }

    const priceCheck = async () => {
      await nextTick()
      if (internalValue.value !== 0 && internalValue.value <= minLimit.value) {
        internalValue.value = minLimit.value
        emit('update:modelValue', internalValue.value)
      } else if (maxLimit.value && internalValue.value >= maxLimit.value) {
        internalValue.value = maxLimit.value
        emit('update:modelValue', maxLimit.value)
      } else {
        emit('update:modelValue', Math.round(internalValue.value))
      }
    }

    const debouncedPriceCheck = debounce(priceCheck, DEBOUNCE_DEFAULT)

    const focusInput = () => {
      inputRef.value.focus()
    }

    return {
      internalValue,
      onInput,
      ciPlus,
      ciMinus,
      increase,
      decrease,
      focusInput,
      inputRef,
      minLimit,
      btnClasses,
      btnCircleClasses,
      btnCircleHoverClasses,
      priceCheck,
      debouncedPriceCheck,
      stepAmount,
      inputWidth
    }
  }
})

import { CIcon } from '~/icons/types'

export const ciCalendar: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M18.7 3.8h-2V3c0-.4-.3-.8-.8-.8s-.7.4-.7.8v.8H8.8V3c0-.4-.3-.8-.8-.8s-.7.4-.7.8v.8h-2C3.8 3.8 2.5 5 2.5 6.6V19c0 1.5 1.2 2.8 2.8 2.8h13.5c1.5 0 2.8-1.2 2.8-2.8V6.6c-.1-1.5-1.3-2.8-2.9-2.8Zm0 16.4H5.3c-.7 0-1.3-.5-1.3-1.2v-8.9h16V19c0 .7-.6 1.2-1.3 1.2Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'calendar',
  type: 'solid'
}





















































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  useRoute,
  useRouter
} from '~/utils/nuxt3-migration'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import OfferAmountInput from '~/components/shared/classified/offers/OfferAmountInput.vue'
import ReceiptOrInvoiceModal from '~/components/shared/account/payments/subscriptions/ReceiptOrInvoiceModal.vue'
import { StripeSession } from '~/models/payments/stripe'
import { ButtonSize, ButtonVariant } from '~/models/app/button'
import { defineComponentTranslations } from '~/utils/i18n'
import { SellerOnboardingStatus } from '~/models/marketplace/seller'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'

const DEFAULT_AMOUNT = 10

export default defineComponent({
  components: { ReceiptOrInvoiceModal, OfferAmountInput },
  props: {
    size: {
      type: String as PropType<ButtonSize>,
      default: 'md',
      required: false
    },
    clickCallback: {
      type: Function,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    icon: { type: Object, default: () => null },
    topUpFunction: {
      type: Function as PropType<
        (amount: number, requestInvoice: boolean) => Promise<StripeSession>
      >,
      required: true
    },
    variant: {
      type: String as PropType<ButtonVariant>,
      default: 'primary'
    },
    termsLink: {
      type: String,
      required: false,
      default: ''
    },
    termsLabel: {
      type: String,
      default: ''
    },
    linkMode: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    forFeature: {
      type: String as PropType<
        '' | 'marketplace-stock-balance' | 'inhouse-ads'
      >,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { clickCallback, forFeature } = toRefs(props)
    const amount = ref(DEFAULT_AMOUNT)
    const issueInvoice = ref(false)
    const loading = ref(false)
    const showTopUpModal = ref(false)
    const error = ref(null)
    const showReceiptModal = ref(false)

    const snackbar = useSnackbar()
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()

    const { state: userState } = useNamespacedStore<UserState>(USER_NS)
    const onboardingStatus = computed<SellerOnboardingStatus>(
      () =>
        userState.marketplace?.seller
          ?.onboardingStatus as SellerOnboardingStatus
    )

    onMounted(() => {
      if (route.value.query?.triggerTopUp) {
        showTopUpModal.value = true
        router.replace({
          query: { ...route.value.query, triggerTopUp: undefined }
        })
      }
      if (route.value.query?.success) {
        snackbar.success(t('ads_top_up_success'))
        emit('success')
        router.replace({ query: { success: undefined } })
      }
    })

    const onSubmit = async (invoice: boolean) => {
      issueInvoice.value = invoice
      try {
        loading.value = true
        error.value = null
        const stripeSession = await props.topUpFunction(
          Number(amount.value),
          issueInvoice.value
        )
        window.location = stripeSession.url
      } catch (error) {
        if (error.response?.data?.data?.errors?.name) {
          error.value = error.response?.data?.data?.errors?.name
        } else {
          snackbar.error(
            error.response?.data?.error || t('something went wrong')
          )
        }
      } finally {
        showReceiptModal.value = false
        loading.value = false
      }
    }

    const showDupicateAlert = computed(() => {
      return (
        forFeature.value === 'marketplace-stock-balance' &&
        onboardingStatus.value.balancePurchase === false
      )
    })

    const onAddClick = async () => {
      if (clickCallback.value) {
        try {
          await clickCallback.value()
        } catch (_e) {
          return
        }
      }
      showTopUpModal.value = true
      emit('add-clicked')
    }

    const resetValues = () => {
      if (!showReceiptModal.value) {
        amount.value = DEFAULT_AMOUNT
        issueInvoice.value = false
      }
    }

    const goNext = () => {
      showReceiptModal.value = true
      showTopUpModal.value = false
    }

    const onReceiptModalHide = () => {
      if (showReceiptModal.value) {
        showReceiptModal.value = false
        amount.value = DEFAULT_AMOUNT // default
      }
    }
    const onReceiptBack = () => {
      showReceiptModal.value = false
    }

    const inputDisabled = computed(() => {
      return loading.value || amount.value < DEFAULT_AMOUNT
    })
    return {
      onSubmit,
      loading,
      amount,
      showTopUpModal,
      error,
      issueInvoice,
      onAddClick,
      resetValues,
      showReceiptModal,
      goNext,
      onReceiptModalHide,
      onReceiptBack,
      inputDisabled,
      t,
      showDupicateAlert
    }
  },
  i18n: defineComponentTranslations({
    gift: {
      en: 'GIFT',
      el: 'ΔΩΡΟ'
    },
    we_double_the_amount: {
      en: 'We double the amount of your first deposit.',
      el: 'Διπλασιάζουμε το ποσό της πρώτης σου κατάθεσης.'
    },
    you_pay_you_get: {
      en: 'You pay {initial} you get {double}!',
      el: 'Πληρώνεις {initial} και παίρνεις {double}!'
    }
  })
})

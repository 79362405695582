































import { computed, defineComponent, toRefs } from '~/utils/nuxt3-migration'
import { InHouseSizes } from '~/constants/ads/inhouse'

export default defineComponent({
  props: {
    unitId: {
      type: String,
      required: true
    },
    hideLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    showPixels: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const { unitId } = toRefs(props)

    const previewSizes = computed(() => {
      return InHouseSizes[unitId.value]?.sizes || []
    })
    return {
      previewSizes
    }
  }
})

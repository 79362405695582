









import { computed, defineComponent, toRefs } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    input: {
      type: [String, Number],
      default: ''
    },
    maxCharacters: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { input } = toRefs(props)
    const inputLength = computed(() => {
      return input.value?.length || 0
    })

    return { inputLength }
  }
})

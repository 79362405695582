
































import { computed, defineComponent, ref, watch } from '~/utils/nuxt3-migration'
import CDatePicker from '~/components/shared/configurable/date/CDatePicker.vue'
import { useAdForm } from '~/compositions/advertise/ad-form'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import CTimeInput from '~/components/shared/configurable/datetime/CTimeInput.vue'
import CRadioListEntry from '~/components/shared/configurable/form/radio/CRadioListEntry.vue'
import { useI18n } from '~/compositions/i18n'

export default defineComponent({
  components: {
    CRadioListEntry,
    CTimeInput,
    CFormError,
    CDatePicker
  },
  setup() {
    const {
      getSchemaField,
      emitParams,
      loading,
      errors,
      editMode,
      adValues
    } = useAdForm()

    const getDeliveryStrategy = () => {
      return editMode.value ? adValues.value.deliveryStrategy : 'evenly'
    }
    const delivery = ref(getDeliveryStrategy())

    watch(
      adValues,
      val => {
        if (
          process.client &&
          val.budgetType === 'daily' &&
          !loading.value &&
          delivery.value !== 'evenly'
        ) {
          emitParams('deliveryStrategy', 'evenly')
          delivery.value = 'evenly'
        }
      },
      { deep: !editMode.value }
    )

    const { t } = useI18n()
    const deliveryOptions = computed(() => {
      return (
        getSchemaField('delivery_strategy')?.options.map(o => {
          return {
            ...o,
            disabled:
              adValues.value.budgetType === 'daily' && o.value !== 'evenly'
          }
        }) || []
      )
    })

    // emit the default params for starters
    emitParams('deliveryStrategy', delivery.value)

    watch(adValues, (val, oldVal) => {
      if (val.deliveryStrategy !== oldVal.deliveryStrategy) {
        delivery.value = getDeliveryStrategy()
      }
    })

    const getSubtitleForOption = val => {
      switch (val) {
        case 'evenly':
          return t(
            'the platform evenly distributes the ad in the time period of the campaign you have set so that the budget is spent proportionally'
          )
        case 'fast':
          return t(
            'the platform tries to display your ad in the shortest possible time and thus your budget may be consumed earlier than the time you have chosen'
          )
        case 'frontloaded':
          return t(
            'the platform tries to show your ad more often at the beginning, slowly reducing the frequency so that your budget is consumed approximately within the intended campaign time'
          )
        default:
          return ''
      }
    }

    return {
      delivery,
      getSchemaField,
      loading,
      errors,
      emitParams,
      deliveryOptions,
      getSubtitleForOption
    }
  }
})












import { computed, defineComponent, ref, toRefs } from '~/utils/nuxt3-migration'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import { facetProps } from '~/constants/quick-search'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import { useNamespacedStore } from '~/compositions/store'
import { useQuickSearchParams } from '~/compositions/quick-search/useQuickSearchParams'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'
import CLink from '~/components/shared/configurable/link/CLink.vue'

export default defineComponent({
  props: { ...facetProps },
  components: {
    CCustomSelect,
    CLink
  },
  setup(props) {
    const { searchables, urlArg } = toRefs(props)

    const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
      USER_AGENT_NS
    )
    const { changeParams } = useQuickSearchParams()

    const selectedValue = ref()

    const options = computed(() =>
      searchables.value.map(mapBackendSearchableToOption)
    )

    const isMobile = computed(() => userAgentGetters('isMobile'))

    function onChange(value: any) {
      selectedValue.value = value
      changeParams([
        { name: urlArg.value, value },
        // Also clear the category when changing offer types
        { name: 'category', value: null }
      ])
    }

    return {
      selectedValue,
      options,
      onChange,
      isMobile
    }
  }
})

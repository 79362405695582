import { DESKTOP_AD_UNITS, MOBILE_AD_UNITS } from '~/constants/ads/inhouse'

export const getUnitIconType = (unit: string) => {
  if (DESKTOP_AD_UNITS.includes(unit)) {
    return 'desktop'
  } else if (MOBILE_AD_UNITS.includes(unit)) {
    return 'mobile'
  }

  return undefined
}

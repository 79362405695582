


































































import {
  defineComponent,
  ref,
  toRefs,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'
import CCharacterCounter from '~/components/shared/configurable/form/input/CCharacterCounter.vue'

export default defineComponent({
  model: vue3Model,
  components: { CCharacterCounter },
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: false,
      default: () => null
    },
    placeholder: {
      type: String,
      default: null
    },
    min: {
      type: [Number, String],
      required: false
    },
    max: {
      type: [Number, String],
      required: false
    },
    type: {
      type: String,
      default: 'input'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    maxCharacters: {
      type: Number,
      required: false,
      default: undefined
    },
    appendText: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { options, type } = toRefs(props)
    const focused = ref(false)
    const customMode = ref(
      Boolean(
        !options.value.find(
          o => props.modelValue?.toString() === o.value.toString()
        )
      )
    )

    const internalCustomValue = ref(customMode.value ? props.modelValue : '')
    const selectedOption = ref(customMode.value ? null : props.modelValue)

    const onOptionClick = (val: string | number) => {
      if (internalCustomValue.value) {
        internalCustomValue.value = ''
      }

      customMode.value = false
      selectedOption.value = val
      emit('update:modelValue', type.value === 'number' ? Number(val) : val)
    }

    watch(
      () => props.modelValue,
      val => {
        const foundOption = options.value.find(
          o => o.value.toString() === val.toString()
        )
        if (foundOption && !focused.value) {
          onOptionClick(foundOption.value)
        } else {
          customMode.value = true
          internalCustomValue.value = val
        }
      }
    )

    const onFocus = () => {
      focused.value = true
      if (!customMode.value) {
        customMode.value = true
        internalCustomValue.value = selectedOption.value
        emit('update:modelValue', selectedOption.value)
      }
    }

    const onBlur = () => {
      focused.value = false
      if (!internalCustomValue.value && selectedOption.value) {
        customMode.value = false
        emit(
          'update:modelValue',
          type.value === 'number'
            ? Number(selectedOption.value)
            : selectedOption.value
        )
      } else if (internalCustomValue.value) {
        // find if the value is in options and select that option instead
        const foundOption = options.value.find(
          o => o.value.toString() === internalCustomValue.value.toString()
        )
        if (foundOption) {
          onOptionClick(foundOption.value)
        }
      }
    }

    return {
      internalCustomValue,
      customMode,
      onOptionClick,
      selectedOption,
      onFocus,
      onBlur,
      focused
    }
  }
})

import { useNamespacedStore } from '~/compositions/store'
import {
  APP_CONFIRMATION_MODAL_NS,
  AppConfirmationModalState,
  ConfirmationModalOptions
} from '~/store/modules/shared/app-confirmation-modal/state'
import { SHOW_CONFIRMATION_MODAL } from '~/store/modules/shared/app-confirmation-modal/mutation-types'

export function useAppConfirmationModal() {
  const { commit } = useNamespacedStore<AppConfirmationModalState>(
    APP_CONFIRMATION_MODAL_NS
  )

  function show(options: ConfirmationModalOptions) {
    commit(SHOW_CONFIRMATION_MODAL, options)
  }

  return {
    show
  }
}

import { computed, ref, Ref } from '~/utils/nuxt3-migration'
import { readFromBinaryFile } from 'exif-js'

import {
  base64ToFile,
  extractOrientationValue,
  resizeImage
} from '~/compositions/files/utils'
import { ResizedPhoto } from '~/models/photos/types'
import { useI18n } from '~/compositions/i18n'
import { useLogger } from '~/compositions/logger'
import { useAppConfirmationModal } from '~/compositions/app-confirmation-modal'
import { ConfirmationModalOptions } from '~/store/modules/shared/app-confirmation-modal/state'

export function useFile() {
  const { t } = useI18n()
  const logger = useLogger()
  const confirm = useAppConfirmationModal()

  let heicTo: any = null
  const imageOrientationSupported = computed(() => {
    return document.createElement('img').style.imageOrientation !== undefined
  })
  function fileToBase64(file: File): Promise<string> {
    return new Promise(resolve => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = (e: any) => {
        resolve(e.target?.result)
      }
    })
  }
  function readImageOrientation(file: File): Promise<any> {
    return new Promise(resolve => {
      if (imageOrientationSupported) {
        resolve({ orientation: -1 })
      }

      const orientationReader = new FileReader()
      orientationReader.onload = (e: any) => {
        try {
          const make = readFromBinaryFile(e.target?.result)?.Make
          resolve({
            orientation: extractOrientationValue(e.target?.result),
            make
          })
        } catch (e) {
          resolve({ orientation: -1 })
        }
      }
      orientationReader.readAsArrayBuffer(file)
    })
  }

  async function loadHeicTo() {
    try {
      heicTo = (await import('heic-to')).heicTo
    } catch (error) {
      logger.captureError(error)
    }
  }

  async function heicToJpeg(file: File): Promise<File> {
    try {
      if (!heicTo) {
        await loadHeicTo()
      }

      // get image as blob url
      const blobURL = URL.createObjectURL(file)

      // convert "fetch" the new blob url
      const blobRes = await fetch(blobURL)

      // convert response to blob
      const blob = await blobRes.blob()

      // convert to PNG - response is blob
      const conversionResult = await heicTo({
        blob
      })

      const fileToReturn = conversionResult as File
      // @ts-ignore
      fileToReturn.name = file.name
        .replace('heic', 'jpeg')
        .replace('HEIC', 'jpeg')
        .replace('heif', 'jpeg')
        .replace('HEIF', 'jpeg')

      return fileToReturn
    } catch (error) {
      logger.captureError(error)
      return file
    }
  }

  async function resizeAndReadImage({
    file,
    maxResizeWidth = 2100,
    maxResizeHeight = 1708,
    exactOnly = false, // throws error if image is not === the above values,
    minWidth = 0,
    minHeight = 0,
    loading = ref(false)
  }: {
    file: File
    maxResizeWidth?: number
    maxResizeHeight?: number
    exactOnly?: boolean
    minWidth?: number
    minHeight?: number
    loading?: Ref<boolean>
  }): Promise<ResizedPhoto> {
    let fileToUse = file

    if (
      file.name.toLowerCase().includes('.heic') ||
      file.name.toLowerCase().includes('.heif')
    ) {
      if (heicTo === null) {
        loading.value = true
      }
      fileToUse = await heicToJpeg(file)
      if (heicTo) {
        loading.value = false
      }
    }

    const imageSource = await fileToBase64(fileToUse)
    const { orientation, make } = await readImageOrientation(fileToUse)
    const image = new Image()
    return new Promise((resolve, reject) => {
      image.onload = (event: any) => {
        const eventWidth = event.target?.width
        const eventHeight = event.target?.height
        let width = event.target?.width
        let height = event.target?.height
        if (orientation > 4 && orientation < 9) {
          // intentionally flipping here
          width = eventHeight
          height = eventWidth
        }

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        if (!ctx) {
          reject(new TypeError('canvas not supported'))
          return
        }

        // throw error if image is not exactly at max values
        if (
          exactOnly &&
          width !== maxResizeWidth &&
          height !== maxResizeHeight
        ) {
          const error = t('image_not_exact_dimensions', {
            size: `${maxResizeWidth}x${maxResizeHeight}`
          }).toString()

          // @ts-ignore
          confirm.show({
            variant: 'info',
            title: t('image_dimensions'),
            text: error,
            centered: true,
            size: 'md'
          } as ConfirmationModalOptions)

          throw new TypeError(error)
        }

        // Don't resize if it's small enough
        if (
          width <= maxResizeWidth &&
          height <= maxResizeHeight &&
          width >= minWidth &&
          height >= minHeight &&
          orientation < 0
        ) {
          canvas.width = image.width
          canvas.height = image.height
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
          resolve({
            photo: base64ToFile(canvas.toDataURL('image/jpeg'), fileToUse),
            thumb: canvas.toDataURL('image/jpeg'),
            width,
            height
          })
          return
        }

        // Attempt to resize
        try {
          const {
            file: resizedFile,
            thumb,
            width: resizeWidth,
            height: resizeHeight
          } = resizeImage({
            canvas,
            initWidth: width,
            initHeight: height,
            maxResizeWidth,
            maxResizeHeight,
            minWidth,
            minHeight,
            orientation,
            image,
            eventWidth,
            eventHeight,
            file: fileToUse,
            make
          })
          resolve({
            photo: resizedFile,
            thumb,
            width: resizeWidth,
            height: resizeHeight
          })
          return
        } finally {
          // If it fails fallback to drawing the image as is
          ctx.drawImage(image, 0, 0, image.width, image.height)
          resolve({
            photo: base64ToFile(canvas.toDataURL('image/jpeg'), fileToUse),
            thumb: canvas.toDataURL('image/jpeg'),
            width,
            height
          })
        }
      }
      image.onerror = () => {
        reject(new TypeError('file_corrupted'))
      }

      image.src = imageSource
    })
  }

  function getCroppedImage(
    origFile: any,
    scrollX: number,
    scrollY: number,
    appliedWidth: number, // displayed image width in UI (with zoom)
    appliedHeight: number, // displayed image height in UI
    scaledCropperWidth: number, // the on-screen cropper window width
    scaledCropperHeight: number, // the on-screen cropper window height
    targetWidth: number, // final output width
    targetHeight: number, // final output height
    bgColor: string
  ): Promise<any> {
    const file = origFile.file
    let fileToUse = file

    // Convert HEIC if needed
    if (
      file.name.toLowerCase().includes('.heic') ||
      file.name.toLowerCase().includes('.heif')
    ) {
      fileToUse = heicToJpeg(file)
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = e => {
        if (fileToUse.type.match('image.*') && e.target) {
          const origImg = new Image()
          origImg.onload = event => {
            // @ts-ignore
            const imgWidth = event.target.width
            // @ts-ignore
            const imgHeight = event.target.height

            const canvas = document.createElement('canvas')
            canvas.width = targetWidth
            canvas.height = targetHeight
            canvas.style.opacity = '0'

            try {
              const ctx = canvas.getContext('2d')
              if (!ctx) throw new Error('Canvas context not supported')

              ctx.fillStyle = bgColor
              ctx.fillRect(0, 0, canvas.width, canvas.height)

              const ratioX = targetWidth / scaledCropperWidth
              const ratioY = targetHeight / scaledCropperHeight

              ctx.save()
              ctx.scale(ratioX, ratioY)

              const scaleX = appliedWidth / imgWidth
              const scaleY = appliedHeight / imgHeight

              ctx.translate(
                scaledCropperWidth / 2 + scrollX,
                scaledCropperHeight / 2 + scrollY
              )
              ctx.scale(scaleX, scaleY)
              ctx.drawImage(origImg, -imgWidth / 2, -imgHeight / 2)
              ctx.restore()

              const resultSrc = canvas.toDataURL('image/jpeg')
              const resultFile = base64ToFile(resultSrc, fileToUse)

              resolve({ file: resultFile, src: resultSrc })
            } catch (e) {
              logger.captureError(new TypeError(`Error cropping`))
              logger.captureError(e)
              reject(e)
            }
          }
          origImg.src = e.target.result as string
        } else {
          logger.captureError(new TypeError(`File not an image`))
          reject(new TypeError(`File not an image`))
        }
      }

      reader.onerror = reject
      reader.readAsDataURL(fileToUse)
    })
  }

  function getAverageColorsFromImage(imgEl: any) {
    const blockSize = 5 // only visit every 5 pixels
    const defaultRGB = { r: 255, g: 255, b: 255 } // for non-supporting envs - white
    const canvas = document.createElement('canvas')
    const context = canvas.getContext && canvas.getContext('2d')
    let data
    let i = -4
    const rgb = { r: 0, g: 0, b: 0 }
    let count = 0

    if (!context) {
      return defaultRGB
    }

    const height = (canvas.height =
      imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height)
    const width = (canvas.width =
      imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width)

    context.drawImage(imgEl, 0, 0)

    try {
      data = context.getImageData(0, 0, width, height)
    } catch (e) {
      /* security error, img on diff domain */
      return defaultRGB
    }

    const length = data.data.length

    while ((i += blockSize * 4) < length) {
      ++count
      rgb.r += data.data[i]
      rgb.g += data.data[i + 1]
      rgb.b += data.data[i + 2]
    }

    // ~~ used to floor values
    rgb.r = ~~(rgb.r / count)
    rgb.g = ~~(rgb.g / count)
    rgb.b = ~~(rgb.b / count)

    function rgbToHex(r: number, g: number, b: number) {
      function componentToHex(v: any) {
        const hex = v.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      }
      return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
    }

    return rgbToHex(rgb.r, rgb.g, rgb.b)
  }

  return {
    fileToBase64,
    resizeAndReadImage,
    heicToJpeg,
    getAverageColorsFromImage,
    getCroppedImage,
    loadHeicTo
  }
}

































import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { useI18n } from '~/compositions/i18n'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineComponent({
  props: {
    corruptedFiles: {
      type: Array as PropType<File[]>,
      required: true
    }
  },
  setup() {
    const { t, tc } = useI18n()

    return { t, tc }
  },
  i18n: defineComponentTranslations({
    corrupted_files: {
      en: 'Corrupted file | Corrupted files',
      el: 'Κατεστραμμένο αρχείο | Κατεστραμμένα αρχεία'
    },
    following_files_corrupted: {
      en:
        'The following file is corrupted and cannot be added | The following files are corrupted and cannot be added',
      el:
        'Το παρακάτω αρχείο είναι κατεστραμμένο και δεν γίνεται να προστεθεί | Τα παρακάτω αρχεία είναι κατεστραμμένα και δεν γίνεται να προστεθούν'
    }
  })
})

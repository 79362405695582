import { CategoryId } from '~/models/category/types'

export function resolveCategory(
  categoryDicts: Partial<Record<CategoryId | number, any>> = {},
  categoryArray: CategoryId[] | number[] = []
) {
  if (categoryDicts.constructor !== Object) {
    return null
  }

  for (let i = categoryArray.length - 1; i >= 0; i--) {
    if (categoryDicts[categoryArray[i]] !== undefined) {
      return categoryDicts[categoryArray[i]]
    }
  }

  return null
}

























































































































import {
  computed,
  defineComponent,
  ref,
  watch,
  vue3Set
} from '~/utils/nuxt3-migration'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import { useAdItemForm } from '~/compositions/advertise/ad-item-form'
import CCategoryTreeSelect from '~/components/shared/configurable/category/tree-select/CCategoryTreeSelect.vue'
import { CategoryId } from '~/models/category/types'
import { MakeLogoType } from '~/models/make'
import { Option } from '~/models/shared/types'
import { useI18n } from '~/compositions/i18n'
import { arraysEqual } from '~/utils/array'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import CInfoTooltip from '~/components/shared/configurable/info/CInfoTooltip.vue'

export default defineComponent({
  components: {
    CInfoTooltip,
    CCustomSelect,
    CCategoryTreeSelect,
    CFormError
  },
  props: {},
  setup(_, { emit }) {
    const {
      getSchemaField,
      emitParams,
      errors,
      adItemValues,
      schema,
      loading,
      lockedMode,
      currentAdItem
    } = useAdItemForm()

    const { t } = useI18n()
    const categorySelectRef = ref(null)
    const getPlacementContextCategories = () => {
      return adItemValues.value.placementContext?.categories || []
    }

    const categories = ref(getPlacementContextCategories())

    const getPlacementContextMakes = () => {
      return adItemValues.value.placementContext?.makes || []
    }

    const makes = ref(getPlacementContextMakes())

    const getPlacementContextLocations = () => {
      return adItemValues.value.placementContext?.locations || []
    }

    const locations = ref(getPlacementContextLocations())

    const adItemValuesCategories = computed(
      () => adItemValues.value.placementContext?.caregories
    )

    watch(adItemValuesCategories, val => {
      if (!val || !arraysEqual(val || [], categories.value)) {
        categories.value = getPlacementContextCategories()
      }
    })
    watch(adItemValues, val => {
      if (
        !val.placementContext?.makes ||
        !arraysEqual(val.placementContext.makes || [], categories.value)
      ) {
        makes.value = getPlacementContextMakes()
      }
      if (
        !val.placementContext?.locations ||
        !arraysEqual(val.placementContext.locations || [], locations.value)
      ) {
        locations.value = getPlacementContextLocations()
      }
    })

    const showMakesLogos = (category: string | number) => {
      const catsWithLogo = [
        CategoryId.CARS.toString(),
        CategoryId.BIKES.toString(),
        CategoryId.TAXIS.toString(),
        CategoryId.TAXIS.toString(),
        CategoryId.VANS.toString(),
        CategoryId.BUSES.toString()
      ]
      return catsWithLogo.includes(category)
    }

    watch(schema, () => populateMakeOptionGroups())

    const makeLogoType = (category: number | string) => {
      return category.toString() === CategoryId.BIKES.toString()
        ? MakeLogoType.BIKES
        : MakeLogoType.CARS
    }
    const schemaMakes = ref<any>([])

    const populateMakeOptionGroups = () => {
      schemaMakes.value = []
      if (getSchemaField('placement_context')?.makes) {
        const makesObj = getSchemaField('placement_context')?.makes
        Object.keys(makesObj).forEach(key => {
          const allOptions = makesObj[key].map(s => {
            const option: Option = {
              text: s.label,
              value: s.value,
              key: s.value,
              isTop: s.isTop
            }
            return option
          })

          const topOptions = allOptions.filter((option: Option) => option.isTop)
          const restOfOptions = allOptions.filter(
            (option: Option) => !option.isTop
          )

          schemaMakes.value.push({
            makeCategory: key,
            makeOptionGroups: [
              {
                label: t('popular makes'),
                options: topOptions
              },
              {
                label: t('all makes'),
                options: restOfOptions
              }
            ]
          })
        })

        // check if categories have all schema makes, if not remove them
        if (makes.value.length) {
          const makeSchemaCategories = schemaMakes.value.map(s =>
            s.makeCategory.toString()
          )

          for (let i = 0; i < makes.value.length!; i++) {
            if (
              !makeSchemaCategories.includes(
                makes.value[i].makeCategory.toString()
              )
            ) {
              // category was probably removed
              makes.value.splice(i, 1)
            }
          }
        }
      }
    }

    const locationOptions = computed(() => {
      return (
        getSchemaField('placement_context')?.locations.map(l => {
          return { text: l.label, value: l.value }
        }) || []
      )
    })

    const onCategoryInput = (inputCatgories: number[]) => {
      categories.value = inputCatgories
      emitParams('placementContext', {
        ...adItemValues.value.placementContext,
        categories: inputCatgories
      })
      emit('fetch-schema')
    }
    const handleMakesChange = (
      category: number | string,
      inputMakes: number[]
    ) => {
      const makeCatIndex = makes.value.findIndex(
        m => m.makeCategory.toString() === category.toString()
      )

      if (makeCatIndex > -1) {
        if (!inputMakes || inputMakes.length === 0) {
          makes.value.splice(makeCatIndex, 1)
        } else {
          vue3Set(makes.value[makeCatIndex], 'makeIds', inputMakes)
        }
      } else if (inputMakes.length) {
        // new make cat added
        makes.value.push({
          makeCategory: Number(category),
          makeIds: inputMakes
        })
      } else {
        makes.value = []
      }
      emitParams('placementContext', {
        ...adItemValues.value.placementContext,
        makes: makes.value
      })
    }

    const getMakeValue = computed(() => {
      return (category: string | number) => {
        return (
          makes.value.find(m => m.makeCategory.toString() === category.toString)
            ?.makeIds || []
        )
      }
    })

    const selectedMakeIds = computed(
      () =>
        makes.value?.reduce((acc, curr) => {
          acc[curr.makeCategory] = curr.makeIds
          return acc
        }, {}) || {}
    )

    const onLocationChange = (inputLocation: string[]) => {
      locations.value = inputLocation
      emitParams('placementContext', {
        ...adItemValues.value.placementContext,
        locations: inputLocation
      })
    }

    const getCategoryHumanName = (category: string | number) => {
      return categorySelectRef.value?.getCategoryHumanName(category) || null
    }

    return {
      getSchemaField,
      errors,
      handleMakesChange,
      onCategoryInput,
      showMakesLogos,
      makeLogoType,
      schema,
      locations,
      schemaMakes,
      categories,
      makes,
      onLocationChange,
      loading,
      locationOptions,
      getMakeValue,
      categorySelectRef,
      getCategoryHumanName,
      selectedMakeIds,
      lockedMode,
      currentAdItem,
      adItemValuesCategories
    }
  }
})

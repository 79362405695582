

































import { defineComponent, computed, toRefs } from '~/utils/nuxt3-migration'
import { useQuickAdForm } from '~/compositions/advertise/quick-ad-form'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { slots }) {
    const { subtitle } = toRefs(props)

    const subtitleSlot = computed(() => slots.subtitle)

    const hasSubtitle = computed(() =>
      Boolean(subtitle.value || slots.subtitle)
    )

    const { inQuickForm } = useQuickAdForm()

    return {
      hasSubtitle,
      inQuickForm,
      slots,
      subtitleSlot
    }
  }
})

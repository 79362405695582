import { CIcon } from '~/icons/types'

export const ciCog: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'm20.5 13.7-.9-.7c-.1-.1-.2-.2-.2-.4v-1.2c0-.2.1-.4.2-.4l.8-.7c.7-.6.9-1.6.4-2.4L20 6.3c-.5-.8-1.4-1.1-2.3-.8l-1 .4c-.1 0-.3 0-.4-.1-.3-.2-.6-.4-1-.6-.2-.1-.3-.2-.3-.3l-.2-1.1c-.2-.9-.9-1.6-1.8-1.6h-1.8c-.9 0-1.7.7-1.8 1.6L9 4.9c0 .1-.1.2-.2.3-.3.2-.7.4-1 .6-.2.1-.4.1-.5.1l-1-.4c-.9-.3-1.8 0-2.3.8l-.9 1.6c-.5.8-.3 1.8.4 2.4l.9.7c.1.1.2.2.2.4v1.2c0 .2-.1.4-.2.4l-.8.7c-.7.6-.9 1.6-.4 2.4l.8 1.6c.5.8 1.4 1.1 2.3.8l1-.4c.1 0 .3 0 .4.1.3.2.6.4 1 .6.2.1.3.2.3.3l.2 1.1c.2.9.9 1.6 1.8 1.6h1.8c.9 0 1.7-.7 1.8-1.6l.2-1.1c0-.1.1-.3.3-.3.3-.2.7-.4 1-.6.2-.1.3-.1.4-.1l1 .4c.9.3 1.8 0 2.3-.8l.9-1.6c.7-.8.5-1.8-.2-2.4ZM12 15.8c-2.1 0-3.8-1.7-3.8-3.8 0-2.1 1.7-3.8 3.8-3.8 2.1 0 3.8 1.7 3.8 3.8 0 2.1-1.7 3.8-3.8 3.8Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'cog',
  type: 'solid'
}







































import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '~/utils/nuxt3-migration'
import { AdItem, AdItemStatus } from '~/models/advertising/types'
import CBadge from '../configurable/badge/CBadge.vue'
import { ciApproved } from '~/icons/source/regular/approved'
import { ciPending } from '~/icons/source/regular/pending'
import { format } from '~/utils/date'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'

export default defineComponent({
  components: { CBadge },
  props: {
    status: {
      type: String as PropType<AdItemStatus>,
      required: true
    },
    badge: {
      type: Boolean,
      required: false,
      default: true
    },
    item: {
      type: Object as PropType<AdItem>,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const { status, item, badge } = toRefs(props)
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const userIsAdmin = computed(() => userGetters('isAdmin'))

    const actor = computed(() => {
      if (!userIsAdmin.value) {
        return undefined
      }
      if (
        item.value?.status === AdItemStatus.APPROVED &&
        item.value?.approvedBy
      ) {
        return item.value?.approvedBy
      } else if (
        item.value?.status === AdItemStatus.REJECTED &&
        item.value?.rejectedBy
      ) {
        return item.value?.rejectedBy
      }

      return undefined
    })

    const tooltipString = computed(() => {
      if (!userIsAdmin.value) {
        return ''
      }

      if (
        item.value?.status === AdItemStatus.APPROVED &&
        item.value?.approvedTimestamp
      ) {
        return format(item.value?.approvedTimestamp, 'dd MMM yyyy, HH:mm')
      } else if (
        item.value?.status === AdItemStatus.REJECTED &&
        item.value?.rejectedTimestamp
      ) {
        return format(item.value?.rejectedTimestamp, 'dd MMM yyyy, HH:mm')
      }

      return ''
    })

    const bgClass = computed(() => {
      switch (status.value) {
        case AdItemStatus.PENDING: {
          return 'tw-bg-cyan-500'
        }
        case AdItemStatus.DRAFT: {
          return 'tw-bg-grey-500'
        }

        case AdItemStatus.APPROVED: {
          return 'tw-bg-green-300'
        }

        case AdItemStatus.REJECTED: {
          return 'tw-bg-red-400'
        }

        case AdItemStatus.REPLACED: {
          return 'tw-bg-amber-600'
        }
        default: {
          return 'tw-bg-grey-500'
        }
      }
    })

    const textColorClass = computed(() => {
      if (badge.value) {
        return 'tw-text-white'
      }

      switch (status.value) {
        case AdItemStatus.PENDING: {
          return 'tw-text-cyan-500'
        }
        case AdItemStatus.DRAFT: {
          return 'tw-text-grey-600'
        }

        case AdItemStatus.APPROVED: {
          return 'tw-text-green-300'
        }

        case AdItemStatus.REJECTED: {
          return 'tw-text-red-400'
        }

        case AdItemStatus.REPLACED: {
          return 'tw-text-amber-600'
        }
        default: {
          return 'tw-text-primary'
        }
      }
    })
    const fontWeightClass = computed(() => {
      switch (status.value) {
        case AdItemStatus.APPROVED:
        case AdItemStatus.REJECTED:
        case AdItemStatus.PENDING: {
          return 'tw-font-bold'
        }

        case AdItemStatus.REPLACED:
        case AdItemStatus.DRAFT: {
          return 'tw-font-semibold'
        }

        default: {
          return 'tw-font-normal'
        }
      }
    })

    const icon = computed(() => {
      switch (status.value) {
        case AdItemStatus.PENDING: {
          return ciPending
        }

        case AdItemStatus.APPROVED: {
          return ciApproved
        }

        default: {
          return null
        }
      }
    })

    return {
      bgClass,
      textColorClass,
      fontWeightClass,
      icon,
      actor,
      tooltipString
    }
  }
})

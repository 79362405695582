




















import {
  computed,
  defineComponent,
  ref,
  toRefs,
  useRoute,
  useRouter,
  Vue,
  onMounted
} from '~/utils/nuxt3-migration'
import AdViewService from '~/services/ads/AdViewService'
import { useDeps } from '~/compositions/dependency-container'
import CAdContainer from '~/components/shared/configurable/ad/container/CAdContainer.vue'
import { InHouseSizes } from '~/constants/ads/inhouse'
import InHouseService from '~/services/ads/InHouseService'
import { AdItem } from '~/models/advertising/types'
import RenderedInHouseItem from '~/components/shared/advertising/RenderedInHouseItem.vue'
import { useLogger } from '~/compositions/logger'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { useNamespacedStore } from '~/compositions/store'
import { Facet } from '~/models/search/types'
import {
  CLASSIFIED_VIEW_NS,
  ClassifiedViewState
} from '~/store/modules/shared/classifieds/view/state'
import { CategoryId } from '~/models/category/types'
import { useI18n } from '~/compositions/i18n'
import { defineComponentTranslations } from '~/utils/i18n'
import { Popover } from 'driver.js'
import { useDriver } from '~/compositions/driver'
import CButton from '~/components/shared/configurable/button/CButton.vue'
import { useQuickAdForm } from '~/compositions/advertise/quick-ad-form'

export default defineComponent({
  components: { RenderedInHouseItem, CAdContainer },
  props: {
    unit: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { unit } = toRefs(props)
    const slot = ref<AdItem | null>(null)
    const loading = ref<boolean>(false)
    const adContainerRef = ref(null)

    const logger = useLogger()
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const { driver } = useDriver()
    const { clearPreviewUrlParams } = useQuickAdForm()

    const queryUnit = computed(() => route.value.query.unit)
    const queryItemId = computed(() => route.value.query.item)
    const previewMode = computed(
      () =>
        queryUnit.value === unit.value &&
        queryItemId.value &&
        route.value.query.success
    )

    onMounted(() => {
      if (
        queryUnit.value === unit.value &&
        ((queryItemId.value &&
          route.value.query.cancel &&
          !route.value.query.success) ||
          route.value.query.force_ad)
      ) {
        emit('no-fetch')
        return
      }

      if (previewMode.value) {
        fetchEntry()
      }
    })

    const { getters: searchGetters, state: searchState } = useNamespacedStore<
      SearchState
    >(CLASSIFIED_SEARCH_NS)

    const { state: classifiedViewState } = useNamespacedStore<
      ClassifiedViewState
    >(CLASSIFIED_VIEW_NS)

    const selectedSearchFacets = computed(() =>
      searchGetters('getSelectedFacets')
    )

    const unitDetails = computed(() => {
      return InHouseSizes[unit.value] || {}
    })

    const [adViewService, inHouseService] = useDeps(
      AdViewService,
      InHouseService
    )
    const containerSize = computed(() => {
      if (unit.value === 'in_house_search_dt_top_gal') {
        return [250, 250]
      }
      return adViewService.getMaxSizeDimensions(unitDetails.value.sizes || [])
    })
    const sizeMapping = computed(() => {
      return unitDetails.value.sizesMappings || []
    })

    const categories = computed(() => {
      if (unit.value.startsWith('in_house_search')) {
        if (searchState.categoryIds?.length) {
          return searchState.categoryIds.filter(
            c => c !== CategoryId.CLASSIFIEDS // no need to send this one
          )
        }
      } else if (unit.value.startsWith('in_house_cls')) {
        // in classified view
        return classifiedViewState.classified?.category_ids || []
      }

      return []
    })

    const makes = computed(() => {
      if (unit.value.startsWith('in_house_search')) {
        // in search
        const makeFacet = selectedSearchFacets.value.find(
          (f: Facet) => f.name === 'makemodel'
        )
        if (makeFacet) {
          return makeFacet.values?.selected?.makes || []
        } else {
          const forMakeModelFacet = selectedSearchFacets.value.find(
            (f: Facet) => f.name === 'for_makemodels'
          )

          if (forMakeModelFacet) {
            return (
              forMakeModelFacet.values?.params
                ?.filter(p => p[0] === 'makes_ids')
                .map(fp => fp[1]) || []
            )
          }
        }
      } else if (unit.value.startsWith('in_house_cls')) {
        // in classified view
        const classifiedMake = classifiedViewState.adsTargetings?.find(
          t => t.name === 'make'
        )
        if (classifiedMake) {
          return [classifiedMake.value]
        }
      }

      return []
    })

    const highlightAd = (item: AdItem) => {
      // @ts-ignore
      const driverInstance = driver({
        popoverClass: 'driverjs-theme',
        onCloseClick: () => {
          driverInstance.destroy()
        },
        showButtons: ['close'],
        onPopoverRender: (popover: Popover, _options) => {
          // Create the Vue instance for the button
          const vm = new Vue({
            render: h =>
              h(
                CButton,
                {
                  props: {
                    variant: 'secondary',
                    size: 'sm',
                    type: 'button'
                  },
                  class: 'tw-mt-4 tw-w-full',
                  on: {
                    click: () => {
                      router.push({
                        name: '__advertising_dashboard_ads',
                        query: {
                          campaign: item.campaignId,
                          ad: item.adId,
                          item: item.id
                        }
                      })
                      driverInstance.destroy()
                    }
                  }
                },
                [t('preview_action')]
              ) // Slot content
          })

          // Mount the Vue instance and get the actual DOM element
          const buttonInstance = vm.$mount()

          // Append the button to the popover
          popover.description.appendChild(buttonInstance.$el)
        },
        steps: [
          {
            element: adContainerRef.value.$el as HTMLElement,
            popover: {
              title: t('preview_title_1') as string,
              description: t('preview_description_1') as string,
              side: 'top-start',
              showButtons: ['close']
            }
          }
        ]
      })

      driverInstance.drive()
      clearPreviewUrlParams()
    }
    const fetchEntry = async () => {
      try {
        loading.value = true

        let itemId

        if (previewMode.value) {
          itemId = queryItemId.value
        }
        const { item } = await inHouseService.fetchInHouseEntry(
          unit.value,
          makes.value,
          categories.value,
          itemId
        )

        if (previewMode.value && item && adContainerRef.value) {
          highlightAd(item)
        }
        if (!item) {
          emit('no-fetch')
          return
        }
        slot.value = item
      } catch (e) {
        logger.captureError(e)
      } finally {
        loading.value = false
      }
    }

    const refresh = () => {
      // this.googleAdsService.refreshSlots([slot.value])
      slot.value = null
      loading.value = false
    }

    const recordImpression = async () => {
      try {
        if (slot.value?.id && !previewMode.value) {
          await inHouseService.recordImpression(slot.value.id)
        }
      } catch (e) {
        logger.captureError(e)
      }
    }
    const triggerLoad = (visible: boolean) => {
      if (!slot.value && visible && !loading.value && !previewMode.value) {
        fetchEntry()
      }
    }

    return {
      fetchEntry,
      refresh,
      containerSize,
      sizeMapping,
      unitDetails,
      slot,
      recordImpression,
      triggerLoad,
      previewMode,
      selectedSearchFacets,
      makes,
      adContainerRef
    }
  },
  i18n: defineComponentTranslations({
    preview_title_1: {
      en: 'Your ad is almost ready!',
      el: 'Η διαφήμισή σου'
    },
    preview_description_1: {
      en:
        'This is what your ad will look like after approval. View its status and manage your ads in detail in the Advertising Dashboard.',
      el:
        'Έτσι θα φαίνεται η διαφήμισή σου μετά την έγκρισή της. Δες την κατάστασή της και διαχειρίσου λεπτομερώς τις διαφημίσεις σου στο Dashboard Διαφημίσεων.'
    },
    preview_action: {
      en: 'Advertising Dashboard',
      el: 'Dashboard Διαφημίσεων'
    }
  })
})

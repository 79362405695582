import { CIcon } from '~/icons/types'

export const ciCalendar: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M18.7 3.6H17V3c0-.6-.4-1-1-1s-1 .4-1 1v.6H9V3c0-.6-.4-1-1-1s-1 .4-1 1v.6H5.3c-1.7 0-3 1.3-3 3V19c0 1.7 1.3 3 3 3h13.5c1.7 0 3-1.3 3-3V6.6c-.1-1.7-1.4-3-3.1-3Zm-14.4 3c0-.6.4-1 1-1H7v.6c0 .6.4 1 1 1s1-.4 1-1v-.6h6v.6c0 .6.4 1 1 1s1-.4 1-1v-.6h1.8c.6 0 1 .4 1 1v1.8H4.3V6.6ZM18.7 20H5.3c-.6 0-1-.4-1-1v-8.6h15.5V19c-.1.6-.5 1-1.1 1Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'calendar',
  type: 'regular'
}































































import * as CTreeSelectNode from '~/components/shared/configurable/form/select/tree/CTreeSelectNode.vue'
import { ciExternalLink } from '~/icons/source/regular/external-link'
import { defineComponent, PropType, computed } from '~/utils/nuxt3-migration'
import { formatNumber } from '~/utils/number'

export default defineComponent({
  name: 'CTreeSelectNode',
  components: {
    // @ts-ignore
    CTreeSelectNode
  },
  props: {
    isChild: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    },
    children: {
      type: Array,
      default() {
        return []
      }
    },
    id: {
      type: [Number, String],
      default: null
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    valueCol: {
      type: String,
      default: 'value'
    },
    nameCol: {
      type: String,
      default: 'name'
    },
    countCol: {
      type: String,
      default: 'count'
    },
    childrenCol: {
      type: String,
      default: 'children'
    },
    showCount: {
      type: Boolean,
      default: true
    },
    redirectUrl: {
      type: String,
      default: null
    },
    size: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      required: true
    },
    useFullScreenModal: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const internalValue = computed({
      get() {
        return props.value
      },
      set(newValue) {
        emit('change', newValue)
      }
    })

    const isSelected = computed(() => internalValue.value.includes(props.id))

    const isDisabled = computed(
      () =>
        !props.redirectUrl &&
        !isSelected.value &&
        props.showCount &&
        props.count &&
        props.count <= 0
    )

    const nodeClasses = computed(() => {
      const classes = []

      if (isDisabled.value) {
        classes.push('tw-cursor-default tw-opacity-40 tw-pointer-events-none')
      } else {
        classes.push('tw-cursor-pointer lg:hover:tw-text-blue-500')
      }

      if (isSelected.value) {
        classes.push('tw-text-blue-500 tw-font-medium')
      } else {
        classes.push('tw-text-grey-900')
      }

      if (props.size === 'lg' || props.useFullScreenModal) {
        classes.push('tw-py-3 tw-text-lg')
      } else if (props.size === 'sm') {
        classes.push('tw-py-1.5 tw-text-base')
      } else {
        classes.push('tw-py-2.5 tw-text-base')
      }

      return classes
    })

    const nodeContainerClasses = computed(() => {
      if (!props.isChild) {
        return []
      }

      const classes = []

      if (props.size === 'lg') {
        classes.push('tw-pl-6')
      } else {
        classes.push('tw-pl-4')
      }

      return classes
    })

    function onNodeClick() {
      internalValue.value = isSelected.value
        ? internalValue.value.filter((v: any) => v !== props.id)
        : [...internalValue.value, props.id]
    }

    return {
      ciExternalLink,
      internalValue,
      isDisabled,
      nodeClasses,
      nodeContainerClasses,
      onNodeClick,
      formatNumber
    }
  }
})







































































































































































import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch
} from '~/utils/nuxt3-migration'
import AdImageUploadModal from '~/components/shared/advertising/forms/AdImageUploadModal.vue'
import { useDroppable } from '~/compositions/cursor'
import { useFileUpload } from '~/compositions/files/upload'
import { useFile } from '~/compositions/files/file'
import { useId } from '~/compositions/id'
import { useDep } from '~/compositions/dependency-container'
import AdvertiseService from '~/services/ads/AdvertiseService'
import { useI18n } from '~/compositions/i18n'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { useAdItemForm } from '~/compositions/advertise/ad-item-form'
import CConfirmationModal from '~/components/shared/configurable/modal/CConfirmationModal.vue'
import { useLogger } from '~/compositions/logger'
import { useSnackbar } from '~/compositions/snackbar'
import CImageCropperModal from '~/components/shared/configurable/user/avatar/CImageCropperModal.vue'
import CCorruptedFilesModal from '~/components/shared/configurable/modal/CCorruptedFilesModal.vue'
import { ciImageUpload } from '~/icons/source/solid/image-upload'
import { ciTrash } from '~/icons/source/solid/trash'

export default defineComponent({
  components: {
    CCorruptedFilesModal,
    CImageCropperModal,
    CConfirmationModal,
    CImg,
    AdImageUploadModal
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { type, label } = toRefs(props)
    const showUploadModal = ref(false)
    const dropInputTemplateRef = ref<HTMLElement | null>(null)
    const showCropperModal = ref(false)
    const cropperModalFile = ref<any>(null)

    const {
      emitParams,
      adItemValues,
      disableInputs,
      lockedMode
    } = useAdItemForm()
    const { uuid } = useId()
    const id = uuid()

    const loading = ref(false)
    const showDeleteConfirm = ref(false)

    const corruptedFiles = ref<File[]>([])
    const showCorruptedFilesModal = ref(false)

    const typeValue = computed(() => adItemValues.value[type.value])

    const getImage = () => {
      if (typeValue.value) {
        return {
          thumb: typeValue.value,
          absoluteUrl: typeValue.value,
          url: typeValue.value,
          uploading: false,
          attached: true,
          id,
          progress: 100
        }
      }
      return null
    }
    const image = ref<any>(getImage())

    watch(typeValue, (val, oldVal) => {
      if (val !== oldVal) {
        image.value = getImage()
      }
    })

    const logoMode = computed(() => type.value === 'square_image_url')

    const acceptedFileTypes = computed(() => [
      'image/jpeg',
      'image/png',
      'image/gif'
    ])
    const { droppingOver, onDrop, onDragOver, onDragLeave } = useDroppable(
      false,
      fileDropped
    )
    const { filesAreOfAcceptableType } = useFileUpload()
    const { resizeAndReadImage } = useFile()
    const advertisingService = useDep(AdvertiseService)
    const { t } = useI18n()
    const snackbar = useSnackbar()
    const logger = useLogger()

    async function addFiles(fileList: FileList) {
      if (!fileList || !fileList.length) {
        return
      }
      if (
        acceptedFileTypes.value?.length &&
        !filesAreOfAcceptableType(fileList, acceptedFileTypes.value)
      ) {
        return alert(t('this file type is not supported'))
      }

      if (!fileList[0].type.toLowerCase().includes('gif')) {
        try {
          const currentFile = fileList[0]
          showCropperModal.value = true // first open modal, in order to trigger the on file watch

          const {
            thumb: processedThumb,
            photo: processedPhoto,
            width: processedWidth,
            height: processedHeight
          } = await resizeAndReadImage({
            file: currentFile,
            maxResizeWidth: 99999999,
            maxResizeHeight: height.value,
            exactOnly: false,
            minWidth: width.value,
            minHeight: height.value
          })

          cropperModalFile.value = {
            file: processedPhoto,
            originalFilename: currentFile.name,
            filename: currentFile.name,
            thumb: processedThumb,
            width: processedWidth,
            height: processedHeight
          }
        } catch (e) {
          // an error occurred in resizeAndReadImage, probably wrong size
          showCropperModal.value = false
          if (e.message === 'file_corrupted') {
            corruptedFiles.value.push(fileList[0])
            showCorruptedFilesModal.value = true
          } else {
            snackbar.error(t('something went wrong'), {
              classes: ['above-ads-footer']
            })
          }
          clearImage()
        }
      } else {
        uploadImageFile(fileList[0], URL.createObjectURL(fileList[0]))
      }
    }

    const onCorruptedOk = () => {
      showCorruptedFilesModal.value = false
      corruptedFiles.value = []
    }

    const width = computed(() => {
      if (logoMode.value) {
        return 500
      }
      const splitStr = type.value.split('_')
      const num = splitStr[1]

      return Number(num)
    })

    const height = computed(() => {
      if (logoMode.value) {
        return 500
      }
      const splitStr = type.value.split('_')
      const num = splitStr[2]

      return Number(num)
    })

    async function uploadImageFile(
      file: File,
      thumb: string,
      processFile: boolean = true
    ) {
      let photo = file
      if (!file.type.toLowerCase().includes('gif') && processFile) {
        try {
          const {
            thumb: processedThumb,
            photo: processedPhoto
          } = await resizeAndReadImage({
            file,
            maxResizeWidth: width.value,
            maxResizeHeight: height.value,
            exactOnly: !logoMode.value
          })
          thumb = processedThumb
          photo = processedPhoto
        } catch (e) {
          // an error occurred in resizeAndReadImage, probably wrong size
          if (e.message === 'file_corrupted') {
            corruptedFiles.value.push(file)
            showCorruptedFilesModal.value = true
          }

          clearImage()
          return
        }
      }

      image.value = {
        thumb,
        uploading: true,
        attached: false,
        id,
        progress: 0
      }
      try {
        emit('upload-start')
        const { url, absoluteUrl } = await advertisingService.postAdAsset(
          photo,
          type.value,
          progressEvt =>
            (image.value.progress = Math.round(
              (progressEvt.loaded * 100) / progressEvt.total!
            ))
        )
        image.value.absoluteUrl = absoluteUrl
        image.value.url = url
        image.value.attached = true
        image.value.uploading = false

        emitParams(type.value, absoluteUrl)
      } catch (error) {
        clearImage()

        snackbar.error(
          (error as any)?.response?.data?.message || t('something went wrong'),
          {
            classes: ['above-ads-footer']
          }
        )
        logger.captureError(error)
      } finally {
        emit('upload-end')
      }
    }
    function fileInputChanged(e: Event) {
      addFiles((e.target as any).files)
    }
    function fileDropped(e: DragEvent) {
      if (!image.value && !disableInputs.value) {
        addFiles((e.dataTransfer as any).files)
      }
    }
    function onDeleteConfirm() {
      clearImage()
      emitParams(type.value, null)
    }

    function clearImage() {
      image.value = null
      if (dropInputTemplateRef.value) {
        dropInputTemplateRef.value.value = '' // resetting the file input so it works if we upload the same image
      }
    }

    function triggerInput(): void {
      if (disableInputs.value) {
        return
      }
      if (showUploadModal.value) {
        showUploadModal.value = false
      }
      if (dropInputTemplateRef.value) {
        dropInputTemplateRef.value.click()
      }
    }

    const onImageSelect = img => {
      if (img.url) {
        image.value = {
          url: img.url,
          absoluteUrl: img.url,
          uploading: false,
          attached: true,
          id,
          progress: 100
        }
        emitParams(type.value, img.url)
      }
    }

    const onChooseFromLibraryClick = () => {
      if (!disableInputs.value) {
        showUploadModal.value = true
      }
    }

    const showDeleteButton = computed(() => {
      return image.value && image.value.attached && !lockedMode.value
    })

    const onCropperResult = (file: any) => {
      uploadImageFile(file.file, file.src, false)
    }

    const onCropperHide = () => {
      cropperModalFile.value = null
      if (dropInputTemplateRef.value) {
        dropInputTemplateRef.value.value = '' // resetting the file input so it works if we upload the same image
      }
    }

    const appliedStyle = computed(() => {
      if (!image.value && !logoMode.value) {
        return {
          maxWidth: `${width.value}px`,
          maxHeight: `${height.value}px`,
          aspectRatio: `${width.value}/${height.value}`,
          width: '100%'
        }
      }
      return undefined
    })

    const labelToShow = computed(() => {
      if (!logoMode.value) {
        return `${label.value} (${width.value}x${height.value} pixels)`
      }
      return label.value
    })

    return {
      showUploadModal,
      acceptedFileTypes,
      triggerInput,
      fileInputChanged,
      loading,
      droppingOver,
      onDrop,
      onDragOver,
      onDragLeave,
      onDeleteConfirm,
      dropInputTemplateRef,
      ciImageUpload,
      width,
      height,
      image,
      addFiles,
      typeValue,
      showDeleteConfirm,
      logoMode,
      onImageSelect,
      disableInputs,
      lockedMode,
      ciTrash,
      showDeleteButton,
      onChooseFromLibraryClick,
      showCropperModal,
      cropperModalFile,
      onCropperResult,
      onCropperHide,
      showCorruptedFilesModal,
      corruptedFiles,
      onCorruptedOk,
      appliedStyle,
      labelToShow
    }
  }
})


























import { defineComponent, PropType, computed } from '~/utils/nuxt3-migration'
import { ciAngleRight } from '~/icons/source/solid/angle-right'
import { useI18n } from '~/compositions/i18n'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'

export default defineComponent({
  components: { CBadge },
  props: {
    typedText: {
      tyup: String,
      required: true
    },
    categoryPath: {
      type: Array as PropType<string[]>,
      required: true
    },
    tags: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => []
    }
  },
  setup(props) {
    const { t } = useI18n()

    const tagsToShow = computed(() => {
      const arr = []
      if (props.tags?.length) {
        if (props.tags.includes('my_classifieds')) {
          arr.push(t('my classifieds'))
        }
        if (props.tags.includes('all')) {
          arr.push(t('all'))
        }
      }
      return arr
    })
    return {
      ciAngleRight,
      tagsToShow
    }
  },
  methods: {
    isLast(index: number) {
      return index === this.categoryPath.length - 1
    }
  }
})

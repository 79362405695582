













import { mapState } from 'vuex'
import CAdTarget from '~/components/shared/configurable/ad/target/CAdTarget.vue'
import CAdContainer from '~/components/shared/configurable/ad/container/CAdContainer.vue'
import { GoogleAd, placeholderProp } from '~/models/ads'
import { KeyValue } from '~/models/common/types'
import AdViewService from '~/services/ads/AdViewService'
import { USER_NS } from '~/store/modules/shared/user/state'
import GoogleAdsService from '~/services/ads/GoogleAdsService'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { InHouseSizes } from '~/constants/ads/inhouse'

export default defineComponent({
  components: { CAdContainer, CAdTarget },
  props: {
    ad: {
      type: Object as PropType<GoogleAd>,
      required: true
    },
    keyValues: {
      type: Array as PropType<KeyValue[]>,
      required: false,
      default() {
        return []
      }
    },
    placeholder: placeholderProp,
    lazy: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean,
      default: false
    },
    divCollapse: {
      type: Boolean,
      default: false
    },
    inhouseUnit: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      slot: null,
      createOnNextActivate: false,
      showQuickAdTrigger: false
    }
  },
  computed: {
    ...mapState(USER_NS, {
      userType: state => state.type
    }),
    adViewService(): AdViewService {
      return this.$dep(AdViewService)
    },
    googleAdsService(): GoogleAdsService {
      return this.$dep(GoogleAdsService)
    },
    containerSize() {
      const { ad, adViewService } = this
      return adViewService.getMaxSizeDimensions(ad.sizes)
    },
    internalKeyValues(): KeyValue[] {
      return [
        ...this.keyValues,
        ...[{ key: 'user-type', value: this.userType }]
      ]
    },
    sizeMapping() {
      const { sizesMappings } = this.ad
      return sizesMappings || []
    },
    supportsQuickAd() {
      return Boolean(InHouseSizes[this.inhouseUnit])
    },
    queryItem() {
      return this.$route.query.item
    },
    queryUnit() {
      return this.$route.query.unit
    },
    queryCancel() {
      return this.$route.query.cancel
    },
    queryForceAd() {
      return this.$route.query.force_ad
    }
  },
  deactivated() {
    if (this.slot) {
      this.createOnNextActivate = true
      this.destroyAd()
    }
  },
  beforeDestroy() {
    if (this.slot) {
      this.destroyAd()
    }
  },
  activated() {
    if (this.createOnNextActivate) {
      this.createAd()
      this.createOnNextActivate = false
    }
  },
  mounted() {
    this.$emit('mounted')

    if (
      this.queryUnit === this.inhouseUnit &&
      ((this.queryItem && this.queryCancel && !this.$route.query.success) ||
        this.queryForceAd)
    ) {
      this.showQuickAdTrigger = true
      this.$refs.adContainerRef.triggerQuickAdModal()
      return
    }
    this.createAd()

    const pubAds = this.googleAdsService.getPubAds()
    if (pubAds) {
      pubAds.addEventListener('slotRenderEnded', event => {
        if (event.slot.getSlotElementId() === this.slot?.getSlotElementId()) {
          if (event.isEmpty) {
            this.showQuickAdTrigger = true
          } else {
            this.showQuickAdTrigger = false
          }
        }
      })
    }
  },
  methods: {
    destroyAd() {
      this.googleAdsService.destroySlots([this.slot])
      this.slot = null
    },
    createAd() {
      this.showQuickAdTrigger = false // reset it just in case this comes from reactivate hook
      const { googleAdsService } = this
      const { id, path, sizes, sizesMappings } = this.ad

      if (this.divCollapse) {
        googleAdsService.setCollapseEmptyDivs()
      }
      googleAdsService.enqueueCommand(() => {
        try {
          this.slot = googleAdsService.defineSlot(path, sizes, id)
          if (this.slot) {
            if (sizesMappings) {
              googleAdsService.defineSlotSizeMapping(this.slot, sizesMappings)
            }
            googleAdsService.setTargeting(this.slot, this.internalKeyValues)
            googleAdsService.prepareAndDisplaySlot(this.slot, id)
          }
        } catch (_e) {
          this.showQuickAdTrigger = true
        }
      })
    },
    refresh() {
      this.showQuickAdTrigger = false
      this.googleAdsService.refreshSlots([this.slot])
    }
  }
})

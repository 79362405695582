var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.badge)?_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
    content: _vm.tooltipString,
    disabled: !_vm.tooltipString
  }),expression:"{\n    content: tooltipString,\n    disabled: !tooltipString\n  }",modifiers:{"hover":true}}],staticClass:"tw-flex tw-items-center tw-select-none tw-pl-[0.35rem] tw-pr-2 tw-py-0.5 tw-text-base tw-rounded-2xl tw-gap-1",class:[_vm.textColorClass, _vm.bgClass]},[(_vm.icon)?_c('CIcon',{staticClass:"tw-text-white",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(_vm.actor
      ? _vm.$t(("ad_status_" + _vm.status + "_by"), { actor: _vm.actor.displayName })
      : _vm.$t(("ad_status_" + _vm.status))))])],1):_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip.hover",value:({
    content: _vm.tooltipString,
    disabled: !_vm.tooltipString
  }),expression:"{\n    content: tooltipString,\n    disabled: !tooltipString\n  }",modifiers:{"hover":true}}],staticClass:"tw-flex tw-items-center tw-gap-1",class:[_vm.textColorClass, _vm.fontWeightClass]},[(_vm.icon)?_c('CIcon',{attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n    "+_vm._s(_vm.actor
        ? _vm.$t(("ad_status_" + _vm.status + "_by"), { actor: _vm.actor.displayName })
        : _vm.$t(("ad_status_" + _vm.status)))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export function useTime() {
  function formatTimeToDoubleDigit(time: number) {
    const timeAsStr = time.toString()
    if (timeAsStr.length === 1) {
      // Transforms "0" to "00"
      return `0${timeAsStr}`
    }
    return timeAsStr
  }

  function extractTimeFromDatetimeString(datetimeStr: string): string {
    return datetimeStr.split(' ')[1]
  }

  // Accepts time string as 'HH:mm'
  function extractTimeFromTimeString(timeStr: string, index: number): number {
    try {
      const time = timeStr.split(':')[index]
      return Number(time)
    } catch (_err) {
      return 0
    }
  }

  function extractHourFromTimeString(timeStr: string): number {
    return extractTimeFromTimeString(timeStr, 0)
  }

  function extractMinuteFromTimeString(timeStr: string): number {
    return extractTimeFromTimeString(timeStr, 1)
  }

  function extractHoursAndMinutesFromTimeString(timeStr: string): number[] {
    const [hours, minutes] = timeStr.split(':')
    return [hours, minutes].map(Number)
  }

  return {
    formatTimeToDoubleDigit,
    extractHourFromTimeString,
    extractMinuteFromTimeString,
    extractHoursAndMinutesFromTimeString,
    extractTimeFromDatetimeString
  }
}

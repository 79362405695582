import {
  SET_AD_VALUE,
  UPDATE_CAMPAIGN_AD_VALUE
} from '~/store/modules/shared/advertising/form/mutation-types'
import { useNamespacedStore } from '~/compositions/store'
import {
  ADVERTISE_FORM_NS,
  AdvertiseFormState
} from '~/store/modules/shared/advertising/form/state'
import { computed, useRoute } from '~/utils/nuxt3-migration'
import { SchemaField } from '~/models/form/types'
import { useQuickAdForm } from '~/compositions/advertise/quick-ad-form'
import { toCamel } from '~/utils/object'

export function useAdForm() {
  const { state, commit, getters } = useNamespacedStore<AdvertiseFormState>(
    ADVERTISE_FORM_NS
  )

  const route = useRoute()

  const {
    emitParams: quickEmitParams,
    getSchemaField: getQuickSchemaField,
    daysBetween: quickDaysBetween,
    errors: quickErrors,
    values: quickValues,
    inQuickForm
  } = useQuickAdForm()

  const newAdSchema = computed(() => state.newAdSchema)
  const campaignAdSchema = computed(
    () => getters('getCampaignAd')(route.value.params?.ad)?.schema || []
  )

  const editMode = computed(() => Boolean(route.value.params?.ad))

  const newAdValues = computed(() => state.newAdValues)
  const editAdValues = computed(
    () => getters('getCampaignAd')(route.value.params?.ad)?.values
  )
  const adValues = computed(() => {
    if (inQuickForm.value) {
      return quickValues.value
    }
    return editMode.value ? editAdValues.value : newAdValues.value
  })

  const schema = computed(() =>
    editMode.value ? campaignAdSchema.value : newAdSchema.value
  )

  const emitParams = (name: string, value: any) => {
    if (inQuickForm.value) {
      quickEmitParams(name, value)
      return
    } else if (editMode.value) {
      commit(UPDATE_CAMPAIGN_AD_VALUE, {
        id: route.value.params?.ad,
        adFormValue: { name, value }
      })
      return
    }
    commit(SET_AD_VALUE, { name, value })
  }

  const getSchemaField = (name: string) => {
    if (inQuickForm.value) {
      return getQuickSchemaField(toCamel(name))
    }
    return schema.value.find((s: SchemaField) => s.name === name)
  }

  const daysBetween = computed(() => {
    if (inQuickForm.value) {
      return quickDaysBetween.value
    }

    return editMode.value
      ? getters('daysBetweenForAdId')(route.value.params.ad)
      : getters('daysBetween')
  })

  const loading = computed(() => state.loading)
  const newAdErrors = computed(() => state.newAdErrors)
  const editAdErrors = computed(
    () => getters('getCampaignAd')(route.value.params?.ad)?.errors || {}
  )
  const errors = computed(() => {
    if (inQuickForm.value) {
      return quickErrors.value
    }
    return editMode.value ? editAdErrors.value : newAdErrors.value
  })

  const currentAd = computed(() => {
    if (route.value.params?.ad) {
      return getters('getCampaignAd')(route.value.params?.ad)?.ad || null
    }
    return null
  })

  return {
    loading,
    errors,
    emitParams,
    daysBetween,
    getSchemaField,
    editMode,
    adValues,
    currentAd
  }
}

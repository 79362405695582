import { CIcon } from '~/icons/types'

export const ciBarcode: CIcon = {
  width: '24',
  height: '21',
  paths: [
    {
      d:
        'M22 14.7c-.8 0-1.5.7-1.5 1.5v1.5c0 .1-.1.2-.2.2h-1.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h1.5c1.8 0 3.2-1.4 3.2-3.2v-1.5c0-.9-.7-1.5-1.5-1.5ZM5.2 17.9H3.7c-.1 0-.2-.1-.2-.2v-1.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v1.5c0 1.8 1.4 3.2 3.2 3.2h1.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5ZM2 7.098c.8 0 1.5-.7 1.5-1.5v-1.5c0-.1.1-.2.2-.2h1.5c.8 0 1.5-.7 1.5-1.5S6 .898 5.2.898H3.7c-1.8 0-3.2 1.4-3.2 3.2v1.5c0 .9.7 1.5 1.5 1.5Zm18.3-6.2h-1.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h1.5c.1 0 .2.1.2.2v1.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-1.5c0-1.7-1.4-3.2-3.2-3.2ZM6.6 5C5.7 5 5 5.7 5 6.6v8.8c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6V6.6C8.1 5.7 7.4 5 6.6 5Zm3.7 0c-.6 0-1 .5-1 1v9.8c0 .6.5 1 1 1 .6 0 1-.5 1-1V6c.1-.5-.4-1-1-1Zm3.8 0c-.9 0-1.6.7-1.6 1.6v8.8c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6V6.6c0-.9-.7-1.6-1.6-1.6Zm4.8 10.9V6c0-.6-.5-1-1-1-.6 0-1 .5-1 1v9.8c0 .6.5 1 1 1 .6.1 1-.4 1-.9Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'barcode',
  type: 'solid'
}


















import {
  Category,
  CategoryId,
  HierarchicalCategory
} from '~/models/category/types'
import Tree from '~/models/shared/tree/Tree'
import CTreeSelect from '~/components/shared/configurable/form/select/tree/CTreeSelect.vue'
import { ClassifiedCategory } from '~/models/classified/category/types'
import HierarchicalCategoryService from '~/services/category/HierarchicalCategoryService'
import {
  defineComponent,
  PropType,
  vue3Model,
  ref,
  computed,
  watch,
  onMounted
} from '~/utils/nuxt3-migration'
import { TreeOption } from '~/models/shared/types'
import { useDeps } from '~/compositions/dependency-container'

export default defineComponent({
  model: vue3Model,
  components: {
    CTreeSelect
  },
  props: {
    rootIds: {
      type: Array as PropType<number[]>,
      default() {
        return [
          CategoryId.VEHICLES,
          CategoryId.PLOT,
          CategoryId.JOBS,
          CategoryId.XYMA,
          CategoryId.PARTS
        ]
      }
    },
    rootCategory: {
      type: Number as PropType<CategoryId>,
      default: CategoryId.CLASSIFIEDS
    },
    headerClass: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Array as PropType<number[]>,
      default() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    bodyClasses: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * it stops children expansion for children that have parent id included in this list
     */
    parentsLimit: {
      type: Array,
      default() {
        return []
      }
    },
    categoriesCounts: {
      type: Object as PropType<Record<CategoryId, number>>,
      default: null
    },
    hideZeroCountOptions: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array as PropType<HierarchicalCategory>,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, listeners }) {
    const [hierarchicalCategoryService] = useDeps(HierarchicalCategoryService)

    const categoriesMap = ref<Map<string, HierarchicalCategory>>(new Map())
    const expandedCategoryIds = ref<Set<string>>(new Set())
    const queriedCategoryIds = ref<Set<string>>(new Set())
    const selectedCategoryIds = ref<number[]>(props.modelValue || [])
    const internalLoading = ref(props.loading)

    watch(
      () => props.modelValue,
      (value: number[]) => {
        selectedCategoryIds.value = [
          ...new Set([...getSelectedAncestorIds(value), ...value])
        ]
        selectCategories(selectedCategoryIds.value)
        if (!props.categories) {
          getCategories([...expandedCategoryIds.value])
        }
      }
    )

    watch(
      () => props.categories,
      (value: HierarchicalCategory[]) => {
        if (value) {
          updateCategoriesMap(
            hierarchicalCategoryService.createHierarchicalCategoryMap(value)
          )
        }
      }
    )

    watch(
      () => props.loading,
      (value: boolean) => {
        internalLoading.value = value
      }
    )

    onMounted(async () => {
      if (props.categories) {
        updateCategoriesMap(
          hierarchicalCategoryService.createHierarchicalCategoryMap(
            props.categories
          )
        )
      } else {
        await getCategories(
          [...new Set([...selectedCategoryIds.value, ...props.rootIds])].map(
            String
          )
        )
      }

      selectedCategoryIds.value = [
        ...new Set([
          ...getSelectedAncestorIds(selectedCategoryIds.value),
          ...selectedCategoryIds.value
        ])
      ]

      selectCategories(selectedCategoryIds.value)
    })

    const categoryTree = computed(() => new Tree(categoriesMap.value))

    const options = computed<TreeOption[]>(() => {
      const rootIds = new Set([...props.rootIds].map(String))
      const shownIds = [
        ...rootIds,
        ...[...categoriesMap.value.values()]
          .filter(categoryHasEveryAncestorExpanded)
          .filter(c => {
            const isRootCategory = c.parentId === props.rootCategory.toString()

            // Exclude root categories that are not in rootIds
            return !(isRootCategory && !props.rootIds.includes(c.id))
          })
          .map(c => c.id)
      ]

      const expanded = new Map()

      shownIds.forEach(id => {
        if (!categoriesMap.value.has(id)) {
          return
        }

        expanded.set(id, categoriesMap.value.get(id))
      })

      return treeSelectOptionsFromCategoryTree(new Tree(expanded))
    })

    function treeSelectOptionsFromCategoryTree(
      tree: Tree<Category | ClassifiedCategory>
    ): TreeOption[] {
      const getOption: any = function(category: Category | ClassifiedCategory) {
        const children = tree.getChildren(category.id)

        const value = Number(category.id)
        const count = props.categoriesCounts?.[value] || null

        return {
          name: category.humanName,
          value,
          count,
          children: children && children.map(c => getOption(c))
        }
      }
      const roots = tree.getRoots()
      return roots.map(r => getOption(r))
    }

    function categoryHasEveryAncestorExpanded(category: ClassifiedCategory) {
      return categoryTree.value
        .getAncestors(category.id)
        .every((c: ClassifiedCategory) => expandedCategoryIds.value.has(c.id))
    }

    function getSelectedAncestorIds(selectedIds: number[]): Set<number> {
      const selectedAncestorIds = new Set<number>()

      selectedIds.forEach(v => {
        const c: HierarchicalCategory = categoriesMap.value.get(String(v))
        if (c?.ancestorIds) {
          c.ancestorIds.forEach(
            ancestorId =>
              categoriesMap.value.has(ancestorId) &&
              selectedAncestorIds.add(Number(ancestorId))
          )
        }
      })

      return selectedAncestorIds
    }

    function categoryHasEveryAncestorSelected(id: string): boolean {
      return categoryTree.value
        .getAncestors(id)
        .every((c: ClassifiedCategory) =>
          selectedCategoryIds.value.some((id: string) => id === c.id)
        )
    }

    function selectCategories(ids: number[]) {
      expandedCategoryIds.value = ids.reduce(
        (expanded: Set<string>, id: number) => {
          const c: HierarchicalCategory = categoriesMap.value.get(String(id))
          if (!c) {
            return expanded
          }

          const expandable =
            (c.childrenIds && c.childrenIds.length) || c.childrenCanBeRetrieved
          if (expandable) {
            expanded.add(c.id)
          }

          return expanded
        },
        new Set()
      )

      selectedCategoryIds.value = ids.filter(categoryHasEveryAncestorSelected)
    }

    function onChange(ids: number[]) {
      selectCategories(ids)

      if (!props.categories) {
        getCategories([...expandedCategoryIds.value])
      }

      emit('update:modelValue', selectedCategoryIds.value)
      emit('change', selectedCategoryIds.value)

      if (listeners?.['input-children']) {
        const ancestorIds = Array.from(
          getSelectedAncestorIds(selectedCategoryIds.value)
        )
        const childrenCategories = selectedCategoryIds.value.filter(
          (sc: number) => !ancestorIds.includes(sc)
        )
        emit('input-children', childrenCategories)
      }
    }

    async function getCategories(ids: string[]) {
      const queryableIds = ids.filter(id => !queriedCategoryIds.value.has(id))
      if (!queryableIds.length) {
        return
      }

      internalLoading.value = true

      const loadedCategories = await hierarchicalCategoryService.getCategoryMap(
        queryableIds,
        props.rootCategory,
        props.parentsLimit
      )

      updateCategoriesMap(loadedCategories)

      internalLoading.value = false

      queryableIds.forEach(id => queriedCategoryIds.value.add(id))
    }

    function updateCategoriesMap(
      providedCategories: Map<string, HierarchicalCategory>
    ) {
      const categoriesMapCopy = new Map([...categoriesMap.value.entries()])

      providedCategories.forEach((l: HierarchicalCategory) => {
        const category = categoriesMapCopy.get(l.id) as HierarchicalCategory

        const childrenExist =
          category?.childrenIds && (!l.childrenIds || !l.childrenIds.length)
        if (childrenExist) {
          l.childrenIds = category.childrenIds
        }

        categoriesMapCopy.set(l.id, l)
      })

      categoriesMap.value = categoriesMapCopy
    }

    return {
      internalLoading,
      options,
      selectedCategoryIds,
      onChange
    }
  }
})

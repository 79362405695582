



















import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs
} from '~/utils/nuxt3-migration'
import { useTime } from '~/compositions/datetime/time'
import { useDeps } from '~/compositions/dependency-container'
import ScrollService from '~/services/scroll/ScrollService'

export default defineComponent({
  props: {
    times: {
      type: Array as PropType<number[]>,
      default() {
        return []
      }
    },
    selectedTime: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const { selectedTime, times } = toRefs(props)
    const timesTemplateRef = ref<HTMLElement[] | null>(null)
    const timesContainerTemplateRef = ref<HTMLElement[] | null>(null)

    const { formatTimeToDoubleDigit } = useTime()
    const [scrollService] = useDeps(ScrollService)

    function formatTime(time: number): string {
      return formatTimeToDoubleDigit(time)
    }

    onMounted(() => {
      updateSelectedItemScrollPosition()
    })

    function handleTimeClick(time: number, index: number): void {
      scrollToItemByIndex(index)
      emit('select', time)
    }

    function scrollToItemByIndex(index: number) {
      if (!timesTemplateRef.value || !timesContainerTemplateRef.value) {
        return
      }
      const timeElement = timesTemplateRef.value[index]
      scrollService.scrollIfOverflownVertical(
        timeElement,
        timesContainerTemplateRef.value
      )
    }

    function updateSelectedItemScrollPosition() {
      if (!timesTemplateRef.value || !timesContainerTemplateRef.value) {
        return
      }

      const selectedTimeIndex = times.value?.findIndex(
        (t: number) => t === selectedTime.value
      )
      scrollToItemByIndex(selectedTimeIndex)
    }

    function timeIsSelected(time: number): boolean {
      return time === selectedTime.value
    }

    return {
      formatTime,
      handleTimeClick,
      timesTemplateRef,
      timeIsSelected,
      timesContainerTemplateRef,
      updateSelectedItemScrollPosition
    }
  }
})

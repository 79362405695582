

























import { defineComponent, ref } from '~/utils/nuxt3-migration'
import AdImageInput from '~/components/shared/advertising/forms/AdImageInput.vue'
import { useAdItemForm } from '~/compositions/advertise/ad-item-form'

export default defineComponent({
  components: { AdImageInput },
  setup() {
    const showUploadModal = ref(false)

    const { getSchemaField } = useAdItemForm()
    return {
      getSchemaField,
      showUploadModal
    }
  }
})

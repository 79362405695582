













































import { computed, defineComponent, ref, watch } from '~/utils/nuxt3-migration'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import { useAdItemForm } from '~/compositions/advertise/ad-item-form'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { ciGlobe } from '~/icons/source/regular/globe'
import { ciX } from '~/icons/source/brand/x'
import { useQuickAdForm } from '~/compositions/advertise/quick-ad-form'
import { ciYoutube } from '~/icons/source/solid/youtube'

export default defineComponent({
  components: {
    CFormError
  },
  props: {},
  setup() {
    const {
      getSchemaField,
      emitParams,
      errors,
      adItemValues,
      schema,
      disableInputs,
      lockedMode
    } = useAdItemForm()

    const getTargetLink = () => {
      return adItemValues.value.targetLink || ''
    }

    const { inQuickForm } = useQuickAdForm()
    const targetLink = ref(getTargetLink())

    const adItemTargetLink = computed(() => adItemValues.value.targetLink)

    watch(adItemTargetLink, _val => {
      targetLink.value = getTargetLink()
    })

    const recommendedUrls = computed(() => {
      const schemaUrls = getSchemaField('target_link').recommendedUrls

      const urls = []

      if (schemaUrls?.internalWebsite) {
        urls.push({
          platform: 'website',
          link: schemaUrls.internalWebsite
        })
      }

      if (schemaUrls?.websites) {
        const websites = schemaUrls.websites.map(w => {
          return {
            platform: 'website',
            link: w
          }
        })
        urls.push(...websites)
      }

      if (schemaUrls?.social) {
        const socials = Object.keys(schemaUrls.social).map(key => {
          return {
            platform: key,
            link: schemaUrls.social[key].links[0]
          }
        })

        urls.push(...socials)
      }

      return urls
    })

    const urlClicked = (link: string) => {
      targetLink.value = link
      emitParams('targetLink', link)
    }

    const getLinkIcon = (platform: string) => {
      switch (platform) {
        case 'facebook': {
          return faFacebook
        }
        case 'twitter': {
          return ciX
        }
        case 'instagram': {
          return faInstagram
        }
        case 'youtube': {
          return ciYoutube
        }
        default: {
          return ciGlobe
        }
      }
    }

    const getIconCLasses = (platform: string) => {
      switch (platform) {
        case 'facebook': {
          return 'tw-text-facebook'
        }
        case 'twitter': {
          return 'tw-text-x'
        }
        case 'instagram': {
          return 'tw-text-instagram'
        }
        case 'youtube': {
          return 'tw-text-youtube'
        }
        default: {
          return 'tw-text-grey-600'
        }
      }
    }

    return {
      getSchemaField,
      errors,
      schema,
      recommendedUrls,
      disableInputs,
      emitParams,
      targetLink,
      lockedMode,
      urlClicked,
      getLinkIcon,
      getIconCLasses,
      inQuickForm
    }
  }
})



























































































































import {
  computed,
  defineComponent,
  nextTick,
  ref,
  useRoute,
  watch
} from '~/utils/nuxt3-migration'
import CDatePicker from '~/components/shared/configurable/date/CDatePicker.vue'
import { useAdForm } from '~/compositions/advertise/ad-form'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import CTimeInput from '~/components/shared/configurable/datetime/CTimeInput.vue'
import CRadioListEntry from '~/components/shared/configurable/form/radio/CRadioListEntry.vue'
import OptionsOrCustomInput from '~/components/shared/advertising/forms/OptionsOrCustomInput.vue'
import { useNamespacedStore } from '~/compositions/store'
import {
  ADVERTISE_DASHBOARD_NS,
  AdvertiseDashboardState
} from '~/store/modules/shared/advertising/dashboard/state'
import AdBalanceTopUpTrigger from '~/components/shared/advertising/forms/AdBalanceTopUpTrigger.vue'
import {
  ADVERTISE_FORM_NS,
  AdvertiseFormState
} from '~/store/modules/shared/advertising/form/state'
import CPrice from '~/components/shared/configurable/CPrice.vue'
import { useQuickAdForm } from '~/compositions/advertise/quick-ad-form'
import { ciExclamationTriangle } from '~/icons/source/solid/exclamation-triangle'

export default defineComponent({
  components: {
    CPrice,
    AdBalanceTopUpTrigger,
    OptionsOrCustomInput,
    CRadioListEntry,
    CTimeInput,
    CFormError,
    CDatePicker
  },
  setup() {
    const {
      getSchemaField,
      emitParams,
      loading,
      editMode,
      adValues,
      currentAd,
      errors,
      daysBetween
    } = useAdForm()

    const { inQuickForm } = useQuickAdForm()

    const route = useRoute()

    const { state } = useNamespacedStore<AdvertiseDashboardState>(
      ADVERTISE_DASHBOARD_NS
    )
    const { dispatch } = useNamespacedStore<AdvertiseFormState>(
      ADVERTISE_FORM_NS
    )
    const balance = computed(() => state.balance) // its fetched from index.vue

    const dailyBudgetOptions = computed(() => {
      return [
        {
          text: '1 €',
          value: 1
        },
        {
          text: '2 €',
          value: 2
        },
        {
          text: '5 €',
          value: 5
        },
        {
          text: '10 €',
          value: 10
        },
        {
          text: '20 €',
          value: 20
        }
      ]
    })

    const getBudgetType = () => {
      return editMode.value ? adValues.value.budgetType : 'daily'
    }
    const type = ref(getBudgetType())

    const getDailyBudget = () => {
      if (editMode.value) {
        return type.value === 'daily'
          ? Number(
              (Number(adValues.value.budget) / daysBetween.value).toFixed(2)
            )
          : Number(Number(adValues.value.budget).toFixed(2))
      }
      return dailyBudgetOptions.value[0].value
    }

    const dailyBudget = ref(getDailyBudget())

    const lifetimeBudgetOptions = computed(() => {
      if (inQuickForm.value) {
        return dailyBudgetOptions.value
      }
      return [
        {
          text: '20 €',
          value: 20
        },
        {
          text: '50 €',
          value: 50
        },
        {
          text: '100 €',
          value: 100
        },
        {
          text: '200 €',
          value: 200
        }
      ]
    })

    const getLifetimeBudget = () => {
      return editMode.value
        ? adValues.value.budget
        : lifetimeBudgetOptions.value[0].value
    }
    const lifetimeBudget = ref(getLifetimeBudget())

    watch(adValues, val => {
      if (val.budget !== dailyBudget.value) {
        dailyBudget.value = getDailyBudget()
      }
      if (val.budget !== lifetimeBudget.value) {
        lifetimeBudget.value = getLifetimeBudget()
      }

      if (val.budgetType !== type.value) {
        type.value = getBudgetType()
      }
    })
    const budgetToEmit = computed(() => {
      if (daysBetween.value > 0) {
        if (inQuickForm.value) {
          // lifetimeBudget here acts like daily (kind of a hack)
          return Number((daysBetween.value * lifetimeBudget.value).toFixed(2))
        }
        if (type.value === 'daily') {
          return Number((daysBetween.value * dailyBudget.value).toFixed(2))
        }
      }
      return Number(Number(lifetimeBudget.value).toFixed(2))
    })

    emitParams('budgetType', type.value)
    emitParams('budget', budgetToEmit.value)

    watch(type, newVal => {
      if (newVal === 'daily') {
        dailyBudget.value = dailyBudgetOptions.value[0].value
      } else if (newVal === 'lifetime') {
        lifetimeBudget.value = lifetimeBudgetOptions.value[0].value
      }
      emitParams('budgetType', type.value)
      emitParams('budget', budgetToEmit.value)
    })

    watch(daysBetween, async () => {
      await nextTick()
      emitParams('budget', budgetToEmit.value)
    })

    const min = computed(() => {
      return getSchemaField('budget').min
    })
    const max = computed(() => {
      return getSchemaField('budget').max
    })

    const currentSpend = computed(() => {
      return currentAd.value?.currentSpend || 0
    })

    const onTopUpClick = async () => {
      await dispatch(
        route.value.name === '__advertising_campaign_ad_edit'
          ? 'updateAd'
          : 'postNewAd',
        true
      )
    }

    const budgetChanged = computed(() => {
      return currentAd.value?.budget !== budgetToEmit.value
    })

    const showBalanceNotEnough = computed(() => {
      if (inQuickForm.value) {
        return false
      }
      return (
        (!currentAd.value || budgetChanged.value) &&
        (balance.value === 0 ||
          parseFloat(balance.value).toPrecision(14) <
            budgetToEmit.value - parseFloat(currentSpend.value).toPrecision(14))
      )
    })

    return {
      type,
      getSchemaField,
      loading,
      errors,
      dailyBudget,
      dailyBudgetOptions,
      lifetimeBudget,
      lifetimeBudgetOptions,
      emitParams,
      budgetToEmit,
      adValues,
      currentAd,
      currentSpend,
      min,
      max,
      daysBetween,
      ciExclamationTriangle,
      balance,
      onTopUpClick,
      showBalanceNotEnough,
      budgetChanged,
      inQuickForm
    }
  }
})

export function modifyUrl(url: string, newQueries: { [key: string]: any }) {
  const urlObj = new URL(url)
  const params = new URLSearchParams(urlObj.search)

  // Update or add new queries
  Object.keys(newQueries).forEach((key: string) => {
    params.set(key, newQueries[key]) // `set` will add or replace the query
  })

  // Update the URL with modified queries
  urlObj.search = params.toString()

  return urlObj.toString()
}

export function removeQueries(url: string, keysToRemove: string[]) {
  const urlObj = new URL(url)
  const params = new URLSearchParams(urlObj.search)

  keysToRemove.forEach(key => params.delete(key))

  urlObj.search = params.toString()
  return urlObj.toString()
}

import { CIcon } from '~/icons/types'

export const ciShare: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M18 9.6a3.6 3.6 0 1 0-3.573-3.155L8.5 9.409a3.6 3.6 0 1 0 0 5.182l5.928 2.964a3.6 3.6 0 1 0 1.074-2.146l-5.928-2.964a3.639 3.639 0 0 0 0-.89L15.5 8.591A3.588 3.588 0 0 0 18 9.6Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'share',
  type: 'solid'
}

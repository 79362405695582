
































































import {
  computed,
  defineComponent,
  ref,
  watch,
  vue3Model
} from '~/utils/nuxt3-migration'
import { range } from '~/utils/array'
import CTimeInputTimeList from '~/components/shared/configurable/datetime/CTimeInputTimeList.vue'
import { useTime } from '~/compositions/datetime/time'
import CDropdown from '~/components/shared/configurable/dropdown/CDropdown.vue'
import CInput from '~/components/shared/configurable/form/input/CInput.vue'
import { ciClock } from '~/icons/source/regular/clock'

export default defineComponent({
  model: vue3Model,
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: '00:00'
    }
  },
  components: { CTimeInputTimeList },
  setup(props, { emit }) {
    const {
      formatTimeToDoubleDigit,
      extractHourFromTimeString,
      extractMinuteFromTimeString
    } = useTime()

    const inputTemplateRef = ref<typeof CInput | null>()
    const dropdownTemplateRef = ref<typeof CDropdown | null>()
    const hoursListTemplateRef = ref<typeof CTimeInputTimeList | null>()
    const minutesListTemplateRef = ref<typeof CTimeInputTimeList | null>()

    const inputText = ref(props.modelValue)
    const selectedHour = ref(extractHourFromTimeString(props.modelValue))
    const selectedMinute = ref(extractMinuteFromTimeString(props.modelValue))
    const lastValidHour = ref(extractHourFromTimeString(props.modelValue))
    const lastValidMinute = ref(extractMinuteFromTimeString(props.modelValue))

    watch(
      () => props.modelValue,
      newValue => {
        inputText.value = newValue
        selectedHour.value = extractHourFromTimeString(newValue)
        selectedMinute.value = extractMinuteFromTimeString(newValue)
        lastValidHour.value = extractHourFromTimeString(newValue)
        lastValidMinute.value = extractMinuteFromTimeString(newValue)
      }
    )

    const hours = computed(() => range(0, 23))
    const minutes = computed(() => range(0, 59, 5))

    function handleInvalidInput() {
      selectedHour.value = lastValidHour.value
      selectedMinute.value = lastValidMinute.value
      updateInputText()
      emitValues()
    }

    function setSelectedHour(hour: string | number) {
      selectedHour.value = Number(hour)
      lastValidHour.value = Number(hour)
    }

    function setSelectedMinute(minute: string | number) {
      selectedMinute.value = Number(minute)
      lastValidMinute.value = Number(minute)
    }

    function emitValues() {
      emit('update:modelValue', createFormattedTimes())
    }

    function handleInputFocusOut() {
      if (!inputText.value) {
        return
      }

      const hourAndMinute = inputText.value.split(':')
      if (hourAndMinute.length !== 2) {
        handleInvalidInput()
      }
      const [hour, minute] = hourAndMinute

      function timeStringIsValid(
        time: string,
        from: number,
        to: number
      ): boolean {
        if (time.length !== 2) {
          return false
        }
        const timeAsNum = Number(time)
        if (isNaN(timeAsNum)) {
          return false
        }

        return timeAsNum <= to && timeAsNum >= from
      }

      if (timeStringIsValid(hour, 0, 23) && timeStringIsValid(minute, 0, 59)) {
        setSelectedHour(hour)
        setSelectedMinute(minute)
        emitValues()
      } else {
        handleInvalidInput()
      }
    }

    function updateInputText() {
      inputText.value = createFormattedTimes()
    }

    function createFormattedTimes() {
      return `${formatTimeToDoubleDigit(
        selectedHour.value
      )}:${formatTimeToDoubleDigit(selectedMinute.value)}`
    }

    function handleHourSelect(hour: number) {
      setSelectedHour(hour)
      selectedHour.value = hour
      if (!selectedMinute.value) {
        selectedMinute.value = 0
      }
      updateInputText()
      emitValues()
    }

    function handleMinuteSelect(minute: number) {
      setSelectedMinute(minute)
      if (!selectedHour.value) {
        selectedHour.value = 0
      }
      updateInputText()
      emitValues()
    }

    function handleOkButtonClick() {
      dropdownTemplateRef.value!.hide()
      emitValues()
    }

    function handleEnterPress() {
      dropdownTemplateRef.value!.hide()
      emitValues()
    }

    function handleDropdownShown() {
      if (!hoursListTemplateRef.value || !minutesListTemplateRef.value) {
        return
      }
      hoursListTemplateRef.value.updateSelectedItemScrollPosition()
      minutesListTemplateRef.value.updateSelectedItemScrollPosition()
    }

    return {
      hours,
      minutes,
      ciClock,
      handleInputFocusOut,
      handleHourSelect,
      handleMinuteSelect,
      inputTemplateRef,
      inputText,
      selectedHour,
      selectedMinute,
      dropdownTemplateRef,
      handleOkButtonClick,
      handleEnterPress,
      handleDropdownShown,
      hoursListTemplateRef,
      minutesListTemplateRef
    }
  }
})





























































































































import { computed, defineComponent, ref, watch } from '~/utils/nuxt3-migration'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import { useAdItemForm } from '~/compositions/advertise/ad-item-form'
import { arraysEqual } from '~/utils/array'
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { AdItemUnitEntry } from '~/models/advertising/types'
import CImg from '~/components/shared/configurable/image/CImg.vue'
import { useI18n } from '~/compositions/i18n'
import AdUnitSizeString from '~/components/shared/advertising/AdUnitSizeString.vue'
import { getUnitIconType } from '~/utils/inhouse'
import { ciAngleRight } from '~/icons/source/solid/angle-right'

export default defineComponent({
  components: {
    AdUnitSizeString,
    CImg,
    CFormError
  },
  props: {},
  setup() {
    const {
      getSchemaField,
      emitParams,
      errors,
      adItemValues,
      schema,
      loading,
      disableInputs
    } = useAdItemForm()

    const { t } = useI18n()

    const unitOptions = computed(() => {
      const options = getSchemaField('placement_units')?.options
      if (options) {
        Object.keys(options).forEach(key => {
          options[key] = options[key].map(u => {
            return {
              ...u,
              label: t(`${u.value}_description`),
              iconType: getUnitIconType(u.value)
            }
          })
        })
        return options
      }
      return null
    })

    const flattenedUnitOptions = computed(() => {
      if (!unitOptions.value) {
        return []
      }
      return Object.keys(unitOptions.value)
        .map(key => unitOptions.value[key])
        .flat()
    })

    const selectedCategories = computed(
      () => adItemValues.value.placementContext?.categories || []
    )

    const getUnitSection = (unitValue: string) => {
      return Object.keys(unitOptions.value).find(key =>
        unitOptions.value[key]?.find(
          (u: AdItemUnitEntry) => u.value === unitValue
        )
      )
    }

    const isUnitDisabled = (u: AdItemUnitEntry | string) => {
      let unit = u
      if (typeof u === 'string') {
        unit = flattenedUnitOptions.value.find(fu => fu.value === u)
      }
      if (
        !unit ||
        !unitOptions.value ||
        (selectedCategories.value.length &&
          getUnitSection(unit.value) === 'general')
      ) {
        return true
      }

      if (adItemValues.value.designType?.startsWith('template')) {
        return false
      }

      const bannersExist = unit.banners.some(b => adItemValues.value[b])

      return !bannersExist
    }

    const getPlacementUnits = () => {
      if (adItemValues.value?.placementUnits?.length) {
        const filtered = adItemValues.value.placementUnits.filter(u => {
          return !isUnitDisabled(u)
        })
        return filtered
      }
      return []
    }
    const selectedUnits = ref(getPlacementUnits())

    const isUnitSelected = (unit: string) => {
      return selectedUnits.value.includes(unit)
    }

    const extendedSections = ref<string[]>(
      unitOptions.value ? Object.keys(unitOptions.value) : []
    )

    const designType = computed(() => adItemValues.value.designType)
    watch(designType, (val: string, oldVal: string) => {
      if (val !== oldVal) {
        flattenedUnitOptions.value.forEach(unit => {
          // if template enable them all, else disable them all cause we are just back to custom
          onUnitChange(unit, Boolean(val?.startsWith('template')), false)
        })

        emitSelectedValues()
      }
    })

    const selectedCustomBanners = computed(() => {
      return [
        {
          name: 'banner_300_250_url',
          selected: Boolean(adItemValues.value.banner_300_250_url)
        },
        {
          name: 'banner_970_250_url',
          selected: Boolean(adItemValues.value.banner_970_250_url)
        },
        {
          name: 'banner_728_90_url',
          selected: Boolean(adItemValues.value.banner_728_90_url)
        },
        {
          name: 'banner_320_100_url',
          selected: Boolean(adItemValues.value.banner_320_100_url)
        }
      ]
    })
    watch(selectedCustomBanners, (val, oldVal) => {
      if (!arraysEqual(val, oldVal)) {
        if (designType.value === 'custom') {
          flattenedUnitOptions.value.forEach(unit => {
            val.forEach(b => {
              if (unit.banners.includes(b.name)) {
                onUnitChange(unit, b.selected, false)
              }
            })
          })
        }
        emitSelectedValues()
      }
    })

    watch(selectedCategories, (val, oldVal) => {
      // if there are selected categories disable general section, else enable it
      if (!arraysEqual(val, oldVal)) {
        setSectionStatus('general', !val.length)
      }
    })

    const placementUnitsValue = computed(
      () => adItemValues.value.placementUnits
    )
    watch(placementUnitsValue, val => {
      if (!val || !arraysEqual(val, selectedUnits.value)) {
        selectedUnits.value = getPlacementUnits()
      }
    })

    const getActiveSectionUnits = (section: string) => {
      return unitOptions.value[section]
        .filter((u: AdItemUnitEntry) => !isUnitDisabled(u))
        .map((au: AdItemUnitEntry) => au.value)
    }

    const sectionHasAllActiveUnitsSelected = (section: string) => {
      const activeSectionUnits = getActiveSectionUnits(section)

      if (activeSectionUnits.length === 0) {
        return false
      }

      return activeSectionUnits.every(apu => selectedUnits.value.includes(apu))
    }

    const toggleExtendSection = (section: string) => {
      const sectionIndex = extendedSections.value.findIndex(p => p === section)

      if (sectionIndex > -1) {
        extendedSections.value.splice(sectionIndex, 1)
      } else {
        extendedSections.value.push(section)
      }
    }

    const sectionIsExpanded = (section: string) => {
      return extendedSections.value.includes(section)
    }

    const isSectionDisabled = (units: AdItemUnitEntry[]) => {
      return units.every(u => isUnitDisabled(u))
    }

    const onSectionChange = (section: string, selectAll: boolean) => {
      const activeUnits = getActiveSectionUnits(section)

      for (const unit of activeUnits) {
        if (selectAll) {
          if (!selectedUnits.value.includes(unit)) {
            selectedUnits.value.push(unit)
          }
        } else if (selectedUnits.value.includes(unit)) {
          const unitIndex = selectedUnits.value.findIndex(u => u === unit)
          if (unitIndex > -1) {
            selectedUnits.value.splice(unitIndex, 1)
          }
        }
      }

      emitSelectedValues()
    }

    const setSectionStatus = (section: string, enable: boolean) => {
      for (const unit of flattenedUnitOptions.value) {
        if (getUnitSection(unit.value) === section) {
          if (enable) {
            onSectionChange(section, enable) // enable only the active ones
          } else if (selectedUnits.value.includes(unit.value)) {
            const unitIndex = selectedUnits.value.findIndex(
              u => u === unit.value
            )
            if (unitIndex > -1) {
              selectedUnits.value.splice(unitIndex, 1)
            }
          }
        }
      }

      emitSelectedValues()
    }

    const onUnitChange = (
      unit: AdItemUnitEntry,
      enabled: boolean,
      emitChanges: boolean = true
    ) => {
      if (enabled) {
        if (!selectedUnits.value.includes(unit.value)) {
          selectedUnits.value.push(unit.value)
        }
      } else {
        const index = selectedUnits.value.findIndex(u => u === unit.value)
        if (index > -1) {
          selectedUnits.value.splice(index, 1)
        }
      }
      if (emitChanges) {
        emitSelectedValues()
      }
    }

    const emitSelectedValues = () => {
      emitParams('placementUnits', selectedUnits.value)
    }

    const previewId = ref(flattenedUnitOptions.value[0].value)

    const previewLabel = computed(() => {
      return (
        flattenedUnitOptions.value.find(o => o.value === previewId.value)
          ?.label || ''
      )
    })

    const getSectionOrderClass = (section: string) => {
      switch (section) {
        case 'general':
          return 'tw-order-0'

        case 'search':
          return 'tw-order-1'

        case 'view':
          return 'tw-order-2'

        default:
          return 'tw-order-10'
      }
    }
    return {
      getSchemaField,
      errors,
      schema,
      adItemValues,
      unitOptions,
      ciAngleRight,
      loading,
      selectedUnits,
      sectionHasAllActiveUnitsSelected,
      onSectionChange,
      isUnitDisabled,
      extendedSections,
      flattenedUnitOptions,
      sectionIsExpanded,
      toggleExtendSection,
      isSectionDisabled,
      onUnitChange,
      previewId,
      previewLabel,
      getUnitSection,
      isUnitSelected,
      disableInputs,
      faMobileAlt,
      faDesktop,
      getSectionOrderClass
    }
  }
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{on:{"click":_vm.avatarClicked}},[_c('div',{staticClass:"avatar",class:[
        { 'has-border': _vm.border },
        { 'no-avatar': !_vm.avatar || _vm.hasError },
        { 'default-no-avatar': !_vm.$slots.noAvatarIcon },
        { 'not-rounded': !_vm.rounded },
        { 'has-shadow': _vm.shadow },
        { clickable: _vm.clickable || (_vm.editable && _vm.avatar) },
        _vm.customClass
      ],style:(_vm.style)},[(_vm.editable)?_c(_vm.linkTag,{tag:"component",staticClass:"link-overlay",attrs:{"title":_vm.$t('change profile picture'),"href":_vm.linkToEdit},on:{"click":_vm.linkOverlayClicked,"mousedown":function($event){if('button' in $event && $event.button !== 1){ return null; }return _vm.linkOverlayMiddleClicked($event)}}},[_c('CIcon',{attrs:{"icon":_vm.icons.pencil}})],1):_vm._e(),_vm._v(" "),(_vm.avatar && !_vm.hasError)?_c('img',{style:(!_vm.rounded ? { minWidth: _vm.height + 'px' } : null),attrs:{"src":_vm.avatar,"alt":_vm.alt,"width":_vm.rounded && _vm.width ? _vm.width : null,"height":_vm.rounded && _vm.height ? _vm.height : null,"draggable":"false"},on:{"error":function($event){_vm.hasError = true}}}):(_vm.$slots.noAvatarIcon)?_vm._t("noAvatarIcon"):_c('CIcon',{staticClass:"no-avatar-icon tw-text-white tw-w-auto -tw-mb-1",attrs:{"icon":_vm.icons.user,"size":"80%"}})],2)]),_vm._v(" "),(_vm.zoomable && !_vm.editable && _vm.filesToShow.length && _vm.showZoomer)?_c('CImageViewer',{attrs:{"start-index":0,"files-to-show":_vm.filesToShow,"use-portal":""},on:{"zoomer-closed":function($event){_vm.showZoomer = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
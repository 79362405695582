import { CIcon } from '~/icons/types'

export const ciAbs: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 2.2c-5.1 0-9.3 4.2-9.3 9.3 0 5.1 4.2 9.3 9.3 9.3 5.1 0 9.3-4.2 9.3-9.3 0-5.1-4.2-9.3-9.3-9.3Zm0 16.6c-4 0-7.3-3.3-7.3-7.3S8 4.2 12 4.2s7.3 3.3 7.3 7.3-3.3 7.3-7.3 7.3Z'
    },
    {
      d:
        'M8.4 8.7c-.1-.3-.4-.5-.7-.5h-.2c-.3 0-.6.2-.7.5l-1.5 4.7c-.1.4.1.8.5.9H6c.3 0 .6-.2.7-.5l.2-.6h1.4l.2.6c.1.4.5.6.9.5.4-.1.6-.6.5-.9L8.4 8.7Zm-1 3 .2-.6.2.6h-.4Zm6.5-.7c.1-.3.2-.6.2-.9 0-1-.8-1.8-1.7-1.8h-1.2c-.4 0-.8.3-.8.8v4.8c0 .2.1.4.2.5.1.1.3.2.5.2h1.4c1 0 1.9-.9 1.9-2 .1-.7-.2-1.2-.5-1.6Zm-1.5-1.2c.1 0 .2.1.2.3 0 .2-.1.3-.2.3H12v-.6h.4Zm.2 3.2H12v-1.1h.7c.2 0 .4.2.4.5s-.3.6-.5.6Z'
    },
    {
      d:
        'M17.4 10.701c-.5-.2-.9-.4-1.1-.5v-.2c0-.1.1-.2.2-.2 0 0 .4-.1.8.3.3.3.8.2 1.1-.1.3-.3.2-.8-.1-1.1-1-.8-2-.7-2.3-.6-.6.2-1.1.7-1.2 1.4-.1.7.1 1.3.6 1.7l.1.1 1.2.6c.2.1.5.3.4.5 0 .2-.2.4-.5.4s-.6-.2-.8-.4c-.3-.3-.7-.4-1.1-.1-.3.3-.4.7-.1 1.1.5.6 1.2 1 2 1 .9 0 1.8-.7 1.9-1.6.4-1-.1-1.9-1.1-2.3ZM3.8 5.8c.3-.4.2-1.1-.3-1.4-.4-.3-1-.2-1.3.3-2.9 4.1-2.9 9.6 0 13.7.2.3.5.4.8.4.2 0 .4-.1.6-.2.5-.3.6-.9.2-1.4-2.4-3.4-2.4-8 0-11.4Zm18-1.1c-.3-.5-.9-.6-1.4-.2-.5.3-.6.9-.2 1.4 2.4 3.4 2.4 8 0 11.4-.3.5-.2 1.1.2 1.4.2.1.4.2.6.2.3 0 .6-.1.8-.4 2.9-4.2 2.9-9.7 0-13.8Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'abs',
  type: 'regular'
}

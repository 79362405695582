import { CIcon } from '~/icons/types'
export const ciPending: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      fillRule: 'evenodd',
      d:
        'M256 512c141.385 0 256-114.615 256-256S397.385 0 256 0 0 114.615 0 256s114.615 256 256 256Zm20.316-260.304V111.191c0-6.858-2.569-13.435-7.141-18.285-4.572-4.849-10.773-7.573-17.239-7.573-6.466 0-12.667 2.724-17.24 7.573-4.572 4.85-7.14 11.427-7.14 18.285v155.148a27.08 27.08 0 0 0 3.159 12.738c2.072 3.885 5.057 7.136 8.665 9.435l121.9 77.574c5.545 3.531 12.185 4.582 18.46 2.922 6.275-1.661 11.672-5.898 15.002-11.779s4.321-12.923 2.755-19.579c-1.566-6.656-5.561-12.379-11.106-15.911l-110.075-70.043Z'
    }
  ],
  name: 'pending',
  type: 'regular'
}













































































































import { computed, defineComponent, ref, watch } from '~/utils/nuxt3-migration'
import CRadioListEntry from '../../../configurable/form/radio/CRadioListEntry.vue'
import { useAdItemForm } from '~/compositions/advertise/ad-item-form'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import { useI18n } from '~/compositions/i18n'
import AdCustomImagesHandler from '~/components/shared/advertising/forms/handlers/AdCustomImagesHandler.vue'
import AdImageInput from '~/components/shared/advertising/forms/AdImageInput.vue'
import CRadioButtonGroup from '~/components/shared/configurable/button/CRadioButtonGroup.vue'
import PreviewTemplatesForItem from '~/components/shared/advertising/templates/PreviewTemplatesForItem.vue'
import CCharacterCounter from '~/components/shared/configurable/form/input/CCharacterCounter.vue'
import OptionsOrCustomInput from '~/components/shared/advertising/forms/OptionsOrCustomInput.vue'

export default defineComponent({
  components: {
    OptionsOrCustomInput,
    CCharacterCounter,
    PreviewTemplatesForItem,
    CRadioButtonGroup,
    AdImageInput,
    AdCustomImagesHandler,
    CFormError,
    CRadioListEntry
  },
  setup() {
    const {
      getSchemaField,
      emitParams,
      loading,
      errors,
      editMode,
      schema,
      adItemValues,
      disableInputs
    } = useAdItemForm()

    const { t } = useI18n()

    const ctaOptions = computed(() => {
      return [
        {
          text: 'Μάθε περισσότερα',
          value: 'Μάθε περισσότερα'
        },
        {
          text: 'Παράγγειλε τώρα',
          value: 'Παράγγειλε τώρα'
        },
        {
          text: 'Δες περισσότερα',
          value: 'Δες περισσότερα'
        },
        {
          text: 'Αγόρασε τώρα',
          value: 'Αγόρασε τώρα'
        },
        {
          text: 'Δες εδώ',
          value: 'Δες εδώ'
        }
      ]
    })
    const getItemValues = () => {
      if (!adItemValues.value?.title) {
        emitParams('title', ctaOptions.value[0].value)
      }
      return adItemValues.value
    }
    const values = ref(getItemValues())
    watch(adItemValues, val => (values.value = val))

    const getDesignType = () => {
      return editMode.value ? adItemValues.value.designType : 'custom'
    }
    const designType = ref(getDesignType())
    emitParams('designType', designType.value)

    const valuesDesignType = computed(() => adItemValues.value.designType)
    watch(valuesDesignType, (val, oldVal) => {
      if (val !== oldVal) {
        designType.value = val || getDesignType()
        designGroupType.value = getDesignGroupType()
      }
    })

    const getDesignGroupType = () => {
      return designType.value === 'custom' ? 'custom' : 'template'
    }
    const designGroupType = ref(getDesignGroupType())

    const designTypeGroups = computed(() => [
      { value: 'template', label: t('templates::item') },
      { value: 'custom', label: t('custom::items') }
    ])

    const designTemplates = computed(
      () =>
        getSchemaField('design_type')?.options.filter(
          o => o.value !== 'custom'
        ) || []
    )

    const onDesignGroupChange = (val: string) => {
      if (val === 'custom') {
        emitParams('designType', val)
      } else {
        if (!designType.value || designType.value === 'custom') {
          designType.value = designTemplates.value[0].value
        }
        emitParams('designType', designType.value)
      }
    }

    return {
      values,
      schema,
      editMode,
      getSchemaField,
      loading,
      errors,
      emitParams,
      designType,
      onDesignGroupChange,
      designGroupType,
      designTypeGroups,
      designTemplates,
      disableInputs,
      ctaOptions
    }
  }
})


























































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch
} from '~/utils/nuxt3-migration'
import CDatePicker from '~/components/shared/configurable/date/CDatePicker.vue'
import { useAdForm } from '~/compositions/advertise/ad-form'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import CTimeInput from '~/components/shared/configurable/datetime/CTimeInput.vue'
import { combineDateAndTime, getCurrentTime } from '~/utils/time'
import { AdItemStatus } from '~/models/advertising/types'
import { ciExclamationTriangle } from '~/icons/source/solid/exclamation-triangle'

export default defineComponent({
  components: { CTimeInput, CFormError, CDatePicker },
  setup() {
    const {
      getSchemaField,
      emitParams,
      loading,
      errors,
      editMode,
      adValues,
      currentAd
    } = useAdForm()

    const today = computed(() => new Date())

    const thirtyDaysFromToday = computed(() => {
      const currentTime = new Date()
      return new Date(currentTime.setMonth(currentTime.getMonth() + 1))
    })

    const getStartDate = () => {
      if (editMode.value) {
        return adValues.value.startDate
          ? new Date(adValues.value.startDate)
          : undefined
      }
      return today.value
    }
    const getStartTime = () => {
      if (editMode.value) {
        const start = getStartDate()
        const hours = start.getHours()
        const minutes = start.getMinutes()
        return start
          ? ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)
          : undefined
      }

      return getCurrentTime(2)
    }
    const startDate = ref<Date | undefined>(getStartDate())
    const startTime = ref(getStartTime())

    const getEndDate = () => {
      if (editMode.value) {
        return adValues.value.finishDate
          ? new Date(adValues.value.finishDate)
          : undefined
      }
      return thirtyDaysFromToday.value
    }
    const getEndTime = () => {
      if (editMode.value) {
        const end = getEndDate()
        const hours = end.getHours()
        const minutes = end.getMinutes()
        return end
          ? ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2)
          : undefined
      }

      return getCurrentTime(2)
    }

    const endDate = ref<Date | undefined>(getEndDate())
    const endTime = ref(getEndTime())

    watch(adValues, (val, oldVal) => {
      if (oldVal.startDate !== val.startDate) {
        startDate.value = getStartDate()
      }

      if (oldVal.startTime !== val.startTime) {
        startTime.value = getStartTime()
      }

      if (oldVal.finishDate !== val.finishDate) {
        endDate.value = getEndDate()
      }

      if (oldVal.endTime !== val.endTime) {
        endTime.value = getEndTime()
      }
    })

    const emitStartDateTime = async () => {
      await nextTick()

      const dateString = combineDateAndTime(startDate.value, startTime.value)
      emitParams('startDate', dateString)
    }
    const emitEndDateTime = async () => {
      await nextTick()

      const dateString = combineDateAndTime(endDate.value, endTime.value)
      emitParams('finishDate', dateString)
    }

    onMounted(() => {
      emitStartDateTime()
      emitEndDateTime()

      startDate.value = getStartDate()
      endDate.value = getEndDate()
    })

    const toMin = computed(() => {
      let date = startDate.value ? new Date(startDate.value) : null
      const now = new Date(today.value)

      if (date === null || date < now) {
        date = now
      }

      let offsetDays = 2

      if (
        [AdItemStatus.EXPIRED, AdItemStatus.APPROVED].includes(
          currentAd.value?.currentStatus
        )
      ) {
        offsetDays = 1
      }
      return new Date(date.setDate(date.getDate() + offsetDays))
    })

    const fromMin = computed(() => {
      let date = startDate.value ? new Date(startDate.value) : null
      const now = new Date(today.value)

      if (date === null || !disabledFrom.value) {
        date = now
      }
      return date
    })

    const adHasApprovedItems = computed(() => {
      return (
        currentAd.value?.items.some(i => i.status === AdItemStatus.APPROVED) ||
        false
      )
    })

    const disabledFrom = computed(() => {
      if (startDate.value) {
        const now = new Date(today.value)
        return adHasApprovedItems.value && startDate.value < now
      }
      return false
    })

    return {
      startDate,
      getSchemaField,
      startTime,
      endTime,
      loading,
      errors,
      fromMin,
      toMin,
      emitParams,
      endDate,
      today,
      adValues,
      emitStartDateTime,
      emitEndDateTime,
      thirtyDaysFromToday,
      disabledFrom,
      currentAd,
      adHasApprovedItems,
      ciExclamationTriangle,
      AdItemStatus
    }
  }
})

























import {
  computed,
  defineComponent,
  ref,
  toRefs,
  watch
} from '~/utils/nuxt3-migration'
import { facetProps } from '~/constants/quick-search'
import LocationSuggestionService from '~/services/location/autocomplete/LocationSuggestionService'
import CInputAutocomplete from '~/components/shared/configurable/form/input/CInputAutocomplete.vue'
import { useDep } from '~/compositions/dependency-container'
import { AutocompleteSuggestion } from '~/models/autocomplete'
import { LocationSuggestion } from '~/models/location/autocomplete/types'
import { useQuickSearchParams } from '~/compositions/quick-search/useQuickSearchParams'
import { Location } from '~/models/location/closest'
import ClosestLocationService from '~/services/location/closest/ClosestLocationService'
import { useSnackbar } from '~/compositions/snackbar'
import { ciCompass } from '~/icons/source/solid/compass'

export default defineComponent({
  props: { ...facetProps },
  components: { CInputAutocomplete },
  setup(props) {
    const { humanName, urlArg } = toRefs(props)

    const locationSuggestionService = useDep(LocationSuggestionService)
    const closestLocationService = useDep(ClosestLocationService)
    const snackbar = useSnackbar()
    const { changeParam } = useQuickSearchParams()

    const locations = ref<Map<string, LocationSuggestion>>(new Map())
    const locationSuggestions = computed<AutocompleteSuggestion[]>(() =>
      [...locations.value.values()].map(v => ({
        id: v.id,
        name: v.name,
        subText: v.area
      }))
    )
    const selectedLocationName = ref('')

    watch(selectedLocationName, () => {
      if (!selectedLocationName.value) {
        changeParam('location', null)
      }
    })

    const placeholder = computed(() => humanName.value)

    const icons = {
      compass: ciCompass
    }

    async function onRequestSuggestions(query: string) {
      locations.value = await locationSuggestionService.getLocations(query)
    }

    function onSelectSuggestion(suggestion: AutocompleteSuggestion) {
      changeParam(urlArg.value, suggestion.id)
    }

    async function onCurrentLocationButtonClick() {
      try {
        const closestLocations: Location[] = await closestLocationService.getClosestLocationsToCurrent()
        if (!closestLocations.length) {
          return
        }
        const closestLocation = closestLocations[0]
        selectedLocationName.value = closestLocation.name
        locations.value = new Map()
        return changeParam(urlArg.value, closestLocation.id)
      } catch (error) {
        snackbar.error((error as Error).message)
      }
    }

    return {
      placeholder,
      icons,
      onRequestSuggestions,
      locationSuggestions,
      onSelectSuggestion,
      onCurrentLocationButtonClick,
      selectedLocationName
    }
  }
})

import { CIcon } from '~/icons/types'

export const ciChevronDown: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M13.06 16.81a1.5 1.5 0 0 1-2.12 0l-7.5-7.5a1.5 1.5 0 1 1 2.12-2.12L12 13.628l6.44-6.44a1.5 1.5 0 0 1 2.12 2.122l-7.5 7.5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'chevron-down',
  type: 'solid'
}

















































































































































































import { ciReceipt } from '~/icons/source/regular/receipt'
import { ciInvoice } from '~/icons/source/regular/invoice'
import { mapGetters, mapMutations } from 'vuex'
import { ACCOUNT_NS } from '~/store/modules/shared/account/state'
import { defineComponent, PropType, vue3Model } from '~/utils/nuxt3-migration'
import {
  CreditPackage,
  StripeActivePerk,
  StripePerkPlan
} from '~/models/payments/stripe'
import StripeService from '~/services/logistics/StripeService'
import { ciCalendarFa } from '~/icons/source/regular/calendar-fa'
import { mapDeps } from '~/plugins/dependency-container/utils'
import { SET_ACCOUNT_LOADING } from '~/store/modules/shared/account/mutation-types'
import AccountInvoiceForm from '~/components/shared/account/invoices/AccountInvoiceForm.vue'
import AccountSpinner from '~/components/shared/account/AccountSpinner.vue'
import { ciChevronCircleLeft } from '~/icons/source/regular/chevron-circle-left'
import { format } from '~/utils/date'
import CTransitionExpand from '~/components/shared/configurable/expand/CTransitionExpand.vue'
import { formatNumber } from '~/utils/number'
import { USER_NS } from '~/store/modules/shared/user/state'
import { ciPencil } from '~/icons/source/solid/pencil'
import { ciCreditCard } from '~/icons/source/regular/credit-card'
import { ciCheckCircle } from '~/icons/source/solid/check-circle'

enum PaymentTypes {
  RECEIPT = 'receipt',
  INVOICE = 'invoice'
}

interface Data {
  selectedPaymentType: PaymentTypes | null
  schemaLoaded: boolean
  nextClicked: boolean
}

export default defineComponent({
  components: { CTransitionExpand, AccountSpinner, AccountInvoiceForm },
  model: vue3Model,
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    selectedPlan: {
      type: Object as PropType<StripePerkPlan | null>,
      default: null
    },
    activePerk: {
      type: Object as PropType<StripeActivePerk | null>,
      default: null
    },
    selectedPackage: {
      type: Object as PropType<CreditPackage | null>,
      required: false,
      default: null
    },
    successCallback: {
      type: Function,
      default: null
    },
    topUpMode: {
      type: Boolean,
      default: false
    },
    termsLink: {
      type: String,
      default: ''
    },
    termsLabel: {
      type: String,
      default: ''
    },
    amount: {
      type: [String, Number],
      required: false,
      default: undefined
    },
    modalToggle: { type: Boolean, default: true, required: false }
  },
  data(): Data {
    return {
      selectedPaymentType: null,
      schemaLoaded: false,
      nextClicked: false,
      acceptedTerms: false
    }
  },
  computed: {
    ...mapGetters(ACCOUNT_NS, {
      loading: 'getLoading',
      currentPeriodEnd: 'getActivePackageCurrentPeriodEnd'
    }),
    ...mapGetters(USER_NS, {
      isOfTypeDealer: 'isOfTypeDealer'
    }),
    ...mapDeps({ stripeService: StripeService }),
    visible: {
      get() {
        return this.modelValue
      },
      set(value: boolean) {
        this.$emit('update:modelValue', value)
      }
    },
    icons() {
      return {
        receipt: ciReceipt,
        invoice: ciInvoice,
        calendar: ciCalendarFa,
        creditCard: ciCreditCard,
        circleLeft: ciChevronCircleLeft,
        checkCircle: ciCheckCircle,
        pencil: ciPencil
      }
    },
    buyButtonDisabled() {
      return this.selectedPaymentType === null
    },
    issueInvoice() {
      return this.selectedPaymentType === PaymentTypes.INVOICE
    },
    showInvoiceForm() {
      return (
        this.selectedPaymentType === PaymentTypes.INVOICE && this.nextClicked
      )
    },
    issueReceipt() {
      return this.selectedPaymentType === PaymentTypes.RECEIPT
    },
    activePlan() {
      return this.activePerk?.plan
    },
    hasActivePlan() {
      return Boolean(this.activePlan?.id && !this.topUpMode)
    },
    buyButtonText() {
      if (this.topUpMode) {
        return this.$t('pay')
      }
      if (this.selectedPackage) {
        let text = this.$t('buy::agora') + ' credits'
        if (this.selectedPackage) {
          text = `${this.$t('buy::agora')} ${formatNumber(
            this.selectedPackage.credits
          )} credits`
        }
        return text
      }

      if (this.hasActivePlan) {
        return this.$t('change plan to {subscription}', {
          subscription: this.selectedPlan?.name || '-'
        })
      }
      return this.$t('activate {subscription} subscription', {
        subscription: this.selectedPlan?.name || '-'
      })
    },
    dateFormatted() {
      if (this.hasActivePlan) {
        return format(this.currentPeriodEnd, 'dd MMMM', {
          locale: this.$i18n.locale
        })
      }
      return ''
    },
    forCredits() {
      return Boolean(this.selectedPackage)
    }
  },
  mounted() {
    this.setLoading(false)
  },
  methods: {
    ...mapMutations(ACCOUNT_NS, {
      setLoading: SET_ACCOUNT_LOADING
    }),
    invoiceClicked() {
      this.selectedPaymentType = PaymentTypes.INVOICE
    },
    receiptClicked() {
      this.selectedPaymentType = PaymentTypes.RECEIPT
    },
    buyClicked() {
      if (this.issueInvoice) {
        this.triggerInvoiceFormSubmit()
      } else {
        this.submitBuy()
      }
    },
    triggerInvoiceFormSubmit() {
      if (this.$refs.invoicing) {
        return this.$refs.invoicing.triggerSubmit()
      }
      return null
    },
    backClicked() {
      if (this.topUpMode && !this.showInvoiceForm) {
        this.$emit('back')
      }
      this.selectedPaymentType = null
      this.nextClicked = false
      this.schemaLoaded = false
    },
    async submitBuy() {
      if (this.topUpMode) {
        this.$emit('buy', this.issueInvoice)
        return
      }
      if (this.isOfTypeDealer && this.selectedPaymentType === null) {
        return
      }
      this.setLoading(true)

      try {
        if (this.forCredits) {
          const { gotonext } = this.$route.query
          const successUrl = gotonext
            ? `${window.location.origin}${
                this.$router.resolve({
                  name: '__account_settings_payments_success',
                  query: {
                    gotonext
                  }
                }).href
              }`
            : undefined
          const {
            stripeSession
          } = await this.stripeService.startSessionForPriceId({
            priceId: this.selectedPackage.priceId,
            successUrl,
            issueInvoice: this.issueInvoice
          })
          this.stripeService.setSessionCookie(stripeSession.session)
          this.$emit('redirecting')
          if (this.successCallback) {
            this.successCallback(stripeSession.url)
          } else {
            window.location = stripeSession.url
          }
        } else if (this.hasActivePlan) {
          await this.stripeService.changeSubscription({
            priceId: this.selectedPlan?.stripePriceId
          })
          window.location.reload()
        } else {
          const stripeSession = await this.stripeService.startSessionForPriceId(
            {
              priceId: this.selectedPlan?.stripePriceId,
              issueInvoice: this.issueInvoice,
              forSubscription: true
            }
          )
          this.stripeService.setSessionCookie(stripeSession.session)
          if (this.successCallback) {
            this.successCallback(stripeSession.url)
          } else {
            window.location = stripeSession.url
          }
        }
      } catch (error) {
        this.$logger.captureError(error)
        this.$snackbar.error(
          this.$t('an error occurred please try again later')
        )
        this.setLoading(false)
      }
    }
  }
})






































































































































































import {
  defineComponent,
  ref,
  toRefs,
  computed,
  useRoute,
  onMounted,
  useRouter
} from '~/utils/nuxt3-migration'
import { defineComponentTranslations } from '~/utils/i18n'
import AdScheduleHandler from '~/components/shared/advertising/forms/handlers/AdScheduleHandler.vue'
import AdDeliveryHandler from '~/components/shared/advertising/forms/handlers/AdDeliveryHandler.vue'
import AdFieldset from '~/components/shared/advertising/forms/AdFieldset.vue'
import AdImpressionsPerUserHandler from '~/components/shared/advertising/forms/handlers/AdImpressionsPerUserHandler.vue'
import AdBudgetHandler from '~/components/shared/advertising/forms/handlers/AdBudgetHandler.vue'
import CFormError from '~/components/shared/configurable/form/CFormError.vue'
import { CLEAR_FORMS } from '~/store/modules/shared/advertising/form/mutation-types'
import { useNamespacedStore } from '~/compositions/store'
import {
  ADVERTISE_FORM_NS,
  AdvertiseFormState
} from '~/store/modules/shared/advertising/form/state'
import { useQuickAdForm } from '~/compositions/advertise/quick-ad-form'
import AdItemPlacementContextHandler from '~/components/shared/advertising/forms/handlers/AdItemPlacementContextHandler.vue'
import AdItemTargetLinkHandler from '~/components/shared/advertising/forms/handlers/AdItemTargetLinkHandler.vue'
import AdItemPlacementUnitsHandler from '~/components/shared/advertising/forms/handlers/AdItemPlacementUnitsHandler.vue'
import AdStatus from '~/components/shared/advertising/AdStatus.vue'
import AdItemDesignTypeHandler from '~/components/shared/advertising/forms/handlers/AdItemDesignTypeHandler.vue'
import AdImageInput from '~/components/shared/advertising/forms/AdImageInput.vue'
import { useI18n } from '~/compositions/i18n'
import { InHouseSizes } from '~/constants/ads/inhouse'
import { ciAngleDown } from '~/icons/source/regular/angle-down'
import { ciAngleUp } from '~/icons/source/regular/angle-up'
import ReceiptOrInvoiceModal from '~/components/shared/account/payments/subscriptions/ReceiptOrInvoiceModal.vue'
import { useDep } from '~/compositions/dependency-container'
import AdvertiseService from '~/services/ads/AdvertiseService'
import { useRequestError } from '~/compositions/request-error'
import CBalanceTopUpTrigger from '~/components/shared/configurable/CBalanceTopUpTrigger.vue'
import {
  ADVERTISE_DASHBOARD_NS,
  AdvertiseDashboardState
} from '~/store/modules/shared/advertising/dashboard/state'
import { formatPrice } from '~/utils/number'
import { useSnackbar } from '~/compositions/snackbar'

export default defineComponent({
  components: {
    CBalanceTopUpTrigger,
    ReceiptOrInvoiceModal,
    AdImageInput,
    AdItemDesignTypeHandler,
    AdStatus,
    AdItemPlacementUnitsHandler,
    AdItemTargetLinkHandler,
    AdItemPlacementContextHandler,
    CFormError,
    AdBudgetHandler,
    AdImpressionsPerUserHandler,
    AdFieldset,
    AdDeliveryHandler,
    AdScheduleHandler
  },
  props: {
    unit: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const { commit, dispatch } = useNamespacedStore<AdvertiseFormState>(
      ADVERTISE_FORM_NS
    )

    const { state } = useNamespacedStore<AdvertiseDashboardState>(
      ADVERTISE_DASHBOARD_NS
    )

    const { unit } = toRefs(props)

    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const snackbar = useSnackbar()

    const afterStripeCancel = computed(
      () =>
        route.value.query.cancel &&
        route.value.query.item &&
        route.value.query.unit &&
        route.value.query.unit === unit.value
    )

    const inFirstPage = ref(!afterStripeCancel.value)
    const showMore = ref(false)
    const disableNext = ref(false)
    const showReceiptModal = ref(false)
    const payUsingBalance = ref(false)
    const adCreated = ref(afterStripeCancel.value)
    const submitButtonRef = ref(null)
    const handleRequestError = useRequestError()

    const advertisingService = useDep(AdvertiseService)

    const {
      schema,
      values,
      inEdit,
      errors,
      adItem,
      emitParams,
      loading,
      getSchemaField,
      clearPreviewUrlParams
    } = useQuickAdForm()

    const bannersOrder = computed(() => {
      // this is sorted for best performing units
      const allBanners = [
        { type: 'banner_970_250_url', label: t('large_ad') },
        { type: 'banner_300_250_url', label: t('square_ad') },
        { type: 'banner_728_90_url', label: t('small_ad') },
        { type: 'banner_320_100_url', label: t('mobile_ad') }
      ]

      const unitSizes = InHouseSizes[unit.value]?.sizes || []

      if (unitSizes.length) {
        let first, second

        if (unitSizes[0].length === 2) {
          first = allBanners.find(b =>
            b.type.includes(`${unitSizes[0][0]}_${unitSizes[0][1]}`)
          )
          second = allBanners.find(b =>
            b.type.includes(`${unitSizes[1][0]}_${unitSizes[1][1]}`)
          )
        } else {
          first = allBanners.find(b =>
            b.type.includes(`${unitSizes[0]}_${unitSizes[1]}`)
          )
          // find the first entry from allBanners that isn't same type as "first"
          second = allBanners.find(b => b.type !== first.type)
        }

        if (first && second) {
          if (showMore.value) {
            const rest = [...allBanners].filter(
              b => ![first.type, second.type].includes(b.type)
            )
            return [first, second, ...rest]
          }

          return [first, second]
        }
      }

      return showMore.value ? allBanners : [allBanners[0], allBanners[1]]
    })

    const fetchSchema = async () => {
      await dispatch('loadQuickAdSchema')
    }

    onMounted(async () => {
      await fetchSchema()
    })

    const onSubmit = async () => {
      try {
        await dispatch('submitQuickAd')

        if (inFirstPage.value === false) {
          if (payUsingBalance.value) {
            snackbar.success(t('ad_created_success'))
            adCreated.value = true
            onHidden()
          } else {
            showReceiptModal.value = true
            adCreated.value = true
          }
        } else if (inEdit.value) {
          // just got to step 2 so load schema
          await fetchSchema()
        }
        inFirstPage.value = false // go to step 2
      } catch (_error) {}
    }

    const onPrimaryClick = () => {
      if (adCreated.value) {
        if (inFirstPage.value) {
          inFirstPage.value = false
        } else {
          showReceiptModal.value = true
        }
        return
      }

      submitButtonRef.value.click()
    }
    const onBack = () => {
      inFirstPage.value = true
    }
    const onHidden = () => {
      if (showReceiptModal.value) {
        // we still want some values from the forms here
        return
      }
      const adCreatedAsDraft = inEdit.value
      commit(CLEAR_FORMS)
      clearPreviewUrlParams()

      if (adCreatedAsDraft) {
        snackbar.success(t('ad_saved_as_draft'), {
          action: {
            text: '>',
            href: router.resolve({ name: '__advertising_dashboard_ads' }).href,
            target: '_blank'
          },
          timeout: 6000
        })
      }
      emit('hidden', adCreatedAsDraft)
    }

    const budget = computed(() => values.value.budget)

    const onStripeSubmit = async (invoice: boolean) => {
      try {
        const stripeSession = await advertisingService.topUpBalance(
          Number(budget.value),
          invoice,
          unit.value,
          adItem.value?.id || (route.value.query.item as string)
        )
        clearPreviewUrlParams()
        window.location = stripeSession.url
      } catch (error) {
        handleRequestError(error)
      }
    }

    const availableBalance = computed(() => {
      return state.balance
    })

    const canPayWithBalance = computed(() => {
      return inFirstPage.value === false && state.balance >= values.value.budget
    })
    return {
      fetchSchema,
      loading,
      schema,
      values,
      errors,
      emitParams,
      onSubmit,
      onHidden,
      getSchemaField,
      inFirstPage,
      bannersOrder,
      showMore,
      ciAngleDown,
      ciAngleUp,
      onPrimaryClick,
      onBack,
      disableNext,
      showReceiptModal,
      submitButtonRef,
      onStripeSubmit,
      adCreated,
      payUsingBalance,
      budget,
      adItem,
      availableBalance,
      formatPrice,
      canPayWithBalance
    }
  },
  i18n: defineComponentTranslations({
    quick_ad_creation: {
      en: 'Quickly create an ad',
      el: 'Γρήγορη δημιουργία διαφήμισης'
    },
    quick_ad_name: {
      en: 'Name of the quick ad',
      el: 'Όνομα γρήγορης διαφήμισης'
    },
    more_sizes: {
      en: 'More sizes',
      el: 'Περισσότερα μεγέθη'
    },
    less_sizes: {
      en: 'Less sizes',
      el: 'Λιγότερα μεγέθη'
    },
    select_a_landing_page: {
      en: 'Select a landing page',
      el: 'Επίλεξε τη σελίδα προορισμού'
    },
    pay_using_balance: {
      en: 'Pay using your available balance {balance}',
      el: 'Πληρωμή με το διαθέσιμο υπόλοιπο {balance}'
    },
    ad_created_success: {
      en: 'Your ad is created and is awaiting approval',
      el: 'Η διαφήμισή σου δημιουργήθηκε και αναμένει έγκριση'
    },
    ad_saved_as_draft: {
      en: 'Ad saved as draft',
      el: 'Η διαφήμισή αποθηκεύτηκε ως πρόχειρο'
    },
    custom_sub: {
      en:
        'Use your own custom ad images. Please upload only images that match the dimensions specified below and have a file extension of: {format} and a maximum size of: {filesize}.',
      el:
        'Χρησιμοποίησε τις δικές σου εικόνες διαφημιστικών banners. Ανέβασε μόνο εικόνες που συμφωνούν με τις καθορισμένες διαστάσεις παρακάτω και έχουν τύπο αρχείου: {format} και μέγιστο μέγεθος: {filesize}'
    }
  })
})

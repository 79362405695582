

















































import {
  defineComponent,
  toRefs,
  computed,
  ref,
  useRoute,
  useRouter
} from '~/utils/nuxt3-migration'
import {
  containerSizeMappingProp,
  containerSizeProp,
  placeholderProp
} from '~/models/ads'
import AdViewService from '~/services/ads/AdViewService'
import QuickAdModal from '~/components/shared/advertising/forms/QuickAdModal.vue'
import CAdInlineStyle from '~/components/shared/configurable/ad/container/CAdInlineStyle.vue'
import CAdPlaceholder from '~/components/shared/configurable/ad/placeholder/CAdPlaceholder.vue'
import { useDeps } from '~/compositions/dependency-container'
import { defineComponentTranslations } from '~/utils/i18n'
import { useStatic } from '~/compositions/useStatic'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { useLoginModal } from '~/compositions/login-modal'
import { modifyUrl, removeQueries } from '~/utils/history'
import PopularityService from '~/services/popularity/PopularityService'
import { useI18n } from '~/compositions/i18n'

const EMPTY_IMAGE_URI =
  'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
export default defineComponent({
  components: {
    QuickAdModal,
    CAdInlineStyle,
    CAdPlaceholder
  },
  props: {
    containerSize: containerSizeProp,
    placeholder: placeholderProp,
    containerSizeMapping: containerSizeMappingProp,
    unit: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    showQuickAdTrigger: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const { containerSizeMapping, containerSize } = toRefs(props)
    const adPlaceholderRef = ref(null)
    const adContainerRef = ref(null)
    const showQuickAdModal = ref(false)

    const [adViewService, popularityService] = useDeps(
      AdViewService,
      PopularityService
    )
    const s = useStatic()
    const route = useRoute()
    const router = useRouter()
    const { locale } = useI18n()

    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)

    const isInSearch = () => {
      return route.value.name?.startsWith('__classifieds_search')
    }

    const { showModal: showLoginModal } = useLoginModal({
      onShow: () => {
        if (isInSearch()) {
          window.history.replaceState(
            { isInSearchPage: true },
            '',
            modifyUrl(window.location.href, { force_ad: 1, unit: props.unit })
          )
        }
      },
      onHide: () => {
        if (isInSearch()) {
          window.history.replaceState(
            { isInSearchPage: true },
            '',
            removeQueries(window.location.href, ['force_ad', 'unit'])
          )
        }
      },
      onSuccess: () => {
        if (!isInSearch()) {
          showQuickAdModal.value = true
        }
      },
      redirectAfterLogin: false
    })

    const isAnon = computed(() => userGetters('isSingleOrAnon'))

    const noSizeMappingStyleRules = computed(() => {
      if (containerSizeMapping.value.length === 0) {
        const rules = adViewService.getAdContainerSizeCssRules(
          containerSize.value
        )

        if (process.client && rules['min-width']) {
          const containerWidth = parseInt(rules['min-width'].split('px')[0])
          const parentWidth =
            adContainerRef.value?.parentElement?.clientWidth ||
            window.outerWidth
          if (containerWidth > parentWidth) {
            rules['min-width'] = parentWidth + 'px'
          }
        }
        return rules
      }
      return undefined
    })

    const containerResponsiveProps = computed(() => {
      if (containerSizeMapping.value.length) {
        const attrs = adViewService.getAdContainerResponsiveProps(
          containerSizeMapping.value
        )
        return attrs
      }
      return null
    })

    const triggerQuickAdModal = () => {
      if (route.value.query.force_ad) {
        if (isInSearch()) {
          window.history.replaceState(
            { isInSearchPage: true },
            '',
            removeQueries(window.location.href, ['force_ad'])
          )
        } else {
          router.replace({
            query: {
              ...route.value.query,
              force_ad: undefined
            }
          })
        }
      }
      onQuickAdTriggerClick()
    }

    const quickAdMainSrc = computed(() => {
      if (
        !containerSize.value ||
        !containerSize.value[0] ||
        !containerSize.value[1]
      ) {
        return ''
      }
      const langPrefix = locale === 'el' ? 'el' : 'en'
      return s(
        `/nuxt-static/img/inhouse/${containerSize.value[0]}x${containerSize.value[1]}_${langPrefix}.png`
      )
    })

    const quickAdTriggerSources = computed(() => {
      if (containerSizeMapping.value?.length) {
        const langPrefix = locale === 'el' ? 'el' : 'en'
        return containerSizeMapping.value.map(mapping => {
          return {
            width: mapping[0][0],
            src: mapping[1][0]?.length
              ? s(
                  `/nuxt-static/img/inhouse/${mapping[1][0][0]}x${mapping[1][0][1]}_${langPrefix}.png`
                )
              : EMPTY_IMAGE_URI
          }
        })
      }

      return []
    })

    const onQuickAdTriggerClick = () => {
      popularityService.recordEvent('public_quickad_click')
      if (isAnon.value) {
        showLoginModal()
        return
      }

      showQuickAdModal.value = true
    }

    const onQuickAdTriggerImageLoad = () => {
      popularityService.recordEvent('public_quickad_view')
    }

    return {
      adPlaceholderRef,
      triggerQuickAdModal,
      noSizeMappingStyleRules,
      containerResponsiveProps,
      quickAdMainSrc,
      quickAdTriggerSources,
      showQuickAdModal,
      onQuickAdTriggerClick,
      onQuickAdTriggerImageLoad,
      adContainerRef
    }
  },
  i18n: defineComponentTranslations({
    advertise_here: {
      en: 'Advertise here',
      el: 'Διαφημίσου εδώ'
    }
  })
})

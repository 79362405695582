import { CIcon } from '~/icons/types'
export const ciAngleUp: CIcon = {
  name: 'angle-up',
  type: 'regular',
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M12 8a1 1 0 0 1 .707.293l5 5a1 1 0 0 1-1.414 1.414L12 10.414l-4.293 4.293a1 1 0 0 1-1.414-1.414l5-5A1 1 0 0 1 12 8Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ]
}

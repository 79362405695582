import { ref, Ref } from '~/utils/nuxt3-migration'

export function useDroppable(
  dragging: Ref,
  onDropCallback: (e: DragEvent) => void
) {
  const droppingOver = ref(false)

  function onDragOver(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    if (!dragging.value) {
      droppingOver.value = true
    }
  }

  function onDragLeave(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    droppingOver.value = false
  }

  function onDrop(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    droppingOver.value = false
    if (!dragging.value) {
      onDropCallback(e)
    }
  }

  return {
    droppingOver,
    onDrop,
    onDragLeave,
    onDragOver
  }
}

export function useDraggable() {
  const dragging = ref(false)
  function onDragStart(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    dragging.value = true
  }
  function onDragEnd(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
    dragging.value = false
  }

  return {
    dragging,
    onDragStart,
    onDragEnd
  }
}

























































import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { AdItem } from '../../../../models/advertising/types'
import InHouseTemplate from '~/components/shared/advertising/templates/InHouseTemplate.vue'

export default defineComponent({
  components: { InHouseTemplate },
  props: {
    item: {
      type: Object as PropType<AdItem>,
      required: true
    },
    inAdminRow: {
      type: Boolean,
      required: false,
      default: false
    },
    inPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    inForm: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {}
})

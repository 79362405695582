import { useNamespacedStore } from '~/compositions/store'

import { watch, computed } from '~/utils/nuxt3-migration'
import {
  LOGIN_MODAL_NS,
  LoginModalState
} from '~/store/modules/shared/login-modal/state'
import {
  SET_LOGIN_MODAL_VISIBILITY,
  REDIRECT_AFTER_LOGIN
} from '~/store/modules/shared/login-modal/mutation-types'

export function useLoginModal(params?: {
  onSuccess?: Function
  onShow?: Function
  onHide?: Function
  redirectAfterLogin?: boolean
}) {
  const { state, commit } = useNamespacedStore<LoginModalState>(LOGIN_MODAL_NS)

  const { onSuccess, redirectAfterLogin, onShow, onHide } = params || {}

  if (typeof redirectAfterLogin === 'boolean') {
    commit(REDIRECT_AFTER_LOGIN, redirectAfterLogin)
  }

  function showModal() {
    commit(SET_LOGIN_MODAL_VISIBILITY, true)
  }

  function hideModal() {
    commit(SET_LOGIN_MODAL_VISIBILITY, false)
  }

  const isLoginSuccess = computed(() => state.isLoginSuccess)
  const isLoginModalVisible = computed(() => state.isLoginModalVisible)

  watch(isLoginSuccess, val => {
    if (val && onSuccess) {
      onSuccess()
    }
  })

  watch(isLoginModalVisible, (val, oldVal) => {
    if (val && !oldVal) {
      onShow && onShow()
    } else if (oldVal && !val) {
      onHide && onHide()
    }
  })

  return {
    hideModal,
    showModal
  }
}

import { CIcon } from '~/icons/types'

export const ciVolumeMax: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M11.43 4.1a1 1 0 0 0-1 .12L5.65 8H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h3.65l4.73 3.78A1 1 0 0 0 11 20a.91.91 0 0 0 .43-.1A1 1 0 0 0 12 19V5a1 1 0 0 0-.57-.9Zm3.4 3.65a1 1 0 0 0 0 1.42 4 4 0 0 1 0 5.65 1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0A6.08 6.08 0 0 0 18 12a6.002 6.002 0 0 0-1.75-4.25 1 1 0 0 0-1.42 0Zm4.95-3.53a1.004 1.004 0 0 0-1.42 1.42 9 9 0 0 1 0 12.72 1 1 0 0 0 0 1.42 1.002 1.002 0 0 0 1.42 0 11 11 0 0 0 0-15.56Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'volume-max',
  type: 'solid'
}

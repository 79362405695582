import { CIcon } from '~/icons/types'

export const ciAngleUp: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M12 7.5a1.5 1.5 0 0 1 1.06.44l5 5a1.5 1.5 0 0 1-2.12 2.12L12 11.122l-3.94 3.94a1.5 1.5 0 0 1-2.12-2.122l5-5A1.5 1.5 0 0 1 12 7.5Z',
      fillRule: 'evenodd',
      clipRule: 'evenodd'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'angle-up',
  type: 'solid'
}

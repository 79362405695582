var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"centered":"","return-focus":"#__nuxt","body-class":"!tw-p-0 tw-overflow-hidden","container-class":"tw-max-w-[568px] !tw-p-0","title":_vm.title || _vm.$t('profile image'),"modal-toggle":"","no-close-on-backdrop":"","size":_vm.debug ? 'xl' : 'md'},on:{"hidden":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-w-full"},[(!_vm.forceCrop)?_c('CButton',{attrs:{"size":"sm","variant":"secondary","outline":""},on:{"click":function($event){return _vm.saveNoCrop(ok)}}},[_vm._v("\n        "+_vm._s(_vm.$t('no crop'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('CButton',{staticClass:"confirm-button tw-ml-auto",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.save(ok)}}},[_vm._v("\n        "+_vm._s(_vm.$t('save'))+"\n      ")])],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{class:{ 'tw-flex tw-items-start max-sm:tw-flex-col ': _vm.debug }},[_c('div',{staticClass:"cropper-container"},[_c('div',{ref:"scrollerCont",staticClass:"cropper",style:({ backgroundColor: _vm.bgColorHex })},[_c('div',{ref:"scroller",staticClass:"img-container",class:{ transit: _vm.transit, dragging: !_vm.transit },style:({
            width: _vm.appliedWidth + 'px',
            height: _vm.appliedHeight + 'px',
            transform:
              'translate3d(' + _vm.scrollX + 'px, ' + _vm.scrollY + 'px, 0px)'
          })},[(_vm.imageSource)?_c('img',{ref:"avatar",attrs:{"src":_vm.imageSource,"draggable":"false"}}):_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-h-full"},[_c('AccountSpinner')],1)]),_vm._v(" "),_c('div',{ref:"cropperWindow",staticClass:"cropper-window",class:{ 'tw-rounded-full': _vm.roundedCropper },style:({
            width: _vm.scaledCropperWidth + 'px',
            height: _vm.scaledCropperHeight + 'px'
          })})])]),_vm._v(" "),(_vm.debug)?_c('div',{staticClass:"cropper-container",staticStyle:{"background":"white","z-index":"1","border":"1px solid red"}},[_c('div',{staticClass:"cropper"},[_c('div',{staticClass:"img-container",style:({
            width: _vm.appliedWidth + 'px',
            height: _vm.appliedHeight + 'px'
          })},[(_vm.resultSrc)?_c('img',{attrs:{"src":_vm.resultSrc.src,"draggable":"false"}}):_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-h-full"},[_c('AccountSpinner')],1)]),_vm._v(" "),(_vm.debug)?_c('div',{staticClass:"cropper-container",staticStyle:{"background":"white","z-index":"1","border":"1px solid red"}},[_c('div',{staticClass:"cropper"},[_c('div',{staticClass:"img-container",style:({
                width: _vm.targetWidth + 'px',
                height: _vm.targetHeight + 'px'
              })},[(_vm.resultSrc)?_c('img',{attrs:{"src":_vm.resultSrc.src,"draggable":"false"}}):_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center tw-h-full"},[_c('AccountSpinner')],1)]),_vm._v(" "),_c('div',{staticClass:"cropper-window",class:{ 'tw-rounded-full': _vm.roundedCropper },style:({
                width: _vm.scaledCropperWidth + 'px',
                height: _vm.scaledCropperHeight + 'px'
              })})])]):_vm._e()])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"slider-container"},[_c('CIcon',{staticClass:"icon",attrs:{"icon":_vm.icons.zoomOut},on:{"click":_vm.zoomOut}}),_vm._v(" "),_c('CSlider',{ref:"slider",staticClass:"slider",attrs:{"contained":"","min":_vm.minZoom,"max":_vm.maxZoom,"interval":_vm.zoomInterval,"tooltip":"none"},on:{"change":_vm.zoomChange},scopedSlots:_vm._u([{key:"mark",fn:function(ref){
              var pos = ref.pos;
return [(pos === 50)?_c('span',{staticClass:"tw-inline-flex tw-text-center tw-relative tw-mt-2 tw-text-grey-500 hover:tw-text-grey-800 tw-pt-1",style:({ left: ("calc(" + pos + "% - 4px)") }),on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.resetZoom($event)}}},[_c('CIcon',{staticClass:"tw-cursor-pointer",attrs:{"icon":_vm.icons.center}})],1):_vm._e()]}}]),model:{value:(_vm.zoom),callback:function ($$v) {_vm.zoom=$$v},expression:"zoom"}}),_vm._v(" "),_c('CIcon',{staticClass:"icon",attrs:{"icon":_vm.icons.zoomIn},on:{"click":_vm.zoomIn}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
import { CIcon } from '~/icons/types'

export const ciHamburger: CIcon = {
  width: '24',
  height: '24',
  paths: [
    {
      d:
        'M20.2 16.8H3.8c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h16.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.6-1.5ZM3.8 7.2h16.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5H3.8c-.8 0-1.5.7-1.5 1.5s.6 1.5 1.5 1.5Zm16.4 3.3H3.8c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5h16.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.6-1.5Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'hamburger',
  type: 'solid'
}

import {
  SET_NEW_ITEM_VALUE,
  UPDATE_CAMPAIGN_AD_ITEM_VALUE
} from '~/store/modules/shared/advertising/form/mutation-types'
import { useNamespacedStore } from '~/compositions/store'
import {
  ADVERTISE_FORM_NS,
  AdvertiseFormState
} from '~/store/modules/shared/advertising/form/state'
import { computed, useRoute } from '~/utils/nuxt3-migration'
import { AdItemStatus } from '~/models/advertising/types'
import { SchemaField } from '~/models/form/types'
import { useQuickAdForm } from '~/compositions/advertise/quick-ad-form'
import { toCamel } from '~/utils/object'

export function useAdItemForm() {
  const { state, commit, getters } = useNamespacedStore<AdvertiseFormState>(
    ADVERTISE_FORM_NS
  )

  const route = useRoute()

  const {
    emitParams: quickEmitParams,
    getSchemaField: getQuickSchemaField,
    values: quickValues,
    errors: quickErrors,
    inQuickForm
  } = useQuickAdForm()

  const newAdItemSchema = computed(
    () => getters('getCampaignAd')(route.value.params?.ad)?.newItemSchema || []
  )
  const campaignAdItemSchema = computed(
    () => currentCampaignAdItem.value?.schema || []
  )
  const schema = computed(() =>
    editMode.value ? campaignAdItemSchema.value : newAdItemSchema.value
  )

  const editMode = computed(() => Boolean(route.value.params?.item))

  const currentCampaignAd = computed(() =>
    getters('getCampaignAd')(route.value.params?.ad)
  )
  const newAdItemValues = computed(
    () =>
      currentCampaignAd.value?.newItemValues || {
        placementContext: {}
      }
  )
  const editAdItemValues = computed(() => currentCampaignAdItem.value?.values)
  const adItemValues = computed(() => {
    if (inQuickForm.value) {
      return quickValues.value
    }
    return editMode.value ? editAdItemValues.value : newAdItemValues.value
  })

  const emitParams = (name: string, value: any) => {
    if (inQuickForm.value) {
      quickEmitParams(name, value)
      return
    } else if (editMode.value) {
      commit(UPDATE_CAMPAIGN_AD_ITEM_VALUE, {
        adId: route.value.params?.ad,
        itemId: route.value.params?.item,
        adItemFormValue: { name, value }
      })
      return
    }
    commit(SET_NEW_ITEM_VALUE, {
      id: route.value.params?.ad,
      adItemFormValue: { name, value }
    })
  }

  const getSchemaField = (name: string) => {
    if (inQuickForm.value) {
      return getQuickSchemaField(toCamel(name))
    }
    return schema.value.find((s: SchemaField) => s.name === name)
  }

  const loading = computed(() => state.loading)
  const newAdItemErrors = computed(
    () => getters('getCampaignAd')(route.value.params?.ad)?.newItemErrors || {}
  )
  const editAdItemErrors = computed(
    () => currentCampaignAdItem.value?.errors || {}
  )
  const errors = computed(() => {
    if (inQuickForm.value) {
      return quickErrors.value
    }

    return editMode.value ? editAdItemErrors.value : newAdItemErrors.value
  })

  const currentCampaignAdItem = computed(() =>
    getters('getCampaignAdItem')(
      route.value.params?.ad,
      route.value.params?.item
    )
  )

  const currentAdItem = computed(() => {
    if (route.value.params?.item) {
      return currentCampaignAdItem.value?.item || null
    }
    return null
  })

  const lockedMode = computed(() => {
    return [
      AdItemStatus.PENDING,
      AdItemStatus.REPLACED,
      AdItemStatus.APPROVED,
      AdItemStatus.REJECTED
    ].includes(currentAdItem.value?.status)
  })

  const disableInputs = computed(() => {
    return loading.value || lockedMode.value
  })

  return {
    loading,
    errors,
    emitParams,
    getSchemaField,
    editMode,
    schema,
    adItemValues,
    currentAdItem,
    newAdItemValues,
    disableInputs,
    lockedMode
  }
}
